  <template>
  <div class="lottery-container">
    <el-row>
      <el-select
        v-model="lotteryMode"
        placeholder="选择抽签模式"
        style="width: 240px; margin-left: 10px; margin-right: 20px"
      >
        <el-option
          v-for="item in lotteryModeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-row>
    <div v-if="lotteryMode == '普通抽签'">
      <el-button link type="primary" @click="drawLots">生成抽签号</el-button>
      <el-row>
        <el-col :span="2"></el-col>
        <el-col :span="20">
          <el-table :data="athletes" style="width: 100%">
            <el-table-column
              prop="groupName"
              label="运动员"
              align="center"
              header-align="center"
            />
            <el-table-column
          prop="unite"
          label="单位"
          align="center"
          header-align="center"
        ></el-table-column>
            <el-table-column

              prop="drawNumber"
              label="签号"
              align="center"
              header-align="center"
            />
          </el-table>
        </el-col>
      </el-row>
    </div>

    <div v-if="lotteryMode == '三次抽签'">
      <el-button link type="primary" @click="draw()">生成抽签号</el-button>
      <el-select
        v-model="lotteryIndex"
        placeholder="Select"
        style="width: 240px;margin-left: 20px;"
      >
        <el-option
          v-for="item in lotteryIndexList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-button type="primary" style="margin-left: 20px;">提交</el-button>
      <el-table :data="drawResults" style="width: 100%;font-size: 20px;">
        <el-table-column
          prop="name"
          label="姓名"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="unite"
          label="单位"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="number1"
          label="第一次签号"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="number2"
          label="第二次签号"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="number3"
          label="第三次签号"
          align="center"
          header-align="center"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

  <script>
import axios from "axios";

export default {
  name: "LotteryComponent",
  created() {},
  data() {
    return {
      athletes: [],
      lotteryMode: "普通抽签",
      lotteryModeList: [
        { value: "普通抽签", label: "普通抽签" },
        { value: "一键抽签", label: "一键抽签" },
        { value: "三次抽签", label: "三次抽签" },
      ],
      count: 0,
      lotteryNumber: {},
      names: [
        "Alice",
        "Bob",
        "Charlie",
        "David",
        "Eve",
        "Frank",
        "Grace",
        "Hannah",
        "Ian",
        "Julia",
      ],
      drawResults: [],
      number2: [],
      number3: [],
      number1: [],
      lotteryIndexList: [
        { value: "第一次抽签结果", label: "第一次抽签结果" },
        { value: "第二次抽签结果", label: "第二次抽签结果" },
        { value: "第三次抽签结果", label: "第三次抽签结果" },
      ],
      lotteryIndex:'第一次抽签结果'
    };
  },
  methods: {
    draw() {
      const numbers = Array.from({ length: 10 }, (_, i) => i + 1);
      // 打乱签号数组
      const shuffledNumbers = this.shuffleArray(numbers);
      // 根据 count 的值决定使用 number1、number2 还是 number3
      if (this.count % 3 === 0) {
        this.number1 = shuffledNumbers;
      } else if (this.count % 3 === 1) {
        this.number2 = shuffledNumbers;
      } else if (this.count % 3 === 2) {
        this.number3 = shuffledNumbers;
      }
      // 更新 drawResults 数组
      this.drawResults = this.names.map((name, index) => ({
        name: name,
        number1: this.number1[index],
        number2: this.number2[index],
        number3: this.number3[index],
      }));
      this.count++;
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    drawLots() {
      var that = this;
      // 清空之前的抽签号
      this.athletes.forEach((athlete) => {
        athlete.lotNumber = null;
      });
      console.log( this.$route.query.comItemId);
      // 生成抽签号并分配
      const lotNumbers = this.generateLotNumbers();
      this.assignLotNumbers(lotNumbers);
      axios({
        url: this.$globalurl + "draw/drawForComItem",
        method: "post",
        data: this.$route.query.comItemId,
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        console.log(response.data);
        that.athletes = response.data;
      });
    },
    generateLotNumbers() {
      const numbers = [];
      for (let i = 1; i <= this.athletes.length; i++) {
        numbers.push(i);
      }
      // 打乱数组顺序
      for (let i = numbers.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [numbers[i], numbers[j]] = [numbers[j], numbers[i]]; // ES6 交换算法
      }
      return numbers;
    },
    assignLotNumbers(lotNumbers) {
      this.athletes.forEach((athlete, index) => {
        athlete.lotNumber = lotNumbers[index];
      });
    },
  },
};
</script>


  <style>
.lottery-container {
  text-align: center;
  margin-top: 20px;
}

.athlete-list {
  list-style: none;
  padding: 0;
}

.athlete-list li {
  margin-top: 5px;
  font-size: 16px;
}
</style>