<template>
    
    <div>
        <svg width="301" style="height: 200;">
           
            <text @click="clickRectBlue()" x="15%" y="25%" fill="black" font-family="Arial" font-size="10" text-anchor="middle"
                alignment-baseline="central" v-text="123"></text>

            <line x1="200" y1="50%" x2="300" y2="50%" style="stroke:black; stroke-width:1" />
       
            <line x1="200" y1="25%" x2="200" y2="50%" style=" stroke:black; stroke-width:1 "  />
  
            <line x1="0" y1="25%" x2="200" y2="25%" style=" stroke:black; stroke-width:1 "/>
           
            <text  @click="clickRectRed()" x="15%" y="75%" fill="black" font-family="Arial" font-size="10" text-anchor="middle"
                alignment-baseline="central" v-text="123"></text>
            <line x1="200" y1="50%" x2="200" y2="75%" style=" stroke:black; stroke-width:1 "  />
            <text x="75%" :y=y_session fill="black" font-family="Arial" font-size="11" text-anchor="middle"
            alignment-baseline="central" v-text="session"></text> 
            <line x1="0" y1="75%" x2="200" y2="75%" style=" stroke:black; stroke-width:1 " />
        </svg>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
    methods: {
    }
  };
  </script>
  
  <style>
  
  </style>