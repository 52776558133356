<template>
  <!-- 显示比赛 -->
  <el-row>
    <el-button
      link
      type="primary"
      style="margin-left: 20px; margin-top: 20px"
      @click="addCheckInformation()"
      >添加检录信息</el-button
    >
  </el-row>
  <el-table :data="tableData" style="width: 100%">
    <el-table-column prop="账号" label="账号" width="180" />
    <el-table-column prop="密码" label="密码" width="180" />
    <el-table-column prop="场地" label="场地" width="180" />
    <el-table-column prop="A/B方" label="A/B方" width="180" />
    <el-table-column prop="账号类型" label="账号类型" width="180" />
    <el-table-column prop="比赛" label="比赛名称" />
    <el-table-column prop="操作" label="操作" width="180" />
  </el-table>

  <el-dialog
    v-model="diaCheckShow"
    title="配置账号"
    width="500"
    :before-close="handleClose"
  >
    <el-form label-width="70">
      <el-form-item label="账号&nbsp;&nbsp;" >
        <el-input
          style="width: 200px"
          v-model="this.account.username"
          clearable
        />
      </el-form-item>
      <el-form-item label="密码&nbsp;&nbsp;">
        <el-input
          style="width: 200px"
          v-model="this.account.password"
          clearable
          type="password"
          show-password
        />
      </el-form-item>
      <el-form-item label="场地&nbsp;&nbsp;">
        <el-select
          v-model="this.account.location"
          style="width: 200px"
          placeholder="选择场地"
        >
          <option
            v-for="item in locatonList"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="A/B方">
        <el-select
        v-model="side"
          style="width: 200px"
          placeholder="选择A/B方"
      >
        <el-option
        v-for="item in sideList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
        </el-select>
      </el-form-item>
      <el-form-item label="账号类型" >
        <el-select
        v-model="accountType"
          style="width: 200px"
          placeholder="选择账号类型"
      >
        <el-option
        v-for="item in accountList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
        </el-select>
      </el-form-item>
    </el-form>
    <el-button link type="primary" style="margin-left: 350px;">确定添加</el-button>
    <el-button link type="danger" style="margin-left: 20px;" @click="this.diaCheckShow=false">取消</el-button>
  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      itemList: "",
      diaCheckShow: false,
      side:'',
      accountType:'',
      account: {
        username: "",
        password: "",
        locationName: "",
        comName: "",
        A_B_side: "",
      },
      locatonList: [],
      sideList: [
        { value: "红方", label: "红方" },
        { value: "青方", label: "青方" }
      ],
      accountList:[ { value: "检录", label: "检录" },
      { value: "称重", label: "称重" }]
    };
  },
  created() {
    
  },
  methods: {
    getAllLevelByitemName() {
      var that = this;
      axios({
        url: this.$globalurl + "comItem/getByCondition",
        method: "post",
        data: {
          gameId: localStorage.getItem("comId"),
          name: localStorage.getItem("itemName"),
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.itemList = response.data;
      });
    },
    toCheckAthletes(item) {
      console.log(item);
      let routeUrl = this.$router.resolve({
        path: "/ToCheckAthletesPage",
        query: { competitionInformationId: item.id },
      });
      window.open(routeUrl.href, "_blank");
    },
    addCheckInformation() {
      this.diaCheckShow = true;
    },
  },
};
</script>

<style>
</style>