<template>
    <div >
        <svg :width=width :height="height" :visibility="showPage">
            <rect @click="clickRectBlue()" x="0" :y=y1 width="100" height="30" style="fill:rgb(255,255,255);stroke-width:1"
                :style="{ stroke: this.colorBlueShow }" />
            <text @click="clickRectBlue()" x="15%" y="25%" fill="black" font-family="Arial" font-size="10" text-anchor="middle"
                alignment-baseline="central" v-text="usernameBlue"></text>

            <line x1="200" y1="50%" x2="300" y2="50%" style="stroke:black; stroke-width:1" />
       
            <line x1="200" y1="25%" x2="200" y2="50%" style=" stroke:black; stroke-width:1 "  />
  
            <line x1="100" y1="25%" x2="200" y2="25%" style=" stroke:black; stroke-width:1 "/>

           
            <rect @click="clickRectRed()" x="0" :y=y2 width="100" height="30" style="fill:rgb(255,255,255);stroke-width:1;"
                :style="{ stroke: this.colorRedShow }" />
            <text  @click="clickRectRed()" x="15%" y="75%" fill="black" font-family="Arial" font-size="10" text-anchor="middle"
                alignment-baseline="central" v-text="usernameRed"></text>
            <line x1="200" y1="50%" x2="200" y2="75%" style=" stroke:black; stroke-width:1 "  />
            <text x="75%" :y=y_session fill="black" font-family="Arial" font-size="11" text-anchor="middle"
            alignment-baseline="central" v-text="session"></text> 
            <line x1="100" y1="75%" x2="200" y2="75%" style=" stroke:black; stroke-width:1 " />
            <!-- <text @click="clickRectBlue()" x="15%" :y=y_test_up fill="black" font-family="Arial" font-size="10" text-anchor="middle"
                alignment-baseline="central" v-text="usernameBlue" :style="{ visibility: this.showLink }"></text>

            <line :x1=x_mid :y1=y_mid x2="300" :y2=y_mid style="stroke:black; stroke-width:1" />
       
            <line x1="200" :y1=y_up x2="200" y2="50%" style=" stroke:black; stroke-width:1 " :style="{ visibility: this.showLink }" />
  
            <line :x1=x_blue :y1=y_up x2="200" :y2=y_up style=" stroke:black; stroke-width:1 " :style="{ visibility: this.showLink }"/>
           
            <text  @click="clickRectRed()" x="15%" :y=y_test_down fill="black" font-family="Arial" font-size="10" text-anchor="middle"
                alignment-baseline="central" v-text="usernameRed" :style="{ visibility: this.showLink }"></text>
            <line x1="200" y1="50%" x2="200" :y2=y_down style=" stroke:black; stroke-width:1 " :style="{ visibility: this.showLink }" />
            <text x="75%" :y=y_session fill="black" font-family="Arial" font-size="10" text-anchor="middle"
            alignment-baseline="central" v-text="session"></text> 
            <line x1="0" :y1=y_down x2="200" :y2=y_down style=" stroke:black; stroke-width:1 " :style="{ visibility: this.showLink }"/> -->
           
        </svg>
    </div>
</template>

<script>

  export default {
    name: 'MatchDiagram',
    props:{
        height:{
            type:String,
            default:'100'
        },
        usernameBlue:{
            type:String,
            default:'#'
        },
        usernameRed:{
            type:String,
            default:'#'
        },
        session:{
            type:String,
            default:'#'
        },
        keyIndex:{
            type:String,
            default:'#'
        },
        levels:{
            type:String,
            default:'#'
        },
        nowLevel:{
            type:String,
            default:'#'
        },
        height_up:{
            type:String,
            default:'#'
        },
        height_down:{
            type:String,
            default:'#'
        }
    },
    data(){
      return{
        displayText:'张三',
        y1:(parseInt(this.height)*0.25-15)+'',
        y2:(parseInt(this.height)*0.75-15)+'',
        showPage:"visible",
        colorRedShow:'black',
        colorBlueShow:'black',
        y_session:(parseInt(this.height)*0.50)-10,
        y_test_up:'',
        y_test_down:'',
        // showLink:'visible'
        x_mid:200,
        y_up:'25%',
        y_down:'75%',
        height_show :'',
        x_up:'',
        y_mid:''
      }
    },
    created(){
        this.y_up = this.height_up
        this.y_down = this.height_down
        this.y_test_up = this.y_up-7
        this.y_test_down = this.y_down-7
        if(this.nowLevel !=1){
            this.y_mid = Math.floor((this.y_down-this.y_up)/2+this.y_up)
            console.log('mid-'+this.y_mid);
        }else{
            this.y_mid = this.height*0.5
        }
        if(this.usernameBlue =='#' || this.usernameBlue=="none2" || this.usernameRed=="none2" ){
            this.showPage = 'hidden'
        }
        
        if(this.usernameBlue !='none'){
            this.colorBlueShow = 'blue'
        }
        if(this.usernameRed !='none'){
            this.colorRedShow = 'red'
        }
            // 
      
        if(this.usernameRed =='none2' && this.nowLevel==1){
            
            this.showLink = 'hidden'
            this.x_mid = 0     
        }
        if(this.usernameBlue =='none2' &&  this.nowLevel==1){
            this.showLink = 'hidden'
            this.x_mid = 0
        
        }
        // if(this.usernameRed =='null-null'  &&  this.nowLevel==2 && this.usernameBlue !='null-null'){
        //         this.y_up = this.height*1/6
        //         this.y_down = this.height*2/3
        // }else if(this.usernameBlue =='null-null'  &&  this.nowLevel==2 && this.usernameRed !='null-null' ){
        //     this.y_up = this.height*1/3
        //     this.y_down = this.height*5/6
        // }else if(this.nowLevel==3){
        //         console.log('ada');
        // }
    },
    methods:{
        clickRectBlue(){
            const username = this.usernameBlue // 假设点击时需要显示的用户名
            var keyIndex = this.keyIndex 
            this.$emit('username-clicked', username); // 触发自定义事件并传递用户名
            this.$emit('keyIndex-clicked',keyIndex)
        },
        clickRectRed(){
            const username =  this.usernameRed; // 假设点击时需要显示的用户名
            var keyIndex = this.keyIndex 
            this.$emit('username-clicked', username); // 触发自定义事件并传递用户名
            this.$emit('keyIndex-clicked',keyIndex)
        }
    }
  };
  </script>

<style scoped>
html,
body {
    margin: 0;
    padding: 0;
}
rect {
  cursor: pointer; /* 当鼠标悬浮在rect元素上时，光标变成小手 */
}
.animate-click {
  transform: scale(1.1); 
  transition: transform 0.2s; /* 动画效果持续时间 */
}
text{
    cursor: pointer; /* 当鼠标悬浮在rect元素上时，光标变成小手 */
}
</style>