<!-- components/ImageViewer.vue -->
<template>
    <div class="btncontainer">
        <!-- <button @click="backPage">关闭</button>
        <button v-print="'#print-content'">打印</button> -->
        <el-button @click="backPage" type="primary">关闭</el-button>
        <el-button v-print="'#print-content'" type="success">打印</el-button>
        <!-- <button @click="test">打印</button> -->
    </div>
    <div class="image-viewer" id="print-content">
        <!-- <div class="modelcomtainer"> -->
        <div>
            <div class="modelcomtainer">
                <el-row class="toptitlecontainer">
                    <el-col :span="3" style="height: 100%;">
                        <!-- 比赛的图片 -->
                        <img src="../assets/test/th.jpg" mode="heightFix">
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchlefttitleonecontainer">
                            <span class="matchlefttitleonefontstyle">{{ matchname }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="9" style="height: 100%;">
                        <el-row class="matchrighttitleonecontainer">
                            <span class="matchrighttitleonefontstyle">{{ matchitemname }}</span>
                        </el-row>
                        <el-row class="matchrighttitletwocontainer">
                            <span class="matchrighttitletwofontstyle">{{ matchitenlevel }}</span>
                        </el-row>
                        <el-row class="matchrighttitletherecontainer">
                            <span class="matchrighttitletherefontstyle">{{ matchitemtype }}</span>
                        </el-row>
                    </el-col>
                    <el-col :span="3" style="height: 100%;">
                        <img src="../assets/test/th.jpg" mode="heightFix">
                    </el-col>
                </el-row>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <div class="contentcontainer">
                    <div class="titlecontainer">
                        <span class="titlefontstyle">综合秩序表</span>
                    </div>
                    <div class="alignmentchartcontainer">
                        <SingleFailurePrinting></SingleFailurePrinting>
                    </div>
                    <div class="annotationcontainer">
                    </div>
                </div>
                <el-row style="height: 1%;width: 100%;">
                    <el-divider></el-divider>
                </el-row>
                <el-row style="height: 5%;width: 100%;">
                    <el-col :span="6" style="height: 100%;">
                        <img src="../assets/test/icon.jpeg" mode="heightFix">
                    </el-col>
                    <el-col :span="12" style="display: flex;align-items: center;justify-content: center;">
                    </el-col>
                    <el-col :span="6" style="height: 100%;display: flex;justify-content: right;">
                        <img src="../assets/test/icon.jpeg" mode="heightFix">
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import SingleFailurePrinting from './SingleFailurePrinting.vue';
export default {
    components: {
        SingleFailurePrinting,
     },
    methods: {
        test() {
            this.scaleImage()
        },
        backPage() {
            this.$router.go(-1);
        },
        scaleImage() {
            // 确保图片已经加载
            let image = this.$refs.imageToResize;
            if (image.complete) {
                const containerWidth = 800;
                const containerHight = 840
                const originalWidth = image.naturalWidth;
                const originalHeight = image.naturalHeight;
                const ratio = originalWidth / originalHeight;
                let newWidth = 0;
                let newHeight = 0;
                // 计算新的高度
                if (originalWidth >= originalHeight) {
                    newWidth = Math.min(originalWidth, containerWidth);
                    newHeight = (newWidth / ratio);
                } else {
                    newHeight = Math.min(originalHeight, containerHight);
                    newWidth = (newHeight / ratio);
                }
                // 设置图片的尺寸
                image.style.width = `${newWidth}px`;
                image.style.height = `${newHeight}px`;
                console.log(originalWidth)
                console.log(originalHeight)
                console.log(ratio)
                console.log(newHeight)
                console.log(newWidth)
            } else {
                // 图片未加载完成，监听 load 事件后再缩放
                image.addEventListener('load', () => this.scaleImage);
            }
        },
    },
    data() {
        return {
            width:'',
            levels:'',
            x_Quarterfinals: '',
            Quarterfinals: 'Quarterfinals',
            x_GoldMedalContest: '',
            GoldMedalContest: 'GoldMedalContest',
            x_Semifinals: '',
            Semifinals: 'Semifinals',
            No: 'No.',
            Bib: 'Bib',
            name: 'Name',
            // TemplatePrintingActive: false,
            // modelimageSrc: '',
            matchitenlevel: '',
            matchitemname: '',
            // matchdata: '',
            matchname: '',
            matchitemtype: '',
            imageSrc: ''
        }
    },
    created() {
        var that = this
        // 组件创建时获取查询参数
        const imageSrc = this.$route.query.imageSrc;
        // 现在你可以使用 imageSrc，例如设置到组件的数据属性
        this.imageSrc = decodeURIComponent(imageSrc);
        const comItemId = this.$route.query.comItemId
        axios({
            url: this.$globalurl+'comItem/getById',
            method: 'get',
            params: {
                id: comItemId
            },
            headers: {
                'content-type': 'application/json'
            }
        }).then(function (response) {
            console.log(response)
            if (response.status == 200) {
                that.matchitenlevel = response.data.level
                that.matchitemname = response.data.name
                that.matchitemtype = response.data.type
                that.matchname = response.data.gameName
            }
        })
    },
    // mounted() {
    //     // 组件挂载后，等图片加载完成再缩放
    //     this.scaleImage();
    // },
};
</script>

<style scoped>
/* el-divider 修改高度&虚线效果 */
.signaturecontainer {
    height: 60%;
    width: 98%;
    margin-left: 1%;
    padding-top: 1%;
    display: flex;
    flex-direction: column;
}

.althlesignaturecontainer {
    display: flex;
    margin-top: 2%;
    margin-bottom: 3%;
}

.weighttotalcontainer {
    border: 1px solid black;
    height: 15%;
    width: 98%;
    margin-left: 1%;
}

.officialsignaturecontainer {
    height: 15%;
    width: 98%;
    margin-left: 1%;
}

/* .reportformtipscontainer {
    height: 5%;
    width: 98%;
    margin-left: 1%;
} */

.tipsfontstyle {
    font-weight: bold;
}

.tipsstyle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.el-divider--horizontal {
    margin-top: 8px;
    margin-bottom: 2px;
    background: 0 0;
    border-top: 1px solid black;
}

/* .matchimgcontainer {
    max-width: 100%;
    max-height: 100%;
} */

.toptitlecontainer {
    height: 8%;
    width: 100%;
}

.matchrighttitletwofontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchrighttitletherefontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchrighttitletwocontainer {
    display: flex;
    justify-content: right;
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-right: 5%;
}

.matchrighttitletherecontainer {
    display: flex;
    justify-content: right;
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
}

.matchrighttitleonefontstyle {
    font-size: large;
    font-weight: bold;
}

.matchrighttitleonecontainer {
    display: flex;
    justify-content: right;
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-right: 5%;
}

.matchlefttitletwofontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchlefttitletherefontstyle {
    font-size: medium;
    font-weight: bold;
}

.matchlefttitletwocontainer {
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-left: 5%;
}

.matchlefttitletherecontainer {
    height: 26%;
    text-align: center;
    padding-top: 5%;
    padding-left: 5%;
    padding-bottom: 5%;
}

.matchlefttitleonefontstyle {
    font-size: large;
    font-weight: bold;
}

.matchlefttitleonecontainer {
    text-align: center;
    height: 26%;
    padding-top: 5%;
    padding-left: 5%;
}

.modelcomtainer {
    /* width: 612pt;
    height: 784pt; */
    width: 210mm;
    height: 297mm;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 1px solid #ddd; */
    background-color: white;
}

.image-viewer {
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background-color: rgb(246, 246, 246);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* 根据需要添加内边距 */

}

.image-viewer img {
    max-width: 100%;
    max-height: 100%;
}

.contentcontainer {
    height: 85%;
    width: 100%;
}

.titlecontainer {
    /* padding: 2%; */
    height: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.weighttipscontainer {
    width: 98%;
    margin-left: 1%;
    height: 5%;
    display: flex;
    border: 1px solid black;
}
.alignmentchartcontainer {
    /* padding: 3%; */
    height: 70%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-left: 20px;
    margin-top: 20px;
}

.annotationcontainer {
    height: 10%;
}

.titlefontstyle {
    font-size: large;
    font-weight: bold;
}

.tipscontainer {
    display: flex;
    height: 5%;
    width: 98%;
    margin-left: 1%;
    border: 1px solid black;
}
.phasecontainer{
    display: flex;
    height: 10%;
    width: 98%;
    margin-left: 1%;
}

.unitordertipscontainer {
    display: flex;
    flex-direction: column;
    height: 8%;
    margin-left: 1%;
    width: 98%;
    border: 1px solid black;
}

.sessionnumcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid black;
    /* width: 100%; */
    height: 39%;
}

.sessionnumfontstyle {
    font-size: large;
    font-weight: bold;
}

.unitordertipcontainer {
    display: flex;
    height: 60%;
}

.unitorderdetailcontainer {
    /* height: 5%; */
    /* padding-top: 5px; */
    margin-left: 1%;
    width: 98%;
    margin-top: 2%;
    margin-bottom: 3%;
    display: flex;
}

.contentdetailcontainer {
    height: 90%;
    width: 98%;
    margin-left: 1%;
}

.detailmsgcontainer {
    display: flex;
    width: 98%;
    margin-left: 1%;
    margin-top: 2%;
    margin-bottom: 3%;
}

.btncontainer {
    position: fixed;
    /* 固定定位 */
    top: 20px;
    /* 距离顶部20像素 */
    right: 20px;
    /* 距离右侧20像素 */
    z-index: 1000;
    /* 确保按钮在页面其他内容的上方 */
}

.el-row {
    margin-bottom: 0px;
}

.el-col {
    margin-bottom: 0px;
}

/* 定义打印样式，隐藏按钮 */
/* @media print {
    .btncontainer {
        display: none;
    }
} */
</style>