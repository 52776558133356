<template>
    <el-row>
        <el-col :span="2"></el-col>
        <el-col :span="20">

            <el-avatar shape="square" :size="100" :src="squareUrl" />

            <el-descriptions title="" size="6px" column="2" border>
                <template #extra>
                    <el-button type="primary"  @click="startEditing">修改个人信息</el-button>
                </template>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <user />
                            </el-icon>
                            用户名
                        </div>
                    </template>
                    <span>{{ user.name }}</span>
                </el-descriptions-item>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <user />
                            </el-icon>
                            姓名简称
                        </div>
                    </template>
                    <span>{{ user.nameAbb }}</span>
                </el-descriptions-item>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <user />
                            </el-icon>
                            出生年月
                        </div>
                    </template>
                    <span >{{ user.birthday }}</span>
                </el-descriptions-item>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <iphone />
                            </el-icon>
                            手机号
                        </div>
                    </template>
                  {{ user.phone }}
                </el-descriptions-item>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <iphone />
                            </el-icon>
                            密码
                        </div>
                    </template>
                  {{ user.password }}
                </el-descriptions-item>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <iphone />
                            </el-icon>
                            性别
                        </div>
                    </template>
                {{ user.gender }}
                
                </el-descriptions-item>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <iphone />
                            </el-icon>
                            证件类型
                        </div>
                    </template>
                  {{ user.cardType }}
                
                </el-descriptions-item>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <iphone />
                            </el-icon>
                            证件号
                        </div>
                    </template>
                  {{ user.cardId }}
                </el-descriptions-item>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <iphone />
                            </el-icon>
                            国籍
                        </div>
                    </template>
                 {{ user.counrty }}
                </el-descriptions-item>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <iphone />
                            </el-icon>
                            紧急联系人
                        </div>
                    </template>
                 {{ user.contactName }}
                </el-descriptions-item>
            <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <iphone />
                            </el-icon>
                            紧急联系人
                        </div>
                    </template>
                 {{ user.contactName }}
                </el-descriptions-item>
                <el-descriptions-item width="150">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <iphone />
                            </el-icon>
                            紧急联系人电话
                        </div>
                    </template>
                 {{ user.contactPhone }}
                </el-descriptions-item>
                <el-descriptions-item style="width: 100%;">
                    <template #label>
                        <div class="cell-item">
                            <el-icon :style="iconStyle">
                                <iphone />
                            </el-icon>
                            地址
                        </div>
                    </template>
                 {{ user.address }}
                </el-descriptions-item>
            </el-descriptions>
        </el-col>
        <el-col :span="2"></el-col>
    </el-row>

</template>

<script>
import axios from 'axios';

  export default {
    name: 'PersonalInfo',
    data(){
        return{
            user: '',
            isEditing: false, // 控制编辑状态
            squareUrl:'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
        }
    },
    created(){
        this.getUser()
    },
    methods:{
        getUser(){
            var that = this
        axios({
            url: that.$globalurl+'athlete/getByCondition',
            method:"post",
            data:{"id":localStorage.getItem("userId")},
            headers:{
                'content-type':'application/json'
            },
            }).then(function(response){
                that.user = response.data[0]
                console.log(that.user);
            })
        },
        startEditing() {
           this.isEditing = true;
        },
        saveChanges() {
            // 这里添加保存逻辑，例如发送请求更新服务器上的数据
            this.isEditing = false;
    },
    }
  }
  </script>

<style scoped>
.personal-info {
    max-width: 600px;
    margin: 20px auto;
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: 'Arial', sans-serif;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 auto 15px;
}

h1 {
    margin: 0 0 10px;
    color: #333333;
    font-size: 24px;
}

.bio {
    color: #666666;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.info-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.info-list li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 14px;
    color: #333333;
}

.info-list span:first-child {
    font-weight: bold;
}

.custom-descriptions-item {
    height: 20px;
    /* 你可以根据需要设置高度 */
}
</style>