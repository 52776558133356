<template>
  <div id="background">
    <div id="logo">LOGIN</div>
    <div>
      <el-card id="login" class="box-card">
        <el-form id="form" :label-position="labelPosition" label-width="70px">
          <el-form-item label="用户名 :">
            <el-input v-model="phone" placeholder="手机号/用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码 :">
            <el-input type="password" v-model="password" placeholder="请输入密码" show-password></el-input>
          </el-form-item>
          <el-form-item label="角色 :">
            <el-select v-model="identity" placeholder="请选择角色">
              <el-option v-for="item in identityList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-button style="float: right; opacity: 0.95; padding: 3px 0" type="text"
            @click="registrationEvent">注册</el-button>
          <el-button type="primary" class="custom-button" @click="loginSubmit">
            登入
          </el-button>
        </el-form>
      </el-card>
    </div>
    <div id="clock">
      <div id="time">{{ time }}</div>
      <div id="date">{{ date }}</div>
    </div>
    <div id="copyright">
      copyright @ {{ year }} 拳跆空(上海)体育经济发展有限公司. All Rights Reserved
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  created(){
    var screenWidth = window.screen.width;
  var screenHeight = window.screen.height;
  console.log("Screen Width: " + screenWidth + "px");
  console.log("Screen Height: " + screenHeight + "px");
  },
  data() {
    return {
      labelPosition: "right",  // 让用户名 ，密码，角色 像右对其
      phone: "", // 用户名 或者手机号
      password: "",// 用户密码
      identity: "", // 用户的身份
      identityList: [ // 用户身份的可选项
        { value: "运动员", label: "运动员" },
        { value: "领队", label: "领队" },
        { value: "组委会", label: "组委会" },
        { value: "管理员", label: "管理员" },
      ],
      time: "", // 显示的时间 
      date: "", // 日期
      year: "", // 年份
      score:0
    };
  },
  mounted() {
    this.$nextTick(() => { // 定时器方法 
      setInterval(this.update_clock, 100);
    });
    this.phone = this.$route.query.phone
    this.password=this.$route.query.password
  },
  methods: {
    update_clock: function () {
      var d = new Date();
      var year = d.getFullYear();
      if (year < 10) {
        year = "0" + year;
      }
      var mon = d.getMonth()+1;
      if (mon < 10) {
        mon = "0" + mon;
      }
      var day = d.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      var hour = d.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      var minute = d.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      this.time = hour + ":" + minute;
      this.date = year + "/" + mon + "/" + day;
      this.year = year;
    },
    registrationEvent(){
      // 跳转到注册页面
      this.$router.push('/ToRegistrationPage');
    },
    loginSubmit(){
          const that = this
          axios.post(this.$globalurl+"account/login",{
            password:this.password,
            phone:this.phone,
            type:this.identity
          }).then(function (response){
            if(response.status ==200){
              that.$router.push({
                path:'/ToMainPage',
              })
              that.$message({
              type:'success',
              message:'登入成功'
            })
            }
            localStorage.setItem('userId',response.data.id)
            localStorage.setItem('userType',response.data.type)
          }).catch(error =>{
            if(error.response.status ==604){
              that.$message({
                type:'error',
                message:'账号不存在'
              })
            }else if(error.response.status ==603){
              that.$message({
                type:'error',
                message:'密码错误'
              })
            }else if(error.response.status ==602){
              that.$message({
                type:'error',
                message:'账号类型未定义'
              })
            }
          })
    }
  },
};
</script>

<style scoped>
#background {

  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  /* background-image: url('../assets/img/background/loginBg.jpg'); */
  background-size: 100%;
  background-image: linear-gradient(90deg,
      rgb(160, 178, 183),
      rgb(164, 159, 164));
  /* animation: myanimation 10s infinite; */
}

 @keyframes myanimation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
} 

#logo {
  color: white;
  font-size: 50px;
  font-weight: 800;
  position: absolute;
  top: 10%;
  left: 15%;
}

#login {
  position: absolute;
  top: 30%;
  left: 38%;
  /* border: none; */
}

.el-card {
  width: 371px;
  height: 210px;
  background-color: rgb(255, 255, 255, 0.4);
  box-shadow: 0 10px 26px 0px rgba(52, 81, 91, 0.5);
}

#clock {
  color: aliceblue;
  position: absolute;
  top: 70%;
  left: 15%;
  text-align: center;
}

#time {
  font-size: 100px;
}

#date {
  font-size: 35px;
}

#copyright {
  color: aliceblue;
  position: absolute;
  top: 95%;
  left: 38%;
}

.custom-button {
  float: right;
  margin-right: 10px;
  height: 32px;
  background-color: rgba(160, 178, 183, 0.8);
  /* 初始背景颜色 */
  border: none;
  transition: background-color 0.3s;
  /* 平滑过渡效果 */
}

/* 悬浮时颜色变深 */
.custom-button:hover {
  background-color: rgba(120, 138, 143, 0.8);
  /* 悬浮时的深色背景 */
}

/* 点击时颜色更深，这里使用了更暗的颜色 */
.custom-button:active {
  background-color: rgba(80, 98, 103, 0.8);
  /* 点击时的更深颜色背景 */
}
</style>