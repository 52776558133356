<template>
    <div class="registration-page">
        <el-card class="box-card">
            <div style="margin-bottom: 5px">
                日 &nbsp; &nbsp;期 &nbsp;&nbsp;&nbsp;&nbsp;<el-date-picker v-model="startData" type="date"
                    placeholder="开始日期" :disabled-date="disabledDate" :shortcuts="shortcuts"
                    style="width: 170px;" />&nbsp;&nbsp;&nbsp;
                <el-date-picker v-model="endData" type="date" placeholder="结束日期" :disabled-date="disabledDate"
                    :shortcuts="shortcuts" style="width: 170px;" />
            </div>
            <div>

                城 &nbsp; &nbsp;市 &nbsp;&nbsp;&nbsp;&nbsp;
                <el-radio-group v-model="CompetitionAddress" class="ml-4">
                    <el-radio value="全部" size="large" @click="selectAddress('全部')">全部</el-radio>
                    <el-radio value="热门" size="large" @click="selectAddress('热门')">热门</el-radio>
                </el-radio-group>
                <!-- 显示城市 -->
                <div style="margin-bottom: 20px" v-show="addressShow === '全部'">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;<span
                        v-for="(address, index) in addressList" :key="index">
                        <el-button link :type="selectedIndex === index ? 'primary' : 'default'"
                            @click="selectCity(index)">
                            {{ address }} &nbsp; &nbsp; &nbsp;
                        </el-button>
                    </span>
                </div>
                <div style="margin-bottom: 20px" v-show="addressShow === '热门'">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;<span
                        v-for="(address, index) in addressListHost" :key="index">
                        <el-button link :type="selectedIndex === index ? 'primary' : 'default'"
                            @click="selectCity(index)">
                            {{ address }} &nbsp; &nbsp; &nbsp;
                        </el-button>
                    </span>
                </div>
            </div>
            <!-- 状态选择 -->
            <div style="margin-bottom: 20px">
                状 &nbsp; &nbsp;态 &nbsp;&nbsp;&nbsp;&nbsp;
                <el-radio-group v-model="CompetitionStatus" class="ml-4">
                    <el-radio value="全部" size="large" @click="selectStatus('全部')">全部</el-radio>
                    <el-radio value="报名中" size="large" @click="selectStatus('报名中')">报名中</el-radio>
                    <el-radio value="近期比赛" size="large" @click="selectStatus('近期比赛')">近期比赛</el-radio>
                </el-radio-group>
            </div>
            <!-- 显示比赛 -->
            <el-row :gutter="20">
                <el-col :span="6" v-for="(match, index) in matches" :key="index">
                    <el-card class="match-card" @click="selectMatch(match)">
                        <div class="match-info">
                            <h3>{{ match.name }}</h3>
                            <p>时间：{{ match.show_comStartDate }}</p>
                            <p>地点：{{ match.comAddress }}</p>
                            <p>状态：{{ match.status }}</p>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-card>

        <el-dialog title="选择比赛时间" :visible="dialogVisible" width="30%">
            <el-date-picker v-model="selectedDate" type="datetime" placeholder="选择日期时间"></el-date-picker>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="confirmSelection">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';

  export default {
    data() {
      return {
        matches: [],
        selectedMatch: null,
        selectedDate: null,
        startData:'' , // 查询的初始日期,
        endData:'',
        CompetitionAddress:'',
        addressList:['上海','北京','江西','广东','深圳','浙江','河南','湖南','新疆','西藏','云南','四川'],
        addressListHost:['上海','江西','北京','四川'],
        addressShow:null,
        CompetitionStatus:'',
        selectedIndex:null,
      };
    },
    created(){
        this.getAllCompetition()
    },
    methods: {
    getAllCompetition(){
        var that = this
        axios({
            url: this.$globalurl+'game/getAll',
            method:"post",
            headers:{
                'content-type':'application/json'
            },
            }).then(function(response){
                that.matches = response.data
            })
    },
    selectCity(index){
        this.selectedIndex = index
    },
    selectMatch(match) {
      this.selectedMatch = match;
      console.log(match);
      localStorage.setItem("comId",match.id)
      let routeUrl = this.$router.resolve({
        path:'/ToCompetitionInformation',
        query:{competitionInformationId:match.id}
     })
     window.open(routeUrl.href, '_blank');
    },
      confirmSelection() {
        if (this.selectedDate) {
          this.selectedMatch.selectedTime = this.selectedDate;
          this.dialogVisible = false;
          alert(`已选择比赛：${this.selectedMatch.name}，时间：${this.selectedDate}`);
        }
      },
      selectAddress(address){
            this.addressShow =address
      },
      selectStatus(status){
            console.log(status);
      }
    }
  };
  </script>

<style scoped>
.match-card {
    cursor: pointer;
}

.match-info h3 {
    margin: 0;
}

.dialog-footer {
    text-align: right;
}

.match-card {
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* 平滑过渡效果 */
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    /* 初始阴影 */
}

.match-card:hover,
.match-card:focus,
.match-card.active {
    /* 悬浮、聚焦或激活状态 */
    transform: scale(1.05);
    /* 放大到105% */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    /* 更明显的阴影 */
    z-index: 10;
    /* 确保放大的卡片在其他元素上方 */
}

.match-info h3 {
    margin: 0;
}

.dialog-footer {
    text-align: right;
}
</style>