<template>

    <div id="main-container" class="container">
        <el-container>
            <!-- 头 -->
            <header class="header">
                <h1>网站标题</h1>
                <div class="back-button">
                    <el-button @click="goBack" >后退</el-button>
                </div>
                <el-avatar size="30" :src="circleUrl" style="margin-left: 20px;cursor: pointer;" @click="personalInformationPage()"></el-avatar>
            </header>
        </el-container>
        <el-container>
            <aside class="sidebar">
                <div v-if="type=='true'">
                    <el-menu style="height: 100vh;" :router="true" :default-active=menuActive>
                    <el-menu-item index="/ToHomePage" @click="addTab('首页', 'ToHomePage')">
                        <el-icon><Location /></el-icon>
                        <span>首页</span>
                    </el-menu-item>
                    <!-- 比赛管理 -->
                    <!--  -->
                    <el-menu-item index="/ToCompetitionManagementPage"
                        @click="addTab('赛事管理', 'ToCompetitionManagementPage')">
                        <el-icon>
                            <Edit />
                        </el-icon>
                        <span>赛事管理</span>
                    </el-menu-item>
                    <el-menu-item index="/ToGameManagementPage"
                        @click="addTab('比赛管理', 'ToGameManagementPage')">
                        <el-icon>
                            <Edit />
                        </el-icon>
                        <span>比赛管理</span>
                    </el-menu-item>
                    <el-menu-item index='/ToPersonalInformationPage'
                        @click="addTab('个人信息', 'ToPersonalInformationPage')">
                        <el-icon><User /></el-icon>
                        <span>个人信息</span>
                    </el-menu-item>
            
                    <el-menu-item index='/ToSignUpPage'
                        @click="addTab('比赛报名', 'ToSignUpPage')">
                        <el-icon><EditPen /></el-icon>
                        <span>比赛报名</span>
                    </el-menu-item>
                    <el-menu-item index="1">
                        <el-icon><ChatDotRound /></el-icon>
                        <span>通知管理</span>
                    </el-menu-item>
                    <el-menu-item index="3">
                        <el-icon>
                            <setting />
                        </el-icon>
                        <span>系统设置</span>
                    </el-menu-item>
                </el-menu>
                </div>
                <div v-if="type=='false'">
                    <el-menu style="height: 100vh;" :router="true" :default-active=menuActive>
                        <el-menu-item index="/ToHomePage" @click="addTab('首页', 'ToHomePage')">
                        <el-icon><Location /></el-icon>
                        <span>首页</span>
                    </el-menu-item>
                    <el-menu-item index='/ToSignUpPage'
                        @click="addTab('比赛报名', 'ToSignUpPage')">
                        <el-icon><EditPen /></el-icon>
                        <span>比赛报名</span>
                    </el-menu-item>
                   
                    
                </el-menu>
                </div>


             
            </aside>
            <main class="content">
                <el-tabs style="margin-left: 10px;" v-model="editableTabsValue" type="card" closable
                    @tab-remove="removeTab" @tab-click="handleTabClick">
                    <el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name">
                    </el-tab-pane>
                </el-tabs>
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </main>
        </el-container>
    </div>
</template>

<script>
  export default {
    name: 'MainLayout',
    data(){
        return {
        editableTabsValue: '',
        editableTabs: [],
        tabIndex: 0,
        circleUrl:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        menuActive:"/ToHomePage",
        type:''
        }
    },
    created(){
        this.$router.push('/ToHomePage');
     
        if(localStorage.getItem("userType")=="运动员"){
            this.type="false"
        }else{
            this.type="true"
        }
    },
    methods:{
        personalInformationPage(){
            this.menuActive = "/"+"ToPersonalInformationPage"
            this.$router.push('/ToPersonalInformationPage');
            this.addTab('个人信息', 'ToPersonalInformationPage')

        }
        ,
        // 移除标签的方法
        removeTab(targetName) {
        let tabs = this.editableTabs;
        let activeName = this.editableTabsValue;
        let flag = 0
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              flag = index
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter(tab => tab.name !== targetName);
        if(this.editableTabs.length==0){
            this.$router.push('/ToHomePage');
            this.menuActive = "/"+"ToHomePage"
            return
        }
        if(this.editableTabs[flag]==null){
            flag--
        }
        this.menuActive = "/"+this.editableTabs[flag].name
        this.$router.push('/'+this.editableTabs[flag].name);
      },
      // 添加标签的方法
      addTab(targetName,url) {
    //     let newTabName = ++this.tabIndex + '';
   
        for(let i =0 ; i<this.editableTabs.length;i++){
            if(this.editableTabs[i].title==targetName){
                this.editableTabsValue = url;
                return
            }
        }
        this.editableTabs.push({
          title: targetName,
          name: url
        });
        this.editableTabsValue = url;
        
      },
      handleTabClick(tab) {
        this.menuActive = "/"+tab.props.name
        this.$router.push('/'+ tab.props.name);
    // 这里可以添加你的点击事件处理逻辑
    }, 
    goBack() {
            this.$router.go(-1); 
        },
    }
  };

  </script>

<style scoped>
body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}

.container {
    height: 100%;
}

.header {
    background: #fff;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
     padding: 0 20px; /* 根据需要调整内边距 */
}

.header h1 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.sidebar {
    background: #f8f9fa;
    width: 160px;
    /* padding: 2rem; */
    padding-top: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.content {
    height: 100%;
    width: 100%;
    padding-top: 1rem;
}
.headerCom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* 根据需要调整内边距 */
}

.back-button {
  margin-left: auto; /* 将按钮放置在最右边 */
 
}
</style>