<template>
   <el-card class="box-card">
        <!-- 显示比赛 -->
        <el-row :gutter="20">
            <el-col :span="6" v-for="(delegation, index) in delegationList" :key="index">
                <el-card class="match-card">
                    <div class="match-info">
                        <h3>{{ delegation.name }}  <el-button link type="primary" @click="applyDelegation(delegation.id)">申请加入代表队</el-button></h3>
                        <h3>
                           
                        </h3>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </el-card>
    <el-dialog v-model="diaAddDelegation" title="添加代表队" width="700" :before-close="handleClose">
        <el-scrollbar>
            <el-form label-width="100px">
                <el-form-item label="代表队名称">
                    <el-input style="width: 200px;" v-model="delegationName" clearable />
                </el-form-item>
            </el-form>
        </el-scrollbar>

        <template #footer>
            <div class="dialog-footer">
                <el-button @click="diaAddDelegation = false">取消</el-button>
                <el-button type="primary" @click="handleAdd()">
                    确定添加
                </el-button>
            </div>
        </template>

    </el-dialog>

</template>

<script>
import axios from 'axios';
export default {
    data(){
        return{
            diaAddDelegation:false,
            delegationList:[],
            delegationName:''
        }
    },
    created(){
        this.getAllDelegation()
    },
    methods:{
        handleAdd(){
            this.diaAddDelegation= false
            var that = this
            axios({
            url: this.$globalurl+'team/add',
            method:'post',
            data:{name:this.delegationName,gameId:localStorage.getItem("comId")},
            headers:{
            'content-type':'application/json'
            }
        }).then(function(){
            that.getAllDelegation()
            that.$message({
                    type:'success',
                    message:'添加成功'
                })
        })
         
       
        },
        getAllDelegation(){
            var that =this
            axios({
            url: this.$globalurl+'team/getByGameId',
            method:'get',
            params:{gameId:localStorage.getItem("comId")},
            headers:{
            'content-type':'application/json'
            }
        }).then(function (response){
            console.log(response.data);
            that.delegationList = response.data
        })

        },
        applyDelegation(id){        
            console.log(id);
            var that =this
            axios({
            url: this.$globalurl+'teamApply/add',
            method:'get',
            params:{athleteId:localStorage.getItem('userId'),teamId:id}
        }).then(function(response){
            console.log(response.data);
            that.getAllDelegation()
            that.$message({
                    type:'success',
                    message:'申请成功'
                })
        })
        },
    }
}
</script>

<style>

</style>