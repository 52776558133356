<template>
      <!-- 显示比赛 -->
  <el-row>
    <el-button link type="primary" style="margin-left: 20px; margin-top: 20px;" @click="addCheckInformation()">添加检录信息</el-button>
  </el-row>
    <el-table :data="tableData" style="width: 100%">
    <el-table-column prop="账号" label="账号" width="180" />
    <el-table-column prop="密码" label="密码" width="180" />
    <el-table-column prop="场地" label="场地"  width="180"/>
    <el-table-column prop="A/B方" label="A/B方"  width="180"/>
    <el-table-column prop="比赛" label="比赛名称"  />
    <el-table-column prop="操作" label="操作"  width="180"/>
  </el-table>

  <el-dialog
    v-model="diaCheckShow"
    title="添加检录账号"
    width="500"
    :before-close="handleClose"
  >
  <el-form >
    <el-form-item label="账号">
          <el-input
            style="width: 200px"
            v-model="this.checkForm.username"
            clearable
          />
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            style="width: 200px"
            v-model="this.checkForm.password"
            clearable
            type="password"
            show-password 
          />
        </el-form-item>
        <el-form-item label="场地">
            <el-select v-model="this.checkForm.location" style="width: 200px;" placeholder="选择场地">
              <option v-for="item  in locatonList" :key="item.id"></option>
            </el-select>


        </el-form-item>
  </el-form>


</el-dialog>


</template>

<script>
import axios from 'axios';

export default {
    data(){
        return{
            itemList:'',
            diaCheckShow:false,
            checkForm:{
              username:'',
              password:'',
              locationName:'',
              comName:'',
            },
            locatonList:[]
        }
    },
    created(){
        this.getAllLevelByitemName()
    },
    methods:{
    getAllLevelByitemName() {
      var that = this;
      axios({
        url: this.$globalurl + "comItem/getByCondition",
        method: "post",
        data: {
          gameId: localStorage.getItem("comId"),
          name: localStorage.getItem("itemName"),
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.itemList = response.data;
      });
    },
    toCheckAthletes(item){
        console.log(item);
        let routeUrl = this.$router.resolve({
        path:'/ToCheckAthletesPage',
        query:{competitionInformationId:item.id}
     })
     window.open(routeUrl.href, '_blank');
    },
    addCheckInformation(){
      this.diaCheckShow = true
    }
    }
}
</script>

<style>

</style>