<template>
  <el-row>
    <el-col :span="4">
    <el-select
    v-model="value"
      placeholder="选择运动员"
      style="width: 240px;margin-left: 10px;margin-top: 20px;"
    >    
    </el-select>

      <el-avatar
        :size="300"
        :src="circleUrl"
        style="margin-top: 10px; margin-left: 10px" />
      <el-descriptions :column="1" style="margin-left: 10px" class="custom-descriptions">
        <el-descriptions-item label="姓名">kooriookami</el-descriptions-item>
        <el-descriptions-item label="级别"
          >18100000000</el-descriptions-item
        >
        <el-descriptions-item label="性别">男</el-descriptions-item>

      </el-descriptions>
    </el-col>
    <el-col :span="18">
        <el-table :data="this.checkList" style="width: 500px;margin-top: 400px;">
        <el-table-column
          prop="position"
          label="部位"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column prop="isPass">
            <template #default="scope">
                <el-switch v-model="value1" :active-icon="s" :inactive-icon="Close" />
                {{ scope.row.isPass     }}
             </template>

        </el-table-column>
    </el-table>
    </el-col>
  </el-row>
</template>

<script>
export default {
    data(){
        return{
            checkList:[{position:'头部',isPass:true},{position:'躯干',isPass:true},{position:'左手',isPass:true},
            {position:'又手',isPass:true},{position:'左腿',isPass:true},{position:'右腿',isPass:true}],
            value1:''
        }
    }
};
</script>

<style scpped>

.custom-descriptions .el-descriptions__label {

  font-size: 20px !important;
  height: 60px; /* 你可以根据需要调整高度 */
  line-height: 60px; /* 使文本垂直居中 */
}

.custom-descriptions .el-descriptions__content {
  font-size: 20px !important;
  height: 40px; /* 你可以根据需要调整高度 */
  line-height: 40px; /* 使文本垂直居中 */
}

</style>