<template>
  <div class="background">
    <!--    head-->
    <el-row style="height: 17%; text-align: center;" type="flex" justify="center" align="middle">
      <!--      左logo-->
      <el-col :span="6" :offset="0">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span style="color:white">logo</span>
        </div>
      </el-col>
      <!--      比赛-->
      <el-col :span="8">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span class="fontStyle">{{ CompetitionName }}比赛</span>
        </div>
      </el-col>
      <!--      右logo-->
      <el-col :span="6">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span style="color:white">logo</span>
        </div>
      </el-col>
    </el-row>
    <!--    红方-->
    <el-row style="height: 34%; text-align: center;" type="flex" justify="center" align="middle">
      <!--      头像-->
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <div class="block">
            <img src="../assets/redImg/redHead.png" class="imgSizeStyle">
          </div>
        </div>
      </el-col>
      <!--      单位 姓名-->
      <el-col :span="5">
        <div class="grid-content bg-purple">
          <el-row type="flex" justify="center" align="middle">
            <span class="fontStyleUserName">{{ RedUnit }}</span>
          </el-row>
          <el-row class="fontStyleUserName" type="flex" justify="center" align="middle">
            {{ RedUsername }}
          </el-row>
        </div>
      </el-col>
      <!--      就医-->
      <el-col :span="3" style="height: 30%">
        <div class="backgroundInMedical">
          <div class="center-container">
            <span class="fontMedicalFStyle">{{ RedtreatmentMinutes }}:&nbsp;{{ RedtreatmentSeconds }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="3" style="height: 30%">
        <div class="backgroundInLate">
          <div class="center-container">
            <span class="fontLateStyle">{{ RedLateTimeMinute }}:&nbsp;{{ RedLateTimeSeconds }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="3" style="height: 100%">
        <el-row style="height: 35%">
        </el-row>
        <el-row style="height: 30%;">
          <div class="backgroundInFoul">
            <div class="center-container">
              <span class="fontFOULStyle">{{ RedWarningNum }}</span>
            </div>
          </div>
        </el-row>
        <el-row style="height: 35%">
          <div class="circle-container">
            <div v-for="(circleIndex, index) in WarningCount" :key="index" class="circle"
              :class="{ 'circle-yellow': circleIndex <= RedWarningNum }"></div>
          </div>
        </el-row>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <div class="block">
            <!-- 分数 -->
            <!-- <img :src="require('../assets/img/300ppi/' + this.RedTotalScore + '.png')" class="imgSizeStyle"> -->
            <span class="fontStyleviewTotalScore">{{ RedTotalScore }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="1">
        <div class="grid-content bg-purple">
          <div class="block" v-if="PlaybackDisplay">
            <img src="../assets/img/300ppi/资源 1.png" class="imgSizeStylePlayback">
          </div>
        </div>
      </el-col>
    </el-row>
    <!--    蓝方-->
    <el-row style="height: 33%; text-align: center;" type="flex" justify="center" align="middle">
      <!--      头像-->
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <div class="block">
            <img src="../assets/redImg/redHead.png" class="imgSizeStyle">
          </div>
        </div>
      </el-col>
      <!--      单位 姓名-->
      <el-col :span="5">
        <div class="grid-content bg-purple">
          <el-row type="flex" justify="center" align="middle">
            <span class="fontStyleUserName">{{ BlueUnit }}</span>
          </el-row>
          <el-row class="fontStyleUserName" type="flex" justify="center" align="middle">
            {{ BlueUsername }}
          </el-row>
        </div>
      </el-col>
      <!--      就医-->
      <el-col :span="3" style="height: 30%">
        <div class="backgroundInMedical">
          <div class="center-container">
            <span class="fontMedicalFStyle">{{ BluetreatmentMinutes }}:&nbsp;{{ BluetreatmentSeconds }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="3" style="height: 30%">
        <div class="backgroundInLate">
          <div class="center-container">
            <span class="fontLateStyle">{{ BlueLateTimeMinute }}:&nbsp;{{ BlueLateTimeSeconds }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="3" style="height: 100%">
        <el-row style="height: 35%">
        </el-row>
        <el-row style="height: 30%;">
          <div class="backgroundInFoul">
            <div class="center-container">
              <span class="fontFOULStyle">{{ BlueWarningNum }}</span>
            </div>
          </div>
        </el-row>
        <el-row style="height: 35%">
          <div class="circle-container">
            <div v-for="(circleIndex, index) in WarningCount" :key="index" class="circle"
              :class="{ 'circle-yellow': circleIndex <= BlueWarningNum }"></div>
          </div>
        </el-row>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <div class="block">
            <!-- <img :src="require('../assets/img/300ppi/' + this.BlueTotalScore + '.png')" class="imgSizeStyle"> -->
            <span class="fontStyleviewTotalScore">{{ BlueTotalScore }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="1">
        <div class="grid-content bg-purple">
          <div class="block" v-if="PlaybackDisplay">
            <img src="../assets/img/300ppi/资源 1.png" class="imgSizeStylePlayback">
          </div>
        </div>
      </el-col>
    </el-row>
    <!--    bottom-->
    <el-row style="height: 16%; text-align: center;" type="flex" justify="center" align="middle">
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <el-row type="flex" justify="center" align="middle">
            <span class="fontStyleUserName">{{ Venue }}</span>
          </el-row>
          <el-row class="fontStyleUserName" type="flex" justify="center" align="middle">
            {{ EventGroupName }}
            <!-- <el-button type="primary" @click="test">主要按钮</el-button> -->
          </el-row>
        </div>
      </el-col>
      <el-col :span="8">
        <el-row style="height: 100%;" class="relative-container">
          <div style="width: 40%; height: 100%;" class="awarding-button"><span class="fontStyleCompetitionTime">{{
              secondScreenMinutes }}</span></div>
          <div style="width: 20%; height: 100%;" class="awarding-button"><span class="fontStyleCompetitionTime">:</span>
          </div>
          <div style="width: 40%; height: 100%;" class="awarding-button"><span class="fontStyleCompetitionTime">{{
              viewScreenSeconds }}</span></div>
        </el-row>
      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple-dark"><span class="fontStyle">{{ CompetitionSessions }}</span></div>
      </el-col>
    </el-row>
  </div>
  <div v-if="RedTreatmentState" class="modal">
    <div class="backgroundRedTreatment">
      <el-row style="height: 65%">
      </el-row>
      <el-row style="height: 35%;">
        <el-col :span="4">
        </el-col>
        <el-col :span="6">
          <span class="fontStyleCompetitionTime">{{ RedtreatmentMinutes }}</span>
        </el-col>
        <el-col :span="4">
          <span class="fontStyleCompetitionTime">:</span>
        </el-col>
        <el-col :span="6">
          <span class="fontStyleCompetitionTime">{{ RedtreatmentSeconds }}</span>
        </el-col>
        <el-col :span="4">
        </el-col>
      </el-row>
    </div>
  </div>
  <div v-if="BlueTreatmentState" class="modal">
    <div class="backgroundBlueTreatment">
      <el-row style="height: 65%">
      </el-row>
      <el-row style="height: 35%;">
        <el-col :span="4">
        </el-col>
        <el-col :span="6">
          <span class="fontStyleCompetitionTime">{{ BluetreatmentMinutes }}</span>
        </el-col>
        <el-col :span="4">
          <span class="fontStyleCompetitionTime">:</span>
        </el-col>
        <el-col :span="6">
          <span class="fontStyleCompetitionTime">{{ BluetreatmentSeconds }}</span>
        </el-col>
        <el-col :span="4">
        </el-col>
      </el-row>
    </div>
  </div>
  <div v-if="RedLateState" class="modal">
    <div class="backgroundRedLate">
      <el-row style="height: 65%">
      </el-row>
      <el-row style="height: 35%;">
        <el-col :span="4">
        </el-col>
        <el-col :span="6">
          <span class="fontStyleCompetitionTime">{{ RedLateTimeMinute }}</span>
        </el-col>
        <el-col :span="4">
          <span class="fontStyleCompetitionTime">:</span>
        </el-col>
        <el-col :span="6">
          <span class="fontStyleCompetitionTime">{{ RedLateTimeSeconds }}</span>
        </el-col>
        <el-col :span="4">
        </el-col>
      </el-row>
    </div>
  </div>
  <div v-if="BlueLateState" class="modal">
    <div class="backgroundBlueLate">
      <el-row style="height: 65%">
      </el-row>
      <el-row style="height: 35%;">
        <el-col :span="4">
        </el-col>
        <el-col :span="6">
          <span class="fontStyleCompetitionTime">{{ BlueLateTimeMinute }}</span>
        </el-col>
        <el-col :span="4">
          <span class="fontStyleCompetitionTime">:</span>
        </el-col>
        <el-col :span="6">
          <span class="fontStyleCompetitionTime">{{ BlueLateTimeSeconds }}</span>
        </el-col>
        <el-col :span="4">
        </el-col>
      </el-row>
    </div>
  </div>
    <div v-if="RedSuppressState" class="modal">
    <div class="backgroundRedSuppress">
      <el-row style="height: 65%" >
        <span class="fontStyleSupperss">红方压制中</span>
      </el-row>
      <el-row style="height: 35%;">
        <el-col :span="4">
        </el-col>
        <el-col :span="6">
          <span class="fontStyleCompetitionTime">{{ RedSuppressTimeMinute }}</span>
        </el-col>
        <el-col :span="4">
          <span class="fontStyleCompetitionTime">:</span>
        </el-col>
        <el-col :span="6">
          <span class="fontStyleCompetitionTime">{{ RedSuppressTimeSeconds }}</span>
        </el-col>
        <el-col :span="4">
        </el-col>
      </el-row>
    </div>
  </div>
  <router-view></router-view>
</template>
<script>
export default {
  created(){
      window.addEventListener("storage",e=>{
        if(e.key =="RedTotalScore"){
          this.RedTotalScore = e.newValue
        }
      })
    },
  data() {
    return {
      RedSuppressState: false,//红方压制状态
      RedSuppressTime: 0,//红方压制时间
      RedSuppressTimeMinute: 0,//红方压制时间分钟显示
      RedSuppressTimeSeconds: '0' + 0,//红方压制时间秒钟显示
      WarningCount: 0,//设置警告的次数(默认警告次数为4)
      RedWarningNum: 0, // 红方警告次数
      BlueWarningNum: 0, // 蓝方警告次数
      RedLateState: false,//红方迟到状态
      BlueLateState: false,//蓝方迟到状态
      RedLateTime: 0,//红方迟到时间
      RedLateTimeMinute: 0,//红方迟到时间分钟显示
      RedLateTimeSeconds: '0' + 0,//红方迟到时间秒钟显示
      BlueLateTime: 0,//蓝方迟到时间
      BlueLateTimeMinute: 0,//蓝方迟到时间分钟显示
      BlueLateTimeSeconds: '0' + 0,//蓝方迟到时间秒钟显示
      RedtreatmentTime: 0,
      RedtreatmentMinutes: 0,
      RedtreatmentSeconds: '0' + 0,
      BluetreatmentMinutes: 0,
      BluetreatmentSeconds: '0' + 0,
      BlueTreatmentState: false,
      RedTreatmentState: false,
      viewScreenSeconds: '0' + 0,
      secondScreenMinutes: 0,
      secondScreenSeconds: 0,
      imgUrl: '',
      CompetitionName: '柔道',
      // 头像
      RedSquareUrl: '',
      RedTotalScore: 0,
      BlueTotalScore: 0,
      RedUsername: '张三',
      RedUnit: '上海鬼手道场',
      RedMedicalMinute: '0',
      RedMedicalSecond: '00',
      RedLateMinute: '0',
      RedLateSecond: '00',
      RedFoulNum: '0',
      // true 显示审核图片 false 隐藏
      PlaybackDisplay: true,
      BlueSquareUrl: '',
      BlueUsername: '李四',
      BlueUnit: '上海柔道会场',
      BlueMedicalMinute: '0',
      BlueMedicalSecond: '00',
      BlueLateMinute: '0',
      BlueLateSecond: '00',
      BlueFoulNum: '0',
      //  赛事组别
      Venue: 'A1001',
      EventGroupName: '男子甲组78KG',
      CompetitionTime: '4:00',
      CompetitionSessions: '1/16',
    }
  },
  methods: {
    test() {
      this.RedSuppressState =!this.RedSuppressState
      console.log('aqaa')
    },
    splitTime(time) {
      var minutes;
      var seconds;
      console.log(time)
      minutes = Math.floor(time / 60)
      console.log('bbb' + minutes)
      if (time % 60 < 10) {
        seconds = '0' + time % 60
      }
      else {
        seconds = time % 60
      }
      return minutes, seconds
    },
    ListeningMessage() {
      // this.RedTeatmentState
      // this.RedTreatmentState = localStorage.getItem('RedtreatmentState')

      this.WarningCount = Number(localStorage.getItem('WarningCount'))
      this.RedWarningNum = Number(localStorage.getItem('RedWarningNum'))
      this.BlueWarningNum = Number(localStorage.getItem('BlueWarningNum'))
      this.RedtreatmentTime = localStorage.getItem('RedtreatmentTime')
      this.BluetreatmentTime = localStorage.getItem('BluetreatmentTime')
      this.RedLateTime = localStorage.getItem('RedLateTime')
      this.BlueLateTime = localStorage.getItem('BlueLateTime')
      this.RedSuppressTime = localStorage.getItem('RedSuppressTime')
      this.RedSuppressTimeMinute,this.RedSuppressTimeSeconds = this.splitTime(localStorage.getItem('RedSuppressTime'))
      console.log('aaa'+ this.RedSuppressTimeMinute)
      this.RedLateTimeMinute, this.RedLateTimeSeconds = this.splitTime(localStorage.getItem('RedLateTime'))
      this.BlueLateTimeMinute, this.BlueLateTimeSeconds = this.splitTime(localStorage.getItem('BlueLateTime'))
      this.RedtreatmentMinutes, this.RedtreatmentSeconds = this.splitTime(localStorage.getItem('RedtreatmentTime'))
      this.BluetreatmentMinutes, this.BluetreatmentSeconds = this.splitTime(localStorage.getItem('BluetreatmentTime'))
      // console.log(localStorage.getItem('BluetreatmentState'))
      // console.log(localStorage.getItem('RedtreatmentState'))
      // console.log(localStorage.getItem('RedLateState'))
      // console.log(localStorage.getItem('BlueLateState'))
      if (localStorage.getItem('RedSuppressState') == 1) {
        this.RedSuppressState = true
      }
      else {
        this.RedSuppressState = false
      }
      if (localStorage.getItem('RedLateState') == 1) {
        this.RedLateState = true
      }
      else {
        this.RedLateState = false
      }
      if (localStorage.getItem('BlueLateState') == 1) {
        this.BlueLateState = true
      }
      else {
        this.BlueLateState = false
      }
      if (localStorage.getItem('RedtreatmentState') == 1) {
        this.RedTreatmentState = true
      }
      else {
        this.RedTreatmentState = false
      }
      if (localStorage.getItem('BluetreatmentState') == 1) {
        this.BlueTreatmentState = true
      }
      else {
        this.BlueTreatmentState = false
      }
      this.RedTotalScore = localStorage.getItem('RedTotalScore')
      this.BlueTotalScore = localStorage.getItem('BlueTotalScore')
      this.secondScreenMinutes = localStorage.getItem('minutes')
      if (localStorage.getItem('seconds') < 10) {
        this.secondScreenSeconds = localStorage.getItem('seconds')
        this.viewScreenSeconds = '0' + this.secondScreenSeconds
      }
      else {
        this.secondScreenSeconds = localStorage.getItem('seconds')
        this.viewScreenSeconds = this.secondScreenSeconds
      }
      this.Venue = localStorage.getItem('Venue')
      this.RedUsername = localStorage.getItem('RedUsername')
      this.RedUnit = localStorage.getItem('RedUnit')
      this.BlueUsername = localStorage.getItem('BlueUsername')
      this.BlueUnit = localStorage.getItem('BlueUnit')
      this.EventGroupName = localStorage.getItem('EventGroupName')
      this.CompetitionSessions = localStorage.getItem('CompetitionSessions')
      if (localStorage.getItem('VictorySide') == 0) {
        this.$router.push('/ToBlueWinPage');
      }
      if (localStorage.getItem('VictorySide') == 1) {
        this.$router.push('/ToRedWinPage');
      }
    },
  },
  mounted() {
    setInterval(this.ListeningMessage, 100) // 每秒执行一次 updateCurrentTime 方法,
  },
}
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
}
.circle-container {
  display: flex;
  justify-content: space-around;
  width: 80%; /* 根据需要调整宽度 */
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  line-height: 30px; /* 使文本垂直居中 */
  font-size: 20px; /* 根据需要调整字体大小 */

  margin-left: 10px;
}

.circle-yellow {
  background-color: yellow;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* 半透明黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.backgroundRedSuppress {
  background-color: red;
  width: 40%;
  height: 60%;
  background-size: 100% 100%;
}

.backgroundRedTreatment {
  background: url("../assets/img/300ppi/资源 16.png");
  width: 40%;
  height: 60%;
  background-size: 100% 100%;
}

.backgroundBlueTreatment {
  background: url("../assets/img/300ppi/资源 17.png");
  width: 40%;
  height: 50%;
  background-size: 100% 100%;
}

.backgroundRedLate {
  background: url("../assets/img/300ppi/资源 18.png");
  width: 40%;
  height: 50%;
  background-size: 100% 100%;
}

.backgroundBlueLate {
  background: url("../assets/img/300ppi/资源 19.png");
  width: 40%;
  height: 50%;
  background-size: 100% 100%;
}

.countDownCentre {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
}

.background {
  background: url("../assets/img/background1.png");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}

.fontStyle {
  color: white;
  font-size: calc(100vw * 80 / 1920);
  font-family: 'ysbth';
}

.imgSizeStyle {
  height: 80%;
  width: 60%;
}

.fontStyleUserName {
  color: white;
  font-size: calc(100vw * 80 / 1920 * 0.7);
  font-family: 'ysbth';
}

.imgSizeStyle44-47 {
  height: 40%;
  width: 25%;
  margin: calc(100vw * 80 / 1920 * 0.15);
}

/*就医 诊断 犯规背景设置*/
.backgroundInMedical {
  background: url("../assets/img/300ppi/资源 44.png");
  width: 80%;
  height: 80%;
  background-size: 100% 100%;

}

.backgroundInLate {
  background: url("../assets/img/300ppi/资源 45.png");
  width: 80%;
  height: 80%;
  background-size: 100% 100%;

}

.backgroundInFoul {
  background: url("../assets/img/300ppi/资源 46.png");
  width: 80%;
  height: 80%;
  background-size: 100% 100%;
}

.center-container {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  height: 100%;
  /* 容器高度 */
}

.fontLateStyle {
  font-size: calc(100vw * 80 / 1920 * 0.3);
  margin-left: calc(100vw * 80 / 1920 * 0.6);
  margin-top: calc(100vw * 80 / 1920 * 0.06);
  color: white;
}

.fontMedicalFStyle {
  font-size: calc(100vw * 80 / 1920 * 0.3);
  margin-left: calc(100vw * 80 / 1920 * 0.6);
  margin-top: calc(100vw * 80 / 1920 * 0.02);
  color: white;
}

.fontFOULStyle {
  font-size: calc(100vw * 80 / 1920 * 0.3);
  margin-left: calc(100vw * 80 / 1920 * 0.7);
  margin-top: calc(100vw * 80 / 1920 * 0.05);
  color: white;
}

.imgSizeStylePlayback {
  height: 100%;
  width: 100%;
  margin-right: calc(100vw * 80 / 1920 * 1);
}

.fontStyleCompetitionTime {
  color: rgb(255, 255, 11);
  font-size: calc(100vw * 80 / 1920 * 1.5);
  font-family: 'ysbth';

}
.fontStyleSupperss {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: rgb(255, 255, 11);
  font-size: calc(100vw * 80 / 1920 * 1.7);
  font-family: 'ysbth';
}
.fontStyleviewTotalScore {
  color: white;
  font-weight: 800;
  font-size: calc(100vw * 80 / 1920 * 3);
  font-family: 'ccxyt';
}

.relative-container {
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  /* 确保父容器有高度 */
}
</style>
