<template>
  <div class="match-management">
    <el-card class="match-header">
      <div class="operation-bar">
        <el-button link type="primary" @click="diaAddCompetition = true"
          ><el-icon style="height: 30px; width: 25px">
            <Plus /> </el-icon
          >添加</el-button
        >
        <el-button link type="warning" @click="handleEdit"
          ><el-icon style="height: 30px; width: 25px">
            <EditPen /> </el-icon
          >修改</el-button
        >
        <el-button link type="danger" @click="deleteGame()"
          ><el-icon style="height: 30px; width: 25px">
            <DeleteFilled /> </el-icon
          >删除</el-button
        >
        <el-button link @click="handleRefresh"
          ><el-icon style="height: 30px; width: 25px">
            <Refresh /> </el-icon
          >刷新</el-button
        >
        <div style="margin-left: auto">
          <el-date-picker
            style="width: 150px"
            v-model="startTime"
            type="date"
            placeholder="开始时间"
            :disabled-date="disabledDate"
            :shortcuts="shortcuts"
            size="small"
          />

          <el-date-picker
            style="width: 150px; margin-left: 10px"
            v-model="endTime"
            type="date"
            placeholder="结束时间"
            :disabled-date="disabledDate"
            :shortcuts="shortcuts"
            size="small"
          />
          <el-input
            size="small"
            prefix-icon="Search"
            placeholder="请输入比赛名称"
            v-model="search"
            class="input-with-select"
            clearable
          >
            <el-button icon="el-icon-search" @click="handleSearch"></el-button>
          </el-input>
          <el-button type="primary" link @click="handleAdd">
            <el-icon style="height: 30px; width: 25px">
              <Search />
            </el-icon>
            查找</el-button
          >
        </div>
      </div>
      <el-divider />
      <el-table
        :data="filterTableData"
        border
        style="width: 100%"
        highlight-current-row="true"
        @current-change="handleCurrentChange"
      >
        <el-table-column
          label="id编号"
          prop="id"
          width="70"
          align="center"
          header-align="center"
        ></el-table-column>

        <el-table-column
          label="比赛名称"
          prop="name"
          width="320"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          label="报名开始日期"
          prop="show_regStartDate"
          width="140"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          label="报名结束日期"
          prop="show_regEndDate"
          width="140"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          label="比赛开始日期"
          prop="show_comStartDate"
          width="140"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          label="比赛结束日期"
          prop="show_comEndDate"
          width="140"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          label="比赛状态"
          prop="status"
          width="120"
          align="center"
          header-align="center"
        ></el-table-column>
        <el-table-column
          label="创建人"
          prop="name"
          align="center"
          header-align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="比赛最大人数"
          prop="comMaxPeople"
          align="center"
          header-align="center"
          width="140"
        ></el-table-column>
        <el-table-column label="操作" align="center" header-align="center">
          <template v-slot="scope">
            <el-button
              type="primary"
              size="small"
              @click="ConfigurationCompetition(scope.row)"
            >
              进入比赛
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
            >
              复制
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加比赛 -->
    <el-dialog
      v-model="diaAddCompetition"
      title="添加比赛"
      width="700"
      :before-close="handleClose"
    >
      <el-scrollbar height="500px">
        <div>
          <el-form
            :model="competitionForm"
            label-width="auto"
            style="max-width: 620px"
          >
            <el-form-item label="比赛名称">
              <el-input v-model="competitionForm.competitionName" clearable />
            </el-form-item>
            <el-form-item label="比赛地址">
              <el-input v-model="competitionForm.comAddress" clearable />
            </el-form-item>
            <!-- <el-form-item label="比赛项目">
                            <el-cascader style="width: 600px;" :options="competitionForm.competitionItem" :props="props"
                                clearable>
                                <template #footer>
                                    <el-button v-if="!isAdding" text bg size="small" @click="onAddOption">
                                        Add an option
                                    </el-button>
                                    <template v-else>
                                        <el-input v-model="optionName" class="option-input"
                                            placeholder="input option name" size="small" />
                                        <el-button type="primary" size="small" @click="onConfirm">
                                            confirm
                                        </el-button>
                                        <el-button size="small" @click="clear">cancel</el-button>
                                    </template>
                                </template>
                            </el-cascader>
                        </el-form-item> -->
            <el-form-item label="报名时间">
              <div class="block">
                <el-date-picker
                  v-model="competitionForm.regTime"
                  type="daterange"
                  range-separator="To"
                  value-format="YYYY-MM-DD"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                />
              </div>
            </el-form-item>
            <el-form-item label="比赛时间">
              <div class="block">
                <el-date-picker
                  v-model="competitionForm.competitionTime"
                  type="daterange"
                  range-separator="To"
                  value-format="YYYY-MM-DD"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                />
              </div>
            </el-form-item>
            <el-form-item label="比赛方式">
              <el-radio-group v-model="competitionForm.comType">
                <el-radio value="个人">仅个人</el-radio>
                <el-radio value="团体">仅团体</el-radio>
                <el-radio value="个人和团体">个人和团体</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="最大人数">
              <el-input-number
                v-model="competitionForm.maxPeoerson"
                :min="0"
                :max="10000"
                controls-position="right"
                @change="handleChange"
                clearable
              />
            </el-form-item>
            <el-form-item label="上传logo">
              <el-upload
                class="avatar-uploader"
                action=""
                list-type="picture-card"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
            <el-form-item label="比赛介绍">
              <el-input
                v-model="competitionForm.comIntroduction"
                type="textarea"
              />
            </el-form-item>
          </el-form>
        </div>
      </el-scrollbar>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="diaAddCompetition = false">取消</el-button>
          <el-button type="primary" @click="handleAdd(competitionForm)">
            确定添加
          </el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 比赛配置 -->
    <el-dialog
      v-model="diaConfigurationCompetition"
      title="配置比赛项目"
      width="700"
    >
      <el-form-item label="比赛介绍">
        <el-input v-model="competitionForm.comIntroduction" type="textarea" />
      </el-form-item>
      <template>
        <el-input
          v-model="competitionItemName"
          style="width: 240px"
          placeholder="输入"
        />
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MatchManagement",
  data() {
    return {
      search: "",
      tableData: [],
      searchQuery: "", // 查询
      startTime: "",
      endTime: "",
      diaAddCompetition: false, //添加比赛的显示
      props: { multiple: true }, // 开启多选模式
      competitionForm: {
        competitionName: "",
        regTime: [], // 比赛报名时间
        competitionTime: [], // 比赛时间
        comType: "个人", // 比赛类型
        comMaxPeople: 0, // 比赛的最大人数限制
        comAddress: "",
        comIntroduction: "",
        isAdding: false,
        competitionItemName: "", // 项目名称
        imageUrl: "",
      },
      selectGameId: "",
    };
  },
  created() {
    this.getAllCom();
  },
  computed: {
    filterTableData() {
      return this.tableData.filter(
        (data) =>
          !this.search ||
          data.level1_com_name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    deleteGame(){
        var that = this
        if (this.selectGameId=='') {
            this.$message({
                type:'error',
                message:'请选择比赛'
            })
        }else{
            axios({
                url: that.$globalurl + "game/delete",
                method: "get",
                params:{gameId:this.selectGameId},
                headers: {
                "content-type": "application/json",
        },
            }).then(function(response){
                console.log(response);
              
                if(response.status==200){
                    that.$message({
                        type:'success',
                        message:'删除成功'
                    })   
                    that.getAllCom()
                }else{
                    that.$message({
                        type:'error',
                        message:'系统错误'
                    })   
                }
            })
          
        }
    },

    // 选中行id
    handleCurrentChange(currentRow) {
        if(currentRow){
            this.selectGameId = currentRow.id;
        }else{
            this.selectGameId=''
        }
    },
    getAllCom() {
      var that = this;
      this.selectGameId= ''
      axios({
        url: that.$globalurl + "game/getAll",
        method: "post",
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.tableData = response.data;
        console.log(that.tableData);
      });
    },
    onAddOption() {
      this.isAdding = true;
    },
    handleAdd(competitionForm) {
      this.diaAddCompetition = false;
      const that = this;
      axios({
        url: that.$globalurl + "game/add",
        method: "post",
        data: {
          comAddress: competitionForm.comAddress,
          comEndDate: competitionForm.competitionTime[1],
          comIntroduction: competitionForm.comIntroduction,
          comMaxPeople: parseInt(competitionForm.maxPeoerson),
          comStartDate: competitionForm.competitionTime[0],
          name: competitionForm.competitionName,
          orgCommitteeId: localStorage.getItem("userId"),
          regEndDate: competitionForm.regTime[1],
          regStartDate: competitionForm.regTime[0],
        },
        headers: {
          "content-type": "application/json",
        },
      })
        .then(function (response) {
          if (response.status == 200) {
            that.$message({
              message: "添加成功",
              type: "success",
            });
            that.getAllCom();
          }
        })
        .catch((error) => {
          if (error.response.status == 651) {
            that.$message({
              message: "赛事已存在",
              type: "error",
            });
          } else if (error.response.status == 641) {
            that.$message({
              message: "组委会不存在",
              type: "error",
            });
          }
          console.log(error);
        });
    },
    handleEdit() {
      console.log("修改操作");
      // 这里添加修改操作的逻辑
    },
    handleDelete() {
      console.log("删除操作");
      // 这里添加删除操作的逻辑
    },
    handleRefresh() {
      console.log("刷新操作");
      // 这里添加刷新操作的逻辑
    },
    handleSearch() {
      console.log("搜索操作:", this.searchQuery);
      // 这里添加搜索操作的逻辑
    },
    ConfigurationCompetition(row) {
      console.log(row);
      this.$router.push({
        path: "/ToSelectItemPage",
        query: { comId: row.id, comName: row.name },
      });
    },
  },
};
</script>

<style scoped>
.match-management {
  padding: 20px;
}

.match-header {
  margin-bottom: 20px;
}

.match-list /deep/ .el-card__body {
  padding: 20px;
}

.match-detail {
  margin-left: 20px;
}

.match-info {
  padding: 20px;
}

.dialog-footer {
  text-align: right;
}

.operation-bar {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.operation-bar .el-button {
  margin-right: 10px;
}

.input-with-select {
  margin-left: 10px;
  width: 200px;
}

.el-date-picker_class {
  width: 100px;
}

.option-input {
  width: 100%;
  margin-bottom: 8px;
}

.avatar-uploader .el-upload {
  border: 2px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
  border-color: black;
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>