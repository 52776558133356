<template>
  <div class="backgroundRed">
    <!--    head-->
    <el-row style="height: 17%; text-align: center;" type="flex" justify="center" align="middle">
      <!--      左logo-->
      <el-col :span="6" :offset="0">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span style="color:white">loin</span>
        </div>
      </el-col>
      <!--      比赛-->
      <el-col :span="8">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span class="fontStyleRed">{{ CompetitionName }}比赛</span>
        </div>
      </el-col>
      <!--      右logo-->
      <el-col :span="6">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span style="color:white">login</span>
        </div>
      </el-col>
    </el-row>
    <el-row style="height: 26%; text-align: center;" type="flex" justify="center" align="middle">
      <!--      头像-->
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <div class="block">
            <img src="../assets/redImg/redHead.png" class="imgSizeStyle">
          </div>
        </div>
      </el-col>
      <!--      单位 姓名-->
      <el-col :span="6">
        <span class="fontStyleUserNameRed">{{ Redunit }}</span>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple">
          <div class="block">
            <span></span>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <el-row class="fontStyleUserNameRed" type="flex" justify="center" align="middle">
            {{ ReduserName }}
          </el-row>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple">
          <el-row class="fontStyleUserNameRed" type="flex" justify="center" align="middle">
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row style="height: 41%; text-align: center;" type="flex" justify="center" align="middle">
      <el-col :span="18">
        <div class="grid-content bg-purple">
          <div class="block">
            <span class="fontStyleWinRed">WINNER</span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="height: 16%; text-align: center;" type="flex" justify="center" align="middle">
      <el-col :span="5"></el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <el-row type="flex" justify="center" align="middle">
            <span class="fontStyleUserNameRed">{{ EventGroup }}</span>
          </el-row>
        </div>
      </el-col>
      <el-col :span="10" class="fontStyleRed"><span>{{ EventGroupName }}</span></el-col>
      <el-col :span="5"></el-col>
    </el-row>
  </div>
  <router-view></router-view>
</template>

<script>
export default {

  data() {

    return {
      CompetitionName: '柔道',
      //头像
      RedSquareUrl: '',
      ReduserName: '',
      Redunit: '',
      //  赛事组别
      EventGroup: 'A1001',
      EventGroupName: '男子甲组78KG',
      CompetitionTime: '4:00',
      CompetitionSessions: '1/16'
    };
  },
  methods: {
    ListeningMessage() {
      if (localStorage.getItem('NextCompetition') == 1) {
        localStorage.setItem('NextCompetition', 0)
        this.$router.push('/ToCompetitionPage');
      }
    }
  },
  mounted() {
    setInterval(this.ListeningMessage, 100) // 每秒执行一次 updateCurrentTime 方法,
    this.ReduserName = localStorage.getItem('WinUsername')
    this.Redunit = localStorage.getItem('WinUnit')
    this.CompetitionSessions = localStorage.getItem('CompetitionSessions')
    this.EventGroupName = localStorage.getItem('EventGroupName')
    this.EventGroup = localStorage.getItem('Venue')
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}

.backgroundRed {
  background: url("../assets/img/background2.png");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}

.fontStyleRed {
  color: white;
  font-size: calc(100vw * 80 / 1920);
  font-family: 'ysbth';
}

.imgSizeStyle {
  height: 80%;
  width: 60%;
}

.fontStyleUserNameRed {
  color: white;
  font-size: calc(100vw * 80 / 1920 * 1.2);
  font-family: 'ysbth';
}

.fontStyleUserNameRed {
  color: white;
  font-size: calc(100vw * 80 / 1920);
  font-family: 'ysbth';
}


.fontStyleWinRed {
  color: rgb(255, 255, 11);
  font-size: calc(100vw * 80 / 1920 * 4);
  font-family: 'ysbth';
}
</style>