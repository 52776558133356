<template>
  <div class="backgroundBlue">
    <!--    head-->
    <el-row style="height: 17%; text-align: center;" type="flex" justify="center" align="middle">
      <!--      左logo-->
      <el-col :span="6" :offset="0">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span style="color:white">loin</span>
        </div>
      </el-col>
      <!--      比赛-->
      <el-col :span="8">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span class="fontStyleBlue">{{ CompetitionName }}比赛</span>
        </div>
      </el-col>
      <!--      右logo-->
      <el-col :span="6">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span style="color:white">login</span>
        </div>
      </el-col>
    </el-row>
    <el-row style="height: 26%; text-align: center;" type="flex" justify="center" align="middle">
      <!--      头像-->
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <div class="block">
            <img src="../assets/redImg/redHead.png" class="imgSizeStyleBlue">
          </div>
        </div>
      </el-col>
      <!--      单位 姓名-->
      <el-col :span="6">
        <span class="fontStyleUserNameBlue">{{ Blueunit }}</span>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple">
          <div class="block">
            <span></span>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple">
          <el-row class="fontStyleUserNameBlue" type="flex" justify="center" align="middle">
            {{ BlueuserName }}
          </el-row>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content bg-purple">
          <el-row class="fontStyleUserNameBlue" type="flex" justify="center" align="middle">
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row style="height: 41%; text-align: center;" type="flex" justify="center" align="middle">
      <el-col :span="18">
        <div class="grid-content bg-purple">
          <div class="block">
            <span class="fontStyleWinBlue">WINNER</span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="height: 16%; text-align: center;" type="flex" justify="center" align="middle">
      <el-col :span="5"></el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <el-row type="flex" justify="center" align="middle">
            <span class="fontStyleUserNameBlue">{{ EventGroup }}</span>
          </el-row>
        </div>
      </el-col>
      <el-col :span="10" class="fontStyle  "><span>{{ EventGroupName }}</span></el-col>
      <el-col :span="5"></el-col>
    </el-row>
  </div>
          
</template>

<script>
export default {

  data() {

    return {
      CompetitionName: '柔道',
      // true 显示审核图片 false 隐藏
      BlueSquareUrl: '',
      BlueuserName: '',
      Blueunit: '上海柔道会场',
      //  赛事组别
      EventGroup: 'A1001',
      EventGroupName: '男子甲组78KG',
      CompetitionTime: '4:00',
      CompetitionSessions: '1/16'
    };
  },
  methods: {
    ListeningMessage() {

      if (localStorage.getItem('NextCompetition') == 1) {
        localStorage.setItem('NextCompetition', 0)
        this.$router.push('/ToCompetitionPage');
      }
    }
  },
  mounted() {
    setInterval(this.ListeningMessage, 100) // 每秒执行一次 updateCurrentTime 方法,
    this.BlueuserName = localStorage.getItem('WinUsername')
    this.Blueunit = localStorage.getItem('WinUnit')
    this.CompetitionSessions = localStorage.getItem('CompetitionSessions')
    this.EventGroupName = localStorage.getItem('EventGroupName')
    this.EventGroup = localStorage.getItem('Venue')

  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}

.backgroundBlue {
  background: url("../assets/img/background3.png");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}

.fontStyleBlue {
  color: white;
  font-size: calc(100vw * 80 / 1920);
  font-family: 'ysbth';
}

.imgSizeStyleBlue {
  height: 80%;
  width: 60%;
}

.fontStyleUserNameBlue {
  color: white;
  font-size: calc(100vw * 80 / 1920 * 1.2);
  font-family: 'ysbth';
}

.fontStyleUserNameBlue {
  color: white;
  font-size: calc(100vw * 80 / 1920);
  font-family: 'ysbth';
}


.fontStyleWinBlue {
  color: rgb(255, 255, 11);
  font-size: calc(100vw * 80 / 1920 * 4);
  font-family: 'ysbth';
}
</style>