<template>
  <div>
    <el-row style="min-height: 100vh;width: 100%;background-color: rgb(246, 246, 246);">
      <el-col :span="4" style="height: 100%;">
      </el-col>
      <el-col :span="16">
        <!-- <el-card class="informationformcontainer">
          <el-row class="reginformationtipscontainer">
            <el-divider style="width: 80%;margin-left: 10%;">报名信息</el-divider>
          </el-row>
          <el-row class="formnamecontainer">
            <el-col :span="2"></el-col>
            <el-col :span="2" class="formnametipscontainer">
              <span class="namefontstyle">名字</span>
            </el-col>
            <el-col :span="8" class="formnameinputcontainer">
              <el-input v-model="input" placeholder="请输入名字"></el-input>
            </el-col>
          </el-row>
        </el-card> -->
        <el-card class="informationformcontainer">
          <el-row class="reginformationtipscontainer">
            <el-divider style="width: 80%;margin-left: 10%;">报名信息</el-divider>
          </el-row>
          <el-row>
            <el-col :span="2"></el-col>
            <el-col :span="10">
              <el-form :model="form" label-width="130px" :rules="rules" ref="formRef">
                <el-form-item label="姓名" required>
                  <el-input v-model="form.athletesname" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="代表队" prop="representative" required>
                 <el-select v-model="form.representative" placeholder="选择代表队" disabled>
                  <el-option v-for="item in representativeList" :key="item.value"  :label="item.label" :value="item.value"></el-option>
                 </el-select>

                </el-form-item>
                <el-form-item label="国籍" required>
                  <el-input v-model="form.athletenational" placeholder="请输入国籍"></el-input>
                </el-form-item>
                <el-form-item label="民族" required>
                  <el-input v-model="form.athletenation" placeholder="请输入民族"></el-input>
                </el-form-item>
                <el-form-item label="证件类型" required>
                  <el-select v-model="form.athletecertificatetype" placeholder="请选择证件类型">
                    <el-option v-for="item in certificatetype" :key="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="证件号码" required>
                  <el-input v-model="form.athletecertificatenumber" placeholder="请输入证件号码"></el-input>
                </el-form-item>
                <el-form-item label="性别" required>
                  <el-select v-model="form.athletegender" placeholder="请选择你的性别">
                    <el-option v-for="item in gender" :key="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="出生日期" required>
                  <el-date-picker type="date" placeholder="选择日期" v-model="form.athletesbirthday"  format="YYYY/MM/DD"
        value-format="YYYY-MM-DD"
                    style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="手机号码" required>
                  <el-input v-model="form.athletesphoneNumber" placeholder="请输入你的手机号"></el-input>
                </el-form-item>
                <el-form-item label="紧急联系人" required>
                   <el-input v-model="form.emergencyContactPerson" placeholder="请输入紧急联系人名字"></el-input>
                </el-form-item>
                <el-form-item label="紧急联系方式" required>
                   <el-input v-model="form.emergencyContactPhone" placeholder="请输入紧急联系人联系方式"></el-input>
                </el-form-item>
                <el-form-item label="与本人关系" required>
                  <el-select v-model="form.emergencyContactPersonrelationship" placeholder="请选择紧急联系人与别人关系">
                    <el-option v-for="item in relationship" :key="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="详细地址">
                  <el-input v-model="form.athletesaddress" placeholder="请输入你的详细地址"></el-input>
                </el-form-item>
                <!-- <el-form-item label="上传免冠正面照" required>
                  <el-upload class="avatar-uploader" list-type="picture-card" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img v-if="form.athletesheadimage" :src="form.athletesheadimage" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon">
                      <Plus />
                    </el-icon>
                  </el-upload>
                </el-form-item>
                <el-form-item label="上传证件照正面" required>
                  <el-upload class="avatar-uploader" list-type="picture-card" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img v-if="form.athletesheadimage" :src="form.athletesheadimage" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon">
                      <Plus />
                    </el-icon>
                  </el-upload>
                </el-form-item>
                <el-form-item label="上传证件照反面" required>
                  <el-upload class="avatar-uploader" list-type="picture-card" :show-file-list="false"
                    :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                    <img v-if="form.athletesheadimage" :src="form.athletesheadimage" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon">
                      <Plus />
                    </el-icon>
                  </el-upload>
                </el-form-item> -->
                <el-form-item>
                  <el-button type="primary" @click="onSubmit(form)">立即报名</el-button>
                  <el-button @click="cancel()">取消</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      form: {
        athletesheadimage: '',
        athletesname: '',
        athletenational: '',
        athletenation: '',
        athletecertificatetype: '',
        athletecertificatenumber: '',
        athletegender: '',
        athletesbirthday: '',
        athletesphoneNumber: '',
        athletesarea: '',
        athletesaddress: '',
        emergencyContactPerson: '',
        emergencyContactPhone: '',
        emergencyContactPersonrelationship: '',
        athletesidentificationphoto: '',
        representative:'',
        representativeId:''
      },
      relationship: [{ label: '父子/父女', value: '父子/父女' }, { label: '母子/母女', value: '母子/母女' }, { label: '兄弟/兄妹', value: '兄弟/兄妹' }, { label: '姐弟/姐妹', value: '姐弟/姐妹' }, { label: '其他', value: '其他' }],
      gender: [{ label: '男', value: '男子' }, { label: '女', value: '女子' }],
      certificatetype: [{ label: '身份证', value: '身份证' }, { label: '护照', value: '护照' }, { label: '港、澳、台居民来往内地通行证', value: '港、澳、台居民来往内地通行证' }, { label: '外国人永久居留证', value: '外国人永久居留证' }, { label: '其他', value: '其他' }],
      representativeList:[],
      rules: {
        representative: [
          { required: true, message: '请选择代表队', trigger: 'blur' }
        ],
        // 其他验证规则
      },
      formRef:null
    }
  },
  created(){
    this.getAllDelegation()
    this.getUser()
    this.form.representative=this.$route.query.seleceDeleagationName
    this.form.representativeId=this.$route.query.seleceDeleagationId
  },
  methods: {
    getAllDelegation(){
      var that =this
      console.log({athleteId:localStorage.getItem("userId"),gameId:localStorage.getItem("comId")});
      axios({
        url: that.$globalurl+'team/getByAthleteIdAndGameId',
        method:'get',
        params:{athleteId:localStorage.getItem("userId"),gameId:localStorage.getItem("comId")},
        headers:{
          'content-type':'application/json'
                }
      }).then(function(response){
            for (let index = 0; index < response.data.length; index++) {
              that.representativeList.push({value:response.data[index].id,label:response.data[index].name})
            }
      })
    },
    getUser(){
      var that = this
      axios({
        url: that.$globalurl+'athlete/getById',
        method:'post',
        data:localStorage.getItem("userId"),
        headers:{
          'content-type':'application/json'
                }
      }).then(function(response){
        // console.log(response.data);
        that.form.athletesname = response.data.name
        that.form.athletesphoneNumber = response.data.phone
        that.form.athletesbirthday = response.data.birthday
        that.form.athletecertificatenumber = response.data.cardId
        that.form.athletecertificatetype = response.data.cardType
        that.form.athletegender = response.data.gender
        that.form.athletenational = response.data.country
        that.form.athletegender = response.data.gender
        that.form.athletenation = response.data.ethnic
        that.form.emergencyContactPerson = response.data.contactName
        that.form.emergencyContactPersonrelationship = response.data.contactRelationship
        that.form.emergencyContactPhone = response.data.contactPhone
        that.form.athletesaddress = response.data.fullAddress
        console.log(response.data);
      })
    },
    onSubmit() {
      
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          var that = this
      // 修改用户信息
      axios({
        url: that.$globalurl+'athlete/update',
        method:'post',
        data:{id:localStorage.getItem("userId"),fullAddress:that.form.athletesaddress,birthday:that.form.athletesbirthday,cardId:that.form.athletecertificatenumber,
        cardType:that.form.athletecertificatetype,contactName:that.form.emergencyContactPerson,contactPhone:that.form.emergencyContactPhone,contactRelationship:that.form.emergencyContactPersonrelationship,
        country:that.form.athletenational,ethnic:that.form.athletenation,gender:that.form.athletegender,name:that.form.athletesname,phone:that.form.athletesphoneNumber
        },
        headers:{
          'content-type':'application/json'
                }
      }).then(function(){
        axios({
       
       url: that.$globalurl+'applicationApply/individualApply',
       method:'get',
       params:{athleteId:localStorage.getItem("userId"),comItemId:that.$route.query.itemId,teamId:that.form.representativeId},
       headers:{
         'content-type':'application/json'
              }
     }).then(function(){
       that.$message({
           type:'success',
           message:'报名成功'
         })
         that.$router.push({
          path:'/ToCompetitionInformation',
          query:{competitionInformationId:that.$route.query.competitionInformationId}
         });
     }).catch(function(error){
      console.log(error);
       if (error.response.status==681) {
         that.$message({
           type:'error',
           message:'用户已报名'
         })
       }
       if (error.response.status==682) {
         that.$message({
           type:'error',
           message:'运动员已报名该比赛'
         })
       }
     })
      })
    
        } else {
          this.$message({
            type:'error',
            message:'请正确填写内容'
          })
          return false;
        }
      })

    },
    handleAvatarSuccess(res, file) {
      this.form.headimageurl = URL.createObjectURL(file.raw);
      console.log(file.raw)
    },
    beforeAvatarUpload(file) {
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isPNG) {
        this.$message.error('上传头像图片只能是 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isPNG && isLt2M;
    },
    cancel(){
      this.$router.go(-1); 
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 2px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
  border-color: black;
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}





.namefontstyle {
  font-size: 15px;
  opacity: 0.7;
}

.formnameinputcontainer {
  display: flex;
  align-items: center;
}

.formnametipscontainer {
  display: flex;
  align-items: center;
}

.informationformcontainer {
  height: 100%;
  background-color: white;
}

.reginformationtipscontainer {
  height: 80px;
  display: flex;
  align-items: center;
}

.formnamecontainer {
  width: 100%;
  height: 80px;
  display: flex;
}

.namefontstyle {
  margin-left: 20%;
}
</style>