<template>
  <el-card class="box-card">
    <el-button link type="primary" @click="diaAddCompetitionConfig = true"
      ><el-icon style="height: 30px; width: 25px"> <Plus /> </el-icon
      >添加比赛项目</el-button
    >
    <!-- 显示比赛 -->
    <el-row :gutter="20">
      <el-col :span="6" v-for="(item, index) in itemList" :key="index">
        <el-card class="match-card">
          <div class="match-info">
            <h3>
              {{ item.name }}{{ item.type }}{{ item.gender }}{{ item.level
              }}{{ item.weight }}
            </h3>
            <h4>
              {{ item.constituencyName }}
            </h4>
            <h4>{{ item.arrangeType }}</h4>  <h4 >报名人数:{{ item.applicationNum }}</h4>
            <p>
              <el-button link type="primary" @click="toArrange(item)"
                >编排</el-button
              ><el-button link type="primary" @click="toLottery(item)">
                抽签</el-button
              ><el-button link type="success">设置</el-button>
              <el-popconfirm title="是否确定删除" @confirm="submitDelete(item)">
                <template #reference>
                  <el-button link type="danger">删除</el-button>
                
                </template>
              </el-popconfirm>
            </p>
            <p>
              <el-button link type="primary" @click="toPrintOrderSheet(item)"
                >打印秩序单</el-button
              >
            </p>
            <p>
              <el-button link type="primary" @click="toReportPrint(item)"
                >打印报表</el-button
              >
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-card>
  <el-dialog
    v-model="diaAddCompetitionConfig"
    title="添加比赛"
    width="700"
    :before-close="handleClose"
  >
    <el-scrollbar height="500px">
      <el-form label-width="100px">
        <el-form-item label="项目名称">
          <el-input
            :disabled="true"
            style="width: 200px"
            v-model="this.itemForm.name"
            clearable
          />
        </el-form-item>
        <el-form-item label="组别">
          <el-select
            v-model="itemForm.constituencyId"
            placeholder="选择组别"
            style="width: 200px"
          >
            <el-option
              v-for="group in groupList"
              :key="group.id"
              :label="group.name"
              :value="group.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="itemForm.gender">
            <el-radio value="男子">男子</el-radio>
            <el-radio value="女子">女子</el-radio>
            <el-radio value="混打">混打</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="编排方式">
          <el-radio-group v-model="itemForm.arrangeType">
            <el-radio value="普通单败淘汰赛">普通单败淘汰赛</el-radio>
            <el-radio value="普通双败淘汰赛败">普通双败淘汰赛</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="项目级别">
          <el-input
            v-model="itemForm.level"
            style="width: 200px"
            @change="handleChange"
          />
        </el-form-item>
        <el-form-item label="项目类型">
          <el-radio-group v-model="itemForm.type">
            <el-radio value="个人">个人</el-radio>
            <el-radio value="团体">团体</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="团队最大人数">
          <el-input-number
            :disabled="itemForm.type == '个人'"
            v-model="itemForm.groupMaxNum"
            :min="1"
            :max="20"
            @change="handleChange"
          />
        </el-form-item>
        <el-form-item label="团队最小人数">
          <el-input-number
            :disabled="itemForm.type == '个人'"
            v-model="itemForm.groupMinNum"
            :min="1"
            :max="20"
            @change="handleChange"
          />
        </el-form-item>
        <el-form-item label="单位限制">
          <el-radio-group v-model="itemForm.isUniteLimit">
            <el-radio value="不限制">不限制</el-radio>
            <el-radio value="限制">限制</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="单位限制">
          <el-input-number
            :disabled="itemForm.isUniteLimit == '不限制'"
            v-model="itemForm.uniteLimitNumber"
            :min="0"
            :max="20"
            @change="handleChange"
          />
        </el-form-item>
        <el-form-item label="附加赛">
          <el-checkbox v-model.number="itemForm.extra_3_4" value="1">
            3-4
          </el-checkbox>
          <el-checkbox v-model.number="itemForm.extra_5_6" value="1">
            5-6
          </el-checkbox>
        </el-form-item>
      </el-form>
    </el-scrollbar>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="diaAddCompetitionConfig = false">取消</el-button>
        <el-button type="primary" @click="handleAdd(itemForm)">
          确定添加
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      itemList: [],
      filteredData: [],
      groupList: [],
      diaAddCompetitionConfig: false,
      itemForm: {
        name: localStorage.getItem("itemName"),
        type: "个人",
        level: "",
        groupMaxNum: "1",
        groupMinNum: "1",
        gender: "男子",
        weight: "30",
        arrangeType: "普通单败淘汰赛",
        extra_3_4: false,
        extra_5_6: false,
        constituencyId: "",
        isUniteLimit: "不限制",
        uniteLimitNumber: "",
      },
    };
  },
  created() {
    this.getAllLevelByitemName();
    this.itemForm.name = localStorage.getItem("itemName");
    this.getAllGropu();
  },
  methods: {
    // 删除
    submitDelete(item) {
      var that = this;
      axios({
        url: this.$globalurl + "comItem/delete",
        method: "get",
        params: { comItemId: item.comItemId },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (response.status == 200) {
          that.$message({
            type: "success",
            message: "删除成功",
          });
          that.getAllLevelByitemName();
        } else {
          that.$message({
            type: "error",
            message: "删除失败",
          });
        }
      });
    },
    // 获取所有的组别
    getAllGropu() {
      console.log( localStorage.getItem("comId"));
      var that = this;
      axios({
        url: this.$globalurl + "constituency/getByGameIdAndItemName",
        method: "get",
        params: {
          comItemName: localStorage.getItem("itemName"),
          gameId: localStorage.getItem("comId"),
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.groupList = response.data;
      });
    },
    // 去打印报表
    toPrintOrderSheet(item) {
      this.$router.push({
        path: "/ToTemplateviewPage",
        query: { comItemId: item.comItemId },
      });
    },
    // 去编排
    toArrange(item) {
      console.log(item.comItemId);
      var that = this;
      axios({
        url: this.$globalurl + "arrange/createArrangeForComItem",
        method: "post",
        params: { comItemId: item.comItemId },
        // headers:{
        //     // 'content-type':'application/json'
        // }
      })
        .then(function (response) {
          console.log(response.data);
          that.$router.push({
            path: "/ToOpponentChartPage",
            query: { comItemId: item.comItemId },
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 686) {
            that.$message({
              type: "error",
              message: "无人参赛",
            });
          } else if (error.response.status == 692) {
            that.$router.push({
              path: "/ToOpponentChartPage",
              query: { comItemId: item.comItemId },
            });
            console.log(error);
          }
        });
    },
    // 去抽签
    toLottery(item) {
      console.log(item.comItemId);
      this.$router.push({
        path: "/ToLotteryPage",
        query: { comItemId: item.comItemId },
      });
    },
    handleAdd(itemForm) {
      console.log(itemForm);
      this.diaAddCompetitionConfig = false;
      var that = this;
      if (itemForm.extra_3_4 == true) {
        itemForm.extra_3_4 = "1";
      } else {
        itemForm.extra_3_4 = "0";
      }
      if (itemForm.extra_5_6 == true) {
        itemForm.extra_5_6 = "1";
      } else {
        itemForm.extra_5_6 = "0";
      }
      console.log(itemForm);
      axios({
        url: this.$globalurl + "comItem/add",
        method: "post",
        data: {
          gameId: localStorage.getItem("comId"),
          presetItemDTO: itemForm,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        console.log(response.data);
        that.getAllLevelByitemName();
      });
    },
    getAllLevelByitemName() {
      var that = this;
      axios({
        url: this.$globalurl + "comItem/getByCondition",
        method: "post",
        data: {
          gameId: localStorage.getItem("comId"),
          name: localStorage.getItem("itemName"),
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.itemList = response.data;
        console.log(response.data);
      });
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.$router.push("/ToOpponentChartPage");
    },
    LotteryEvent() {
      this.$router.push("/ToLotteryPage");
    },
    toReportPrint(item) {
      console.log(item);
      let routeUrl = this.$router.resolve({
        path: "/ToReporttemplatePage",
        query: { comItemId: item.comItemId, type: item.type },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style>
.el-row {
  margin-bottom: 20px;
}

.el-col {
  margin-bottom: 20px;
}

.match-card {
  cursor: pointer;
}

.match-info h3 {
  margin: 0;
}

.dialog-footer {
  text-align: right;
}

.el-row {
  margin-bottom: 20px;
}

.el-col {
  margin-bottom: 20px;
}

.match-card {
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* 平滑过渡效果 */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /* 初始阴影 */
  margin-top: 10px;
}

.match-card:hover,
.match-card:focus,
.match-card.active {
  /* 悬浮、聚焦或激活状态 */
  transform: scale(1.05);
  /* 放大到105% */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  /* 更明显的阴影 */
  z-index: 10;
  /* 确保放大的卡片在其他元素上方 */
}

.match-info h3 {
  margin: 0;
}

.dialog-footer {
  text-align: right;
}
</style>
