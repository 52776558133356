<template>
    <el-card class="box-card">
        <el-tabs style="margin-left: 10px;" v-model="editableTabsValue"  closable
                    @tab-remove="removeTab" @tab-click="handleTabClick">
                    <el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name">
                    </el-tab-pane>
                </el-tabs>

        </el-card>
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
</template>
<script>
import axios from 'axios';
export default {
    data(){
        return{
            itemList:[],
            filteredData:[],
            diaAddCompetitionConfig:false,
            itemForm:{
                name:this.$route.query.itemName,
                type:'个人',
                level:'',
                groupMaxNum:'1',
                gender:'男子',
                weight:'30',
                arrangeType:"普通单败淘汰赛"
            },
        
        }
    },
    created(){
        this.getAllLevelByitemName();
    },
    methods:{
    handleAdd(itemForm){
        this.diaAddCompetitionConfig = false
        var that =this
        console.log();
        axios({
            url: this.$globalurl+'comItem/add',
            method:'post',
            data:{"gameId":this.$route.query.comId,"presetItemDTO":itemForm},
            headers:{
            'content-type':'application/json'
            }
        }).then(function(response){
            console.log(response.data);
            that.getAllLevelByitemName()
        })

    },
    getAllLevelByitemName(){
        var that =this
        axios({
        url: 'https://www.nchu-xtc.com:8080/comItem/getByCondition',
        method:'post',
        data:{"gameId":this.$route.query.comId,"name":this.$route.query.itemName},
        headers:{
        'content-type':'application/json'
        }
    }).then(function (response){
        console.log(response.data);
        that.itemList = response.data
    })
        },
    handleEdit(index,row){
        console.log(index,row);
        this.$router.push('/ToOpponentChartPage')
        
    },
    LotteryEvent(){
        this.$router.push('/ToLotteryPage')
    },
        // 移除标签的方法
    removeTab(targetName) {
        
    let tabs = this.editableTabs;
    let activeName = this.editableTabsValue;
    let flag = 0
    if (activeName === targetName) {
    tabs.forEach((tab, index) => {
        if (tab.name === targetName) {
        let nextTab = tabs[index + 1] || tabs[index - 1];
        flag = index
        if (nextTab) {
            activeName = nextTab.name;
        }
        }
    });
    }
    
    this.editableTabsValue = activeName;
    this.editableTabs = tabs.filter(tab => tab.name !== targetName);
    if(this.editableTabs.length==0){
        this.$router.push('/ToConfigurationCompetitionPage');
        this.menuActive = "/"+"ToConfigurationCompetitionPage"
        return
    }
    if(this.editableTabs[flag]==null){
        flag--
    }
    this.menuActive = "/"+this.editableTabs[flag].name
    this.$router.push('/'+this.editableTabs[flag].name);
    },
        // 添加标签的方法
        addTab(targetName,url) {
        for(let i =0 ; i<this.editableTabs.length;i++){
            if(this.editableTabs[i].title==targetName){
                this.editableTabsValue = url;
                return
            }
        }
        this.editableTabs.push({
          title: targetName,
          name: url
        });
        this.editableTabsValue = url;
      },
      handleTabClick(tab) {
        this.menuActive = "/"+tab.props.name
        this.$router.push('/'+ tab.props.name);
    }



    },

}
</script>

<style>

.el-row {
margin-bottom: 20px;
}

.el-col {
margin-bottom: 20px;
}

.match-card {
cursor: pointer;
}

.match-info h3 {
margin: 0;
}

.dialog-footer {
text-align: right;
}


.match-card {
cursor: pointer;
transition: transform 0.3s ease, box-shadow 0.3s ease;
/* 平滑过渡效果 */
box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
/* 初始阴影 */
margin-top: 10px;
}

.match-card:hover,
.match-card:focus,
.match-card.active {
/* 悬浮、聚焦或激活状态 */
transform: scale(1.05);
/* 放大到105% */
box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
/* 更明显的阴影 */
z-index: 10;
/* 确保放大的卡片在其他元素上方 */
}

.match-info h3 {
margin: 0;
}

.dialog-footer {
text-align: right;
}

</style>
