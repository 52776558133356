<template>
  <div>
    <svg :width="width" :height="height">
      <!-- 红色名字 -->
      <text
        @click="clickRectBlue()"
        x="40"
        :y="y_test_up"
        fill="black"
        font-family="Arial"
        font-size="10"
        text-anchor="middle"
        alignment-baseline="central"
        v-text="usernameBlueShow"
        :style="{ visibility: this.showNameBlue }"
      ></text>
      <!-- 中线 -->
      <line
        :x1="x_mid-50"
        :y1="y_mid"
        :x2="width"
        :y2="y_mid"
        style="stroke: black; stroke-width: 1"
      />
      <!-- 下线 -->
      <line
        :x1="this.width-50"
        :y1="y_up"
        :x2="this.width-50"
        y2="50%"
        style="stroke: black; stroke-width: 1"
        :style="{ visibility: this.showLink }"
      />

      <line
        x1="0"
        :y1="y_up"
        :x2="this.width-50"
        :y2="y_up"
        style="stroke: black; stroke-width: 1"
        :style="{ visibility: this.showLink }"
      />

      <text
        @click="clickRectRed()"
        x="40"
        :y="y_test_down"
        fill="black"
        font-family="Arial"
        font-size="10"
        text-anchor="middle"
        alignment-baseline="central"
        v-text="usernameRedShow"
        :style="{ visibility: this.showNameRed }"
      ></text>
      <line
        :x1="this.width-50"
        y1="50%"
       :x2="this.width-50"
        :y2="y_down"
        style="stroke: black; stroke-width: 1"
        :style="{ visibility: this.showLink }"
      />
      <text
        x="40%"
        :y="y_session"
        fill="black"
        font-family="Arial"
        font-size="12"
        text-anchor="middle"
        alignment-baseline="central"
        v-text="session"
        :style="{ visibility: this.showSession }"
      ></text>
      <text
        x="40%"
        :y="y_part"
        fill="black"
        font-family="Arial"
        font-size="12"
        text-anchor="middle"
        alignment-baseline="central"
        font-weight="bold"
        v-text="part"
        :style="{ visibility: this.showPart }"
      ></text>
      <line
        x1="0"
        :y1="y_down"
        :x2="this.width-50"
        :y2="y_down"
        style="stroke: black; stroke-width: 1"
        :style="{ visibility: this.showLink }"
      />
    </svg>
  </div>
</template>

<script>

export default {
  name: "MatchDiagram",
  props: {
    height: {
      type: String,
      default: "100",
    },
    matchWidth: {
      type: String,
      default: "270",
    },
    usernameBlue: {
      type: String,
      default: "#",
    },
    usernameRed: {
      type: String,
      default: "#",
    },
    session: {
      type: String,
      default: "#",
    },
    keyIndex: {
      type: String,
      default: "#",
    },
    levels: {
      type: String,
      default: "#",
    },
    nowLevel: {
      type: String,
      default: "#",
    },
    height_up: {
      type: String,
      default: "#",
    },
    height_down: {
      type: String,
      default: "#",
    },
    showDataList: {
      type: Array,
      default: () => [],
    },
    index: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displayText: "张三",
      y1: parseInt(this.height) * 0.25 - 15 + "",
      y2: parseInt(this.height) * 0.75 - 15 + "",
      showPage: "visible",
      colorRedShow: "black",
      colorBlueShow: "black",
      y_session: "",
      y_test_up: "",
      y_test_down: "",
      // showLink:'visible'
      x_mid: 200,
      y_up: "25%",
      y_down: "75%",
      height_show: "",
      x_up: "",
      y_mid: "",
      usernameBlueShow: "",
      usernameRedShow: "",
      showNameBlue: "visible",
      showNameRed: "visible",
      showSession: "visible",
      showPart: "hidden",
      y_part: "",
      part: "",
      width: '',
    };
  },
  created() {
    (this.usernameBlueShow = this.usernameBlue),
      (this.usernameRedShow = this.usernameRed);
    this.y_up = this.height_up;
    this.y_down = this.height_down;
    this.y_test_up = this.y_up - 7;
    this.y_test_down = this.y_down - 7;
      if(this.levels==3){
          this.width=270
       
      }else if(this.levels==4){
          this.width=200
      }else if(this.levels==5){
        this.width=150
      }
      this.x_mid= this.width
    if (this.nowLevel != 1) {
      this.y_mid = Math.floor((this.y_down - this.y_up) / 2 + this.y_up);
    } else {
      this.y_mid = this.height * 0.5;
    }
    this.y_session = this.y_mid - 7;
    this.y_part = this.y_mid - 20;

    if (this.usernameBlue != "none") {
      this.colorBlueShow = "blue";
    }
    if (this.usernameRed != "none") {
      this.colorRedShow = "red";
    }
    if (this.levels - this.nowLevel == 1) {
      console.log("dada" + this.index);
      this.showPart = "visible";
      if (this.index == 4) {
        this.part = "Pool A";
      } else {
        this.part = "Pool B";
      }
    }

    if (this.usernameRed == "none2" && this.nowLevel == 1) {
      this.showLink = "hidden";
      this.showSession = "hidden";
      this.x_mid = 0;
      this.usernameRedShow = this.showDataList[parseInt(this.index) / 2 - 1];
      this.usernameBlueShow = "";
    }
    if (
      this.showDataList[parseInt(this.index)] == "null  null" ||
      this.showDataList[parseInt((this.index + 1) * 2)] == "none2"
    ) {
      this.showNameRed = "hidden";
    }
    if (
      this.showDataList[parseInt(this.index) - 1] == "null  null" ||
      this.showDataList[parseInt(this.index * 2)] == "none2"
    ) {
      this.showNameBlue = "hidden";
    }
  },
  methods: {
    clickRectBlue() {
      const username = this.usernameBlue; // 假设点击时需要显示的用户名
      var keyIndex = this.keyIndex;
      this.$emit("username-clicked", username); // 触发自定义事件并传递用户名
      this.$emit("keyIndex-clicked", keyIndex);
    },
    clickRectRed() {
      const username = this.usernameRed; // 假设点击时需要显示的用户名
      var keyIndex = this.keyIndex;
      this.$emit("username-clicked", username); // 触发自定义事件并传递用户名
      this.$emit("keyIndex-clicked", keyIndex);
    },
  },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
}
rect {
  cursor: pointer; /* 当鼠标悬浮在rect元素上时，光标变成小手 */
}
.animate-click {
  transform: scale(1.1);
  transition: transform 0.2s; /* 动画效果持续时间 */
}
text {
  cursor: pointer; /* 当鼠标悬浮在rect元素上时，光标变成小手 */
}
</style>