<template>
  <div class="main-container">
    <div>
      <svg  width="790" height="40" >
       
        <rect  x="2" y="2"  height="35" width="780" style="fill:rgb(255,255,255);stroke-width:2;stroke: black;" />
       <text x="15" y="15"  font-family="Arial" font-size="10"  font-weight="bold"   text-anchor="middle" alignment-baseline="central"   v-text="Bib"></text>
        <text x="15" y="25"  font-family="Arial" font-size="10"  font-weight="bold"   text-anchor="middle" alignment-baseline="central"   v-text="No"></text> 
        <text x="55" y="20"  font-family="Arial" font-size="10"  font-weight="bold"   text-anchor="middle" alignment-baseline="central"   v-text="name"></text>
        <text :x="x_GoldMedalContest" y="20"  font-family="Arial" font-size="10"  font-weight="bold"   text-anchor="middle" alignment-baseline="central"   v-text="GoldMedalContest"></text>
        <text :x="x_Semifinals" y="20"  font-family="Arial" font-size="10"  font-weight="bold"   text-anchor="middle" alignment-baseline="central"   v-text="Semifinals"></text>
        <text :x="x_Quarterfinals" y="20"  font-family="Arial" font-size="10"  font-weight="bold"   text-anchor="middle" alignment-baseline="central"   v-text="Quarterfinals"></text>
      </svg>
      
    </div>
    <div class="flex-container">
      <!-- 主节点 -->

      <div class="flex-container" ref="printArea" id="print-content">
        <div v-for="n in levels" :key="'layer-' + n">
          <!-- 每层生成相应数量的MatchDiagramPage组件 -->
          <MatchDiagramPrint
            v-for="i in 2 ** (levels - n)"
            :key="'match-' + i + '-' + n"
            :height="
              calculateHeight(
                levels - n + 1,
                this.showDataList[2 ** (levels - n + 1) + (i - 1) * 2 - 1],
                this.showDataList[2 ** (levels - n + 1) + (i - 1) * 2],
                n,
                2 ** (levels - n + 1) + (i - 1)
              )
            "
            :height_up="this.height_up_List[2 ** (levels - n + 1) + (i - 1)]"
            :height_down="
              this.height_down_list[2 ** (levels - n + 1) + (i - 1)]
            "
            :usernameBlue="
              this.showDataList[2 ** (levels - n + 1) + (i - 1) * 2 - 1]
            "
            :levels="levels"
            :nowLevel="n"
            :usernameRed="
              this.showDataList[2 ** (levels - n + 1) + (i - 1) * 2]
            "
            :session="'1' + '/' + 2 ** (levels - n + 1) + ' A001'"
            :keyIndex="dataUserList[2 ** (levels - n) - 1 + (i - 1)].id"
            :showDataList="this.showDataList"
            :index="2 ** (levels - n + 1) + (i - 1) * 2"
            @username-clicked="handleUsernameClick($event)"
            @keyIndex-clicked="handleKeyIndexClick($event)"
            :style="{
              height:
                calculateHeight(
                  levels - n + 1,
                  this.showDataList[2 ** (levels - n + 1) + (i - 1) * 2 - 1],
                  this.showDataList[2 ** (levels - n + 1) + (i - 1) * 2],
                  n,
                  2 ** (levels - n + 1) + (i - 1)
                ) + 'px',
            }"
          >
          </MatchDiagramPrint>
        </div>
      </div>
    </div>
  </div>
  <el-dialog v-model="diaArrange" style="width: 200px">
    <el-autocomplete
      v-model="state1"
      :fetch-suggestions="querySearch"
      clearable
      class="inline-input w-50"
      placeholder="Please Input"
      @select="handleSelect"
    />
  </el-dialog>
</template>
    
  <script>
import axios from "axios";
import MatchDiagramPrint from "./MatchDiagramPrint.vue";
import html2canvas from "html2canvas";
export default {
  name: "VerticalButtonHierarchy",
  created() {
    this.arrange();
   
    console.log(this.levels);
  },
  data() {
    return {
      width:'',
      x_Quarterfinals:'',
      Quarterfinals:'Quarterfinals',
      x_GoldMedalContest:'',
      GoldMedalContest:'GoldMedalContest',
      x_Semifinals:'',
      Semifinals:'Semifinals',
      No:'No.',
      Bib:'Bib',
      name:'Name',
      levels: 0,
      height: 0,
      dataUserList: [],
      showDataList: [],
      selectedUsername: "",
      updateDatakeyIndex: "",
      diaArrange: false,
      height_up_List: [],
      height_down_list: [],
      countWheel: 0,
      height_list: [],
      matchWidth:270,
      fontSize:12,
      starndHeight:64
    };
  },
  components: {
    MatchDiagramPrint,
  },
  methods: {
    arrange() {
      var that = this;
      console.log(this.$route.query.comItemId);
      axios({
        url: this.$globalurl + "arrange/getByComItemId",
        method: "post",
        data: this.$route.query.comItemId,
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        var flag = Math.floor(response.data.主赛.length / 2);
        that.showDataList[0] = 0;
        var index = 1;
        that.dataUserList = response.data.主赛;
        for (var i = 0; i < response.data.主赛.length; i++) {
          if (
            i >= flag &&
            response.data.主赛[i].drawANum == null &&
            response.data.主赛[i].drawBNum == null
          ) {
            that.showDataList[index] = "none2";
            index = index + 1;
            that.showDataList[index] = "none2";
            index = index + 1;
            that.countWheel = that.countWheel + 1;
          } else {
            that.showDataList[index] =
              response.data.主赛[i].drawANum + "  " + response.data.主赛[i].groupAName;
            index = index + 1;
            that.showDataList[index] =
              response.data.主赛[i].drawBNum + "  " + response.data.主赛[i].groupBName;
            index = index + 1;
          }
        }
        var levels = 0;
        var len = response.data.主赛.length;
        console.log(response.data.主赛);
        while (len > 0) {
          len = Math.floor(len / 2);
          levels++;
        }
        if (levels>=4) {
            that.starndHeight=32
        }
        if (levels==2) {
          that.starndHeight=128
          that.x_GoldMedalContest = (levels-1)*410
          that.x_Semifinals = -360
          that.x_Quarterfinals=-460
        }
        if (levels==3) {
          that.x_GoldMedalContest = (levels-1)*310
          that.x_Semifinals = (levels-2)*360
          that.x_Quarterfinals=-460
        }else if(levels==4){
          that.x_GoldMedalContest = (levels-1)*210
          that.x_Semifinals = (levels-2)*210
          that.x_Quarterfinals=(levels-3)*250
        }else if(levels==5){
          that.x_GoldMedalContest = (levels-1)*158
          that.x_Semifinals = (levels-2)*155
          that.x_Quarterfinals=(levels-3)*155
        }
        that.width= levels*that.matchWidth
        that.levels = levels;
        that.height = 2 ** that.levels * that.starndHeight;

      });
    },
    convertToImage() {
      const printArea = this.$refs.printArea;
      html2canvas(printArea)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const encodedImgData = encodeURIComponent(imgData);
          // 使用查询参数对象传递 imageSrc
          this.$router.push({
            path: "/ToTemplateviewPage",
            query: {
              imageSrc: encodedImgData,
              comItemId:this.$route.query.comItemId ,
              levels:this.levels
            },
          });
        })
        .catch((error) => {
          console.error("html2canvas 失败:", error);
        });
    },
    // 根据层级计算每个MatchDiagramPage组件的高度
    calculateHeight(level, usernameBlue, usernameRed, n, index) {
      var height = this.height / 2 ** (level - 1);
      console.log("index " + n + "-" + index);
      if ((usernameBlue == "none2" || usernameRed == "none2") && n == 1) {
        height = this.starndHeight;
        (this.height_down_list[index] = height * 0.5),
          (this.height_up_List[index] = height * 0.5);
        this.height_list[index] = height;
      } else if (usernameBlue != "none2" && usernameRed != "none2" && n == 1) {
        (this.height_down_list[index] = height * 0.75),
          (this.height_up_List[index] = height * 0.25);
        this.height_list[index] = this.starndHeight*2;
      }
      if (n != 1) {
        height = this.height_list[index * 2] + this.height_list[index * 2 + 1];
        this.height_up_List[index] =
          this.height_up_List[index * 2] +
          (this.height_down_list[index * 2] - this.height_up_List[index * 2]) /
            2;
        this.height_down_list[index] =
          this.height_list[index * 2] +
          this.height_up_List[index * 2 + 1] +
          (this.height_down_list[index * 2 + 1] -
            this.height_up_List[index * 2 + 1]) /
            2;
        this.height_list[index] = height;
      }
      return height.toFixed(0);
    },
    SubmitSave() {
      // 向后后台发送数据
      console.log(this.showDataList[0]);
      this.showDataList[1] = "张三";
      this.showDataList[2] = "里斯";
    },
    handleUsernameClick(username) {
      this.selectedUsername = username;
    },
    handleKeyIndexClick(keyIndex) {
      this.updateDatakeyIndex = keyIndex;
      this.diaArrange = true;
    },
    changeData() {
      this.showDataList[parseInt(this.updateDatakeyIndex)] =
        this.selectedUsername;
    },
  },
};
</script>
    
    
  
  
    <style scoped>
html,
body {
  margin: 0;
  padding: 0;
}
.flex-container {
  margin-top: 10px;
  display: flex;
  /* 启用Flexbox布局 */
  align-items: flex-start;
  height: 100%;
  justify-content: flex-start; /* 默认值，子元素从容器的左边开始排列 */
}

.three-quarters-align {
  height: 25%;
  /* 容器高度为视口高度的3/4 */
}

.svg {
  margin: 0;
  padding: 0;
}
</style>