<template>
  <div class="background-container">
    <!-- 页面其他内容 -->
    <!-- <span style="color: white;">asa</span> -->
    <el-row style="height: 17%; text-align: center;" type="flex" justify="center" align="middle">
      <!--      左logo-->
      <el-col :span="6" :offset="0">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span style="color:white">logo</span>
        </div>
      </el-col>
      <!--      比赛-->
      <el-col :span="8">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span class="fontStyle">{{ CompetitionName }}比赛</span>
        </div>
      </el-col>
      <!--      右logo-->
      <el-col :span="6">
        <div :style="styleObject" class="grid-content bg-purple-dark">
          <span style="color:white">logo</span>
        </div>
      </el-col>
    </el-row>
    <el-row style="height: 39%; text-align: center;" type="flex" justify="center" align="middle">
      <!--    红方-->
      <!--      单位显示和人名显示-->
      <el-col :span="5" class="relative-container">
        <div :style="styleObject" class="bg-purple-dark unit-absolute-container">
          <span class="selfAdaptionFontStyle">{{ selectedRow.RedUnit  }}</span>
        </div>
        <div :style="styleObject" class="bg-purple-dark name-absolute-container">
          <span class="selfAdaptionFontStyle">{{ selectedRow.RedUsername }}</span>
        </div>
      </el-col>
      <!--      红方比分显示-->
      <el-col :span="4" class="relative-container">
        <div style="height:100% ;width: 100%;" class="viewTotalScore">
        <span class="fontStyleviewTotalScore">{{  RedTotalScore }}</span>
        </div>
      </el-col>
      <el-col :span="6">
      </el-col>
      <!--    蓝方-->
      <!--      蓝方比分显示-->
      <el-col :span="4" class="relative-container">
        <div style="height:100% ;width: 100%;" class="viewTotalScore">
          <span class="fontStyleviewTotalScore">{{  BlueTotalScore }}</span>
          <!-- <img v-bind:src="UpdateTheBlueScoreImagePath()"
            style="width: 80%;height: 80%;margin-top: 20%;margin-left: 20%;display: block"> -->
        </div>
      </el-col>
      <!--      蓝方信息显示-->
      <el-col :span="5" class="relative-container">
        <div :style="styleObject" class="bg-purple-dark unit-absolute-container">
          <span class="selfAdaptionFontStyle">{{ selectedRow.BlueUnit }}</span>
        </div>
        <div :style="styleObject" class="bg-purple-dark name-absolute-container">
          <span class="selfAdaptionFontStyle">{{ selectedRow.BlueUsername}}</span>
        </div>
      </el-col>
    </el-row>
    <el-row style="height: 37%; text-align: center;" type="flex" justify="center" align="middle">
      <!--    红方-->
      <!--    红方评分页面-->
      <el-col :span="7" class="relative-container">
        <el-row style="background-color: white; width: 98%; height: 74%;margin: 1%" class="relative-container">
          <el-col :span="6" style="width: 100%; height: 100%;">
            <!--    红方加1分-->
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"
                @click="RedAddOnePoint"><span class="ccxytFontStyle">+</span></button>
            </el-row>
            <el-row style="height: 20%; text-align: center;" type="flex" justify="center" align="middle">
              <span style="color: black; font-size: calc(100vw * 40 / 1920); font-family: 'ysbth';">1分</span>
            </el-row>
            <!--    红方减1分-->
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"
                @click="RedSubtractOnePoint"><span class="ccxytFontStyle">-</span></button>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: 100%; height: 100%;">
            <!--    红方加2分-->
            <el-row style="height: 38%;  text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"
                @click="RedAddTwoPoint"><span class="ccxytFontStyle">+</span></button>
            </el-row>
            <el-row style="height: 20%; text-align: center;" type="flex" justify="center" align="middle">
              <span style="color: black; font-size: calc(100vw * 40 / 1920); font-family: 'ysbth';">2分</span>
            </el-row>
            <!--    红方减2分-->
            <el-row style=" height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"
                @click="RedSubtractTwoPoint"><span class="ccxytFontStyle">-</span></button>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: 100%; height: 100%;">
            <!--    红方加3分-->
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"
                @click="RedAddTherePoint"><span class="ccxytFontStyle">+</span></button>
            </el-row>
            <el-row style="height: 20%; text-align: center;" type="flex" justify="center" align="middle">
              <span style="color: black; font-size: calc(100vw * 40 / 1920); font-family: 'ysbth';">3分</span>
            </el-row>
            <!--    红方减3分-->
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"
                @click="RedSubtractTherePoint"><span class="ccxytFontStyle">-</span></button>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: 100%; height: 100%;">
            <!--    红方警告-->
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"
              @click="RedAddWarning"><span class="ccxytFontStyle">+</span></button>
            </el-row>
            <el-row style="height: 20%; text-align: center;" type="flex" justify="center" align="middle">
              <span style="color: black; font-size: calc(100vw * 40 / 1920); font-family: 'ysbth';">警告</span>
            </el-row>
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"
              @click="RedSubtractWarning"><span class="ccxytFontStyle">-</span></button>
            </el-row>
          </el-col>
        </el-row>
        <!--    红方获胜-->
        <el-row style="width: 100%;height: 22%;text-align: center;" type="flex" justify="center" align="middle"
          class="relative-container">
          <el-col :span="24" style="width: 100%; height: 100%;">
            <el-row style="height: 100%; text-align: center;" type="flex" justify="center" align="middle">
              <button @click="RedWinOpen" style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"><span
                  class="fontStyle">win</span></button>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      <!--    红方功能按钮-->
      <el-col :span="2" class="relative-container">
        <el-row style="height: 19%;margin-left: 4%;margin-top: 2%;margin-right: 2%;" class="relative-container">
          <button @click="redTreatment" style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"><span
              class="twoFontStyle">就医</span></button>
        </el-row>
        <el-row style="height: 19%;margin-left: 4%;margin-top: 2%;margin-right: 2%;" class="relative-container">
          <button @click="redLate" style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"><span
              class="twoFontStyle">迟到</span></button>
        </el-row>
        <el-row style="height: 19%;margin-left: 4%;margin-top: 2%;margin-right: 2%;" class="relative-container">
          <button @click="redSuppress" style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"><span
              class="twoFontStyle">压制</span></button>
        </el-row>
        <el-row style="height: 19%;margin-left: 4%;margin-top: 2%;margin-right: 2%;" class="relative-container">
          <button style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"><span
              class="twoFontStyle">致痛</span></button>
        </el-row>
        <el-row style="height: 19%;margin-left: 4%;margin-top: 2%;margin-right: 2%;" class="relative-container">
          <button style="background-color: rgb(201, 9, 18);height: 100%;width: 100%;" class="awarding-button"><span
              class="fourFontStyle">录像审议</span></button>
        </el-row>
      </el-col>
      <!--    比赛信息显示-->
      <el-col :span="6" class="relative-container">
        <el-row style="height: 40%;" class="relative-container">
          <el-col :span="24" style="width: 100%; height: 100%;">
            <el-row style="height: 30%; text-align: center;" type="flex" justify="center" align="middle">
              <div :style="styleObject" class="bg-purple-dark massage-absolute-container"><span
                  class="MassageSelfAdaptionFontStyle">{{ selectedRow.Venue }}</span></div>
            </el-row>
            <el-row style="height: 30%; text-align: center;" type="flex" justify="center" align="middle">
              <div :style="styleObject" class="bg-purple-dark massage-absolute-container"><span
                  class="MassageSelfAdaptionFontStyle">{{ selectedRow.EventGroupName }}</span></div>
            </el-row>
            <el-row style="height: 30%; text-align: center;" type="flex" justify="center" align="middle">
              <div :style="styleObject" class="bg-purple-dark massage-absolute-container"><span
                  class="MassageSelfAdaptionFontStyle">{{ selectedRow.CompetitionSessions  }}</span></div>
            </el-row>
          </el-col>
        </el-row>
        <!--    比赛倒计时-->
        <el-row style="height: 40%;" class="relative-container">
          <div style="width: 40%; height: 100%;" class="awarding-button">
            <span class="fontStyleCompetitionTime">{{  minutes }}</span>
          </div>
          <div style="width: 20%; height: 100%;" class="awarding-button">
            <span class="fontStyleCompetitionTime">:</span>
          </div>
          <div style="width: 40%; height: 100%;" class="awarding-button">
            <span class="fontStyleCompetitionTime">{{ viewSeconds }}</span>
          </div>
        </el-row>
        <!--    比赛开始、暂停、重置按钮-->
        <el-row style="height: 20%;" class="relative-container">
          <el-col :span="6" style="width: 100%; height: 100%;">
            <button @click="startCountDown" style="background-color: rgb(148, 211, 58);height: 100%;width: 100%;"
              class="awarding-button"><span class="twoFontStyle">开始</span></button>
            <!-- <button @click="StartCompetition" style="background-color: rgb(148, 211, 58);height: 100%;width: 100%;" class="awarding-button" ><span class="twoFontStyle">开始</span></button> -->
          </el-col>
          <el-col :span="12" style="width: 100%; height: 100%;">
            <button @click="toggleCountdown" style="background-color: rgb(148, 211, 58);height: 100%;width: 100%;"
              class="awarding-button"><span class="fourFontStyle">暂停继续</span></button>
          </el-col>
          <el-col :span="6" style="width: 100%; height: 100%;">
            <button @click="resetCountdown" style="background-color: rgb(148, 211, 58);height: 100%;width: 100%;"
              class="awarding-button"><span class="twoFontStyle">重置</span></button>
          </el-col>
        </el-row>
      </el-col>
      <!--    蓝方-->
      <!--    蓝方功能按钮-->
      <el-col :span="2" class="relative-container">
        <el-row style="height: 19%;margin-left: 4%;margin-top: 2%;margin-right: 2%;" class="relative-container">
          <button @click="blueTreatment" style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;"
            class="awarding-button"><span class="twoFontStyle">就医</span></button>
        </el-row>
        <el-row style="height: 19%;margin-left: 4%;margin-top: 2%;margin-right: 2%;" class="relative-container">
          <button @click="blueLate" style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"><span
              class="twoFontStyle">迟到</span></button>
        </el-row>
        <el-row style="height: 19%;margin-left: 4%;margin-top: 2%;margin-right: 2%;" class="relative-container">
          <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"><span
              class="twoFontStyle">压制</span></button>
        </el-row>
        <el-row style="height: 19%;margin-left: 4%;margin-top: 2%;margin-right: 2%;" class="relative-container">
          <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"><span
              class="twoFontStyle">致痛</span></button>
        </el-row>
        <el-row style="height: 19%;margin-left: 4%;margin-top: 2%;margin-right: 2%;" class="relative-container">
          <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"><span
              class="fourFontStyle">录像审议</span></button>
        </el-row>
      </el-col>
      <!--    蓝方评分页面-->
      <el-col :span="7" class="relative-container">
        <el-row style="background-color: white; width: 98%; height: 74%;margin: 1%" class="relative-container">
          <el-col :span="6" style="width: 100%; height: 100%;">
            <!--    蓝方加1分-->
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"
                @click="BlueAddOnePoint"><span class="ccxytFontStyle">+</span></button>
            </el-row>
            <el-row style="height: 20%; text-align: center;" type="flex" justify="center" align="middle">
              <span style="color: black; font-size: calc(100vw * 40 / 1920); font-family: 'ysbth';">1分</span>
            </el-row>
            <!--    蓝方减1分-->
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"
                @click="BlueSubtractOnePoint"><span class="ccxytFontStyle">-</span></button>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: 100%; height: 100%;">
            <!--    蓝方加2分-->
            <el-row style="height: 38%;  text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"
                @click="BlueAddTwoPoint"><span class="ccxytFontStyle">+</span></button>
            </el-row>
            <el-row style="height: 20%; text-align: center;" type="flex" justify="center" align="middle">
              <span style="color: black; font-size: calc(100vw * 40 / 1920); font-family: 'ysbth';">2分</span>
            </el-row>
            <!--    蓝方减2分-->
            <el-row style=" height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"
                @click="BlueSubtractTwoPoint"><span class="ccxytFontStyle">-</span></button>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: 100%; height: 100%;">
            <!--    蓝方加3分-->
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"
                @click="BlueAddTherePoint"><span class="ccxytFontStyle">+</span></button>
            </el-row>
            <el-row style="height: 20%; text-align: center;" type="flex" justify="center" align="middle">
              <span style="color: black; font-size: calc(100vw * 40 / 1920); font-family: 'ysbth';">3分</span>
            </el-row>
            <!--    蓝方减3分-->
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"
                @click="BlueSubtractTherePoint"><span class="ccxytFontStyle">-</span></button>
            </el-row>
          </el-col>
          <el-col :span="6" style="width: 100%; height: 100%;">
            <!--    蓝方警告-->
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"
              @click="BlueAddWarning"><span class="ccxytFontStyle">+</span></button>
            </el-row>
            <el-row style="height: 20%; text-align: center;" type="flex" justify="center" align="middle">
              <span style="color: black; font-size: calc(100vw * 40 / 1920); font-family: 'ysbth';">警告</span>
            </el-row>
            <el-row style="height: 38%; text-align: center;margin: 2%;" type="flex" justify="center" align="middle">
              <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"
              @click="BlueSubtractWarning"><span class="ccxytFontStyle">-</span></button>
            </el-row>
          </el-col>
        </el-row>
        <!--    蓝方获胜-->
        <el-row style="width: 100%;height: 22%;text-align: center;" type="flex" justify="center" align="middle"
          class="relative-container">
          <el-col :span="24" style="width: 100%; height: 100%;">
            <el-row style="height: 100%; text-align: center;" type="flex" justify="center" align="middle">
              <!--              蓝方胜利-->
              <button style="background-color: rgb(33, 63, 244);height: 100%;width: 100%;" class="awarding-button"
                      @click="BlueWinOpen"><span
                  class="fontStyle">win</span></button>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!--    底栏功能按钮-->
    <el-row style="width: 100%;height: 7%;" class="relative-container">
      <el-col :span="4" style="width: 100%; height: 100%;" class="relative-container">
        <button style="background-color: white;height: 100%;width: 100%;" class="awarding-button"><span
            class="bottomFontStyle">录像审议员</span></button>
      </el-col>
      <el-col :span="3" style="width: 100%; height: 100%;">
        <button style="background-color:white;height: 100%;width: 100%;" class="awarding-button"><span
            class="bottomFontStyle">裁判员</span></button>
      </el-col>
      <el-col :span="3" style="width: 100%; height: 100%;">
        <button style="background-color: white;height: 100%;width: 100%;" class="awarding-button"
          @click="CompetitionSetting = true"><span class="bottomFontStyle">比赛设置</span></button>
      </el-col>

      <el-col :span="4" style="width: 100%; height: 100%;">
        <button style="background-color: white;height: 100%;width: 100%;" class="awarding-button"><span
            class="bottomFontStyle">比赛日志</span></button>
      </el-col>
      <el-col :span="3" style="width: 100%; height: 100%;">
        <button style="background-color: white;height: 100%;width: 100%;" class="awarding-button"><span
            class="bottomFontStyle">临场成绩单</span></button>
      </el-col>
      <el-col :span="3" style="width: 100%; height: 100%;">
        <button style="background-color: white;height: 100%;width: 100%;" class="awarding-button"><span
            class="bottomFontStyle">成绩修改</span></button>
      </el-col>
      <el-col :span="4" style="width: 100%; height: 100%;">
        <button style="background-color: white;height: 100%;width: 100%;" class="awarding-button"
          @click="UploadFilesFormVisible = true"><span class="bottomFontStyle">新增比赛</span></button>
      </el-col>
    </el-row>
    <!-- 添加比赛页面 -->
    <div v-if="UploadFilesFormVisible" class="modal">
      <div class="modal-content">
        <el-row style="margin-bottom: 20px">
          <el-col :span="10">
            <div>
              <input type="file" accept=".xlsx" @change="UploadFilesMethod" />
            </div>
          </el-col>
          <el-col :span="2" :offset="12">
            <el-button @click="UploadFilesFormVisible = false" type="danger">取消添加</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-table ref="filterTable" :data="tableDataCompetitionInformation" :highlight-current-row="true"
            @row-click="handleRowClick" max-height="600" style="width: 100%">
            <el-table-column prop="Venue" label="比赛场次" width="180">
            </el-table-column>
            <el-table-column prop="RedUsername" label="红方姓名" width="180">
            </el-table-column>
            <el-table-column prop="RedUnit" label="红方单位" width="180">
            </el-table-column>
            <el-table-column prop="BlueUsername" label="蓝方姓名" width="180">
            </el-table-column>
            <el-table-column prop="BlueUnit" label="蓝方单位" width="180">
            </el-table-column>
            <el-table-column prop="EventGroupName" label="比赛类别" width="180">
            </el-table-column>
            <el-table-column prop="CompetitionSessions" label="场次" width="80">
            </el-table-column>
            <el-table-column prop="address" label="是否比赛" :formatter="formatter">
            </el-table-column>
            <el-table-column prop="tag" label="胜者" :filters="[{ text: '蓝方', value: 0 }, { text: '红方', value: 1 }]"
              :filter-method="filterTag" filter-placement="bottom-end">
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :span="2" :offset="22">
            <el-button @click="ConfirmAddingCompetition" type="primary">确定添加</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 比赛设置页面 -->
    <div v-if="CompetitionSetting" class="modal">
      <div class="modal-content" style="width: 50%;height: 50%;">
        <el-row style="width: 100%;height: 85%;" class="relative-container">
          <el-col :span="10" style="height: 100%" class="relative-container">
            <el-row style="width: 100%;height: 20%;">
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><span
                  style="width: 100%;" class="CSFontStyle">比赛时间:</span></el-col>
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><input
                  style="width: 100%;height: 30%;" ref="CompetitionTime" placeholder="以秒为单位" /></el-col>
            </el-row>
            <el-row style="width: 100%;height: 20%;">
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><span
                  style="width: 100%;" class="CSFontStyle">局间休息:</span></el-col>
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><input
                  style="width: 100%;height: 30%;" placeholder="以秒为单位" /></el-col>
            </el-row>
            <el-row style="width: 100%;height: 20%;">
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><span
                  style="width: 100%;" class="CSFontStyle">倒计时:</span></el-col>
              <el-col :span="12"
                style="height: 100%;display:flex; justify-content: center; align-items: center;"><input
                  style="width: 100%;height: 30%;" placeholder="以秒为单位" /></el-col>
            </el-row>
            <el-row style="width: 100%;height: 20%;">
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><span
                  style="width: 100%;" class="CSFontStyle">分差值:</span></el-col>
              <el-col :span="12"
                style="height: 100%;display:flex; justify-content: center; align-items: center;"><input
                  style="width: 100%;height: 30%;" placeholder="" /></el-col>
            </el-row>
            <el-row style="width: 100%;height: 20%;">
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><span
                  style="width: 100%;" class="CSFontStyle">警告次数:</span></el-col>
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><input
                  style="width: 100%;height: 30%;"  ref="WarningCount" placeholder="" /></el-col>
            </el-row>
          </el-col>
          <el-col :span="4" style="height: 100%" class="relative-container"></el-col>
          <el-col :span="10" style="height: 100%" class="relative-container">
            <el-row style="width: 100%;height: 20%;">
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><span
                  style="width: 100%;" class="CSFontStyle">人工提醒：</span></el-col>
              <el-col :span="12"
                style="height: 100%;display:flex; justify-content: center; align-items: center;"><el-switch
                  v-model="value" active-color="#13ce66" inactive-color="#ff4949"></el-switch></el-col>
            </el-row>
            <el-row style="width: 100%;height: 20%;">
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><span
                  style="width: 100%;" class="CSFontStyle">分差提醒：</span></el-col>
              <el-col :span="12"
                style="height: 100%;display:flex; justify-content: center; align-items: center;"><el-switch
                  v-model="value" active-color="#13ce66" inactive-color="#ff4949"></el-switch></el-col>
            </el-row>
            <el-row style="width: 100%;height: 20%;">
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><span
                  style="width: 100%;" class="CSFontStyle">警告满：</span></el-col>
              <el-col :span="12"
                style="height: 100%;display:flex; justify-content: center; align-items: center;"><el-switch
                  v-model="value" active-color="#13ce66" inactive-color="#ff4949"></el-switch></el-col>
            </el-row>
            <el-row style="width: 100%;height: 20%;">
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><span
                  style="width: 100%;" class="CSFontStyle">比分获胜：</span></el-col>
              <el-col :span="12"
                style="height: 100%;display:flex; justify-content: center; align-items: center;"><el-switch
                  v-model="value" active-color="#13ce66" inactive-color="#ff4949"></el-switch></el-col>
            </el-row>
            <el-row style="width: 100%;height: 20%;">
              <el-col :span="12" style="height: 100%;display:flex; justify-content: center; align-items: center;"><span
                  style="width: 100%;" class="CSFontStyle">先取：</span></el-col>
              <el-col :span="12"
                style="height: 100%;display:flex; justify-content: center; align-items: center;"><el-switch
                  v-model="value" active-color="#13ce66" inactive-color="#ff4949"></el-switch></el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row style="width: 100%;height: 5%;" class="relative-container"></el-row>
        <el-row style="width: 100%;height: 10%;" class="relative-container">
          <el-col :span="4" style="height: 100%;display:flex; justify-content: center; align-items: center;">
            <button style="background-color: white;height: 80%;width: 100%;" class="awarding-button"
              @click="CompetitionSetting = false"><span class="CSFontStyle">关闭</span></button>
          </el-col>
          <el-col :span="16"></el-col>
          <el-col :span="4" style="height: 100%;display:flex; justify-content: center; align-items: center;">
            <button style="background-color: white;height: 80%;width: 100%;" class="awarding-button"
              @click="ConfirmSetting"><span class="CSFontStyle">确认</span></button>
          </el-col>
        </el-row>
        <!-- 模态框内容 -->
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>
<script>
import * as XLSX from 'xlsx';
export default {
  data() {
    return {
      RedSuppressTime: 0,//红方压制时间
      RedSuppressState: false,//红方压制状态
      WarningCount: 0,//警告次数
      RedWarningNum: 0, // 红方警告次数
      BlueWarningNum: 0, // 蓝方警告次数
      RedLateState: false,//红方迟到状态
      RedLateTime: 0,//红方迟到时间
      BlueLateState: false,//蓝方迟到状态
      BlueLateTime: 0,//蓝方迟到时间
      RedtreatmentTime: 0,//红方治疗时间
      RedtreatmentState: false,//红方治疗状态
      BluetreatmentTime: 0,//蓝方治疗时间
      BluetreatmentState: false,//蓝方治疗状态
      ChangeCPtimeState: true,//判断比赛时间是否改变
      tableDataCompetitionInformation: [],//添加比赛表单
      selectedRow: [],//比赛表单行数据
      CountdownState: false,// 控制倒计时运行状态
      timerId: null,// 定时器ID，用于清除定时器
      timerRedTreatId: null,// 红方就医定时器ID，用于清除定时器
      timerBlueTreatId: null,// 蓝方就医定时器ID，用于清除定时器
      timerRedLateId: null,// 红方迟到定时器ID，用于清除定时器
      timerBlueLateId: null,// 蓝方迟到定时器ID，用于清除定时器
      timerRedSuppressId: null,//红方压制定时器ID,用于清除定时器
      timerBlueSuppressId: null,//蓝方压制定时器ID,用于清除定时器
      viewSeconds: '0'+ 0,//页面秒数显示
      SettingMinutes: 0,//设置分钟
      SettingSeconds: 0,//设置秒数
      minutes: 0,
      seconds: 0,
      CompetitionTime: 0,//设置比赛时间
      value: true,
      input: '',
      fileList: [],//导入文件
      UploadFilesFormVisible: false,//显示添加比赛
      CompetitionSetting: false,//显示比赛设置界面
      radio: '1',
      CompetitionName: '柔道',
      RedCompetingUnit: '上海鬼手道场',
      RedAthleteName: '张三',
      BlueCompetingUnit: '上海黑手道场彭浦新村',
      BlueAthleteName: '李四',
      GroupNumber: 'A1000',
      CompetitionLevel: '男子甲组79kg',
      MatchSession: '1/16比赛中',
      RedTotalScore: 0,
      BlueTotalScore: 0
    }
  },
  // computed 是一种特殊的依赖项属性，它根据组件中其他数据的变化自动重新计算值。
  mounted() {
    // this.setFontSize()
    // this.massageSetFontSize()
    this.UpdateTheRedScoreImagePath()
    this.UpdateTheBlueScoreImagePath()
  },
  watch: {
    ChangeCPtimeState() {
      localStorage.setItem('minutes', this.minutes)
      localStorage.setItem('seconds', this.seconds)
    },
    // minutes(newValue, oldValue) {
    //   console.log(`Minutes changed from ${oldValue} to ${newValue}`);
    //   localStorage.setItem('minutes', newValue)
    // },
    // seconds(newValue, oldValue) {
    //   console.log(`Seconds changed from ${oldValue} to ${newValue}`);
    //   localStorage.setItem('seconds', newValue)
    //   }
  },
  unmounted() {
    // 组件销毁前清除定时器
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  },
  methods: {
    test: function (e) {
      console.log(e)
    },
    //红方压制
    redSuppress() {
      if(this.RedSuppressState) {
        this.RedSuppressState = !this.RedSuppressState
        localStorage.setItem('RedSuppressState', 0);
        this.redSuppressTimePause()
      }
      else {
        this.RedSuppressState = !this.RedSuppressState
        localStorage.setItem('RedSuppressState', 1);
        this.redSuppressTime()
      }
    },
    //红方压制倒计时
    redSuppressTime() {

      this.timerRedSuppressId = setInterval(() => {
      this.RedSuppressTime ++;
      localStorage.setItem('RedSuppressTime', this.RedSuppressTime);
      console.log(this.RedSuppressTime)
    }, 1000);
    },
    //红方压制倒计时暂停
    redSuppressTimePause() {
      if(this.RedSuppressTime > 3){
        this.RedTotalScore++
        localStorage.setItem('RedTotalScore', this.RedTotalScore)
      }
      this.RedSuppressTime = 0
      localStorage.setItem('RedSuppressTime', this.RedSuppressTime);
      if (this.timerRedSuppressId) {
        clearInterval(this.timerRedSuppressId);
        this.timerRedSuppressId = null;
      }
    },
    //红方迟到
    redLate() {
      if(this.RedLateState) {
        this.RedLateState = !this.RedLateState
        localStorage.setItem('RedLateState', 0);
        this.redLateTimePause()
      }
      else {
        this.RedLateState = !this.RedLateState
        localStorage.setItem('RedLateState', 1);
        this.pauseCountDown()
        this.redLateTime()
      }
    },
    //红方迟到倒计时
    redLateTime() {
      this.timerRedLateId = setInterval(() => {
      this.RedLateTime ++;

      localStorage.setItem('RedLateTime', this.RedLateTime);
      console.log(this.RedLateTime)
    }, 1000);
    },
    //红方迟到倒计时暂停
    redLateTimePause() {
      if (this.timerRedLateId) {
        clearInterval(this.timerRedLateId);
        this.timerRedLateId = null;
      }
    },
    //蓝方迟到
    blueLate() {
      if(this.BlueLateState) {
      this.BlueLateState = !this.BlueLateState
      localStorage.setItem('BlueLateState', 0);
      this.blueLateTimePause()
      }
      else {
      this.BlueLateState = !this.BlueLateState
      localStorage.setItem('BlueLateState', 1);
      this.pauseCountDown()
      this.blueLateTime()
      }
    },
    //蓝方迟到倒计时
    blueLateTime() {
      this.timerBlueLateId = setInterval(() => {
      this.BlueLateTime ++;
      localStorage.setItem('BlueLateTime', this.BlueLateTime);
    }, 1000);
    },
    //蓝方迟到倒计时暂停
    blueLateTimePause() {
      if (this.timerBlueLateId) {
        clearInterval(this.timerBlueLateId);
        this.timerBlueLateId = null;
      }
    },
    //红方就医
    redTreatment() {
      // if(!this.RedtreatmentTimeState){
      //   this.timerId = setInterval(() => {
      //     this.RedtreatmentTime++
      // }, 1000);
      // }
      if(this.RedtreatmentState) {
        this.RedtreatmentState = !this.RedtreatmentState
        localStorage.setItem('RedtreatmentState', 0);
        this.redCalculateTimePause()
      }
      else {
        this.RedtreatmentState = !this.RedtreatmentState
        localStorage.setItem('RedtreatmentState', 1);
        this.pauseCountDown()
        this.redCalculateTime()
      }
    },
    //蓝方就医
    blueTreatment(){
      if(this.BluetreatmentState) {
        console.log('0' + this.BluetreatmentState)
        this.BluetreatmentState = !this.BluetreatmentState
        localStorage.setItem('BluetreatmentState', 0);
        this.blueCalculateTimePause()
      }
      else {
        console.log(this.BluetreatmentState)
        this.BluetreatmentState = !this.BluetreatmentState
        localStorage.setItem('BluetreatmentState', 1);
        this.pauseCountDown()
        this.blueCalculateTime()
      }
    },
    //红方就医倒计时
    redCalculateTime() {
      this.timerRedTreatId = setInterval(() => {
      this.RedtreatmentTime ++;
      localStorage.setItem('RedtreatmentTime', this.RedtreatmentTime);
    }, 1000);
    },
    //蓝方就医倒计时
    blueCalculateTime() {
      this.timerBlueTreatId = setInterval(() => {
      this.BluetreatmentTime++;
      localStorage.setItem('BluetreatmentTime', this.BluetreatmentTime);
      console.log(this.BluetreatmentTime)
    }, 1000);
    },
    //红方就医倒计时暂停
    redCalculateTimePause() {
      if (this.timerRedTreatId) {
        clearInterval(this.timerRedTreatId);
        this.timerRedTreatId = null;
      }
    },
    //蓝方就医倒计时暂停
    blueCalculateTimePause() {
      if (this.timerBlueTreatId) {
        clearInterval(this.timerBlueTreatId);
        this.timerBlueTreatId = null;
      }
    },
    // 添加比赛方法
    resetDateFilter() {
      this.$refs.filterTable.clearFilter('date');
    },
    clearFilter() {
      this.$refs.filterTable.clearFilter();
    },
    formatter(row) {
      return row.address;
    },
    filterTag(value, row) {
      return row.tag === value;
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    handleRowClick(row) {
      this.selectedRow = row
    },
    ConfirmAddingCompetition() {
      this.UploadFilesFormVisible = false
      localStorage.setItem('Venue', this.selectedRow.Venue)
      localStorage.setItem('RedUsername', this.selectedRow.RedUsername)
      localStorage.setItem('RedUnit', this.selectedRow.RedUnit)
      localStorage.setItem('BlueUsername', this.selectedRow.BlueUsername)
      localStorage.setItem('BlueUnit', this.selectedRow.BlueUnit)
      localStorage.setItem('EventGroupName', this.selectedRow.EventGroupName)
      localStorage.setItem('CompetitionSessions', this.selectedRow.CompetitionSessions)
      localStorage.setItem('RedTotalScore', 0)
      localStorage.setItem('BlueTotalScore', 0)
      localStorage.setItem('RedtreatmentTime', 0)
      localStorage.setItem('BluetreatmentTime', 0)
      localStorage.setItem('RedLateTime', 0)
      localStorage.setItem('BlueLateTime', 0)
      localStorage.setItem('WarningCount', this.WarningCount)
      localStorage.setItem('RedWarningNum', 0)
      localStorage.setItem('BlueWarningNum', 0)
      this.RedWarningNum = 0
      this.BlueWarningNum = 0
      this.RedTotalScore = 0
      this.BlueTotalScore =0
      this.RedtreatmentTime =0
      this.BluetreatmentTime =0
      this.RedLateTime =0
      this.BlueLateTime =0
      this.fontSizeInitialize()
      this.resetCountdown()
      this.setFontSize ()
      this.massageSetFontSize()
      // 表示胜利页面需要跳转到新的页面
      localStorage.setItem('NextCompetition', 1)
      //0蓝方 1红方 -1还未裁决
      localStorage.setItem('VictorySide', -1)
      this.$message({
        type: 'success',
        message: '添加成功'
      });
    },
    //判定比赛为蓝色赢
    BlueWinOpen() {
      this.$confirm('是否判定蓝方胜利', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '蓝方胜利!'
        });
        //0表示蓝方胜利 重置分数
        localStorage.setItem('VictorySide', 0)
        localStorage.setItem('WinUsername', localStorage.getItem('BlueUsername'))
        localStorage.setItem('WinUnit', localStorage.getItem('BlueUnit'))
        this.pauseCountDown()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        });
      })
    },
    RedWinOpen() {
      this.$confirm('是否判定红方胜利', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '红方胜利!'
        });
        //0表示蓝方胜利 重置分数
        localStorage.setItem('VictorySide', 1)
        localStorage.setItem('WinUsername', localStorage.getItem('RedUsername'))
        localStorage.setItem('WinUnit', localStorage.getItem('RedUnit'))
        this.pauseCountDown()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        });
      })
    },
    // 读取文件方法
    UploadFilesMethod(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          this.readExcelFile(data);
        };
        reader.readAsArrayBuffer(file);
      }
    },
    readExcelFile(data) {
      const workbook = XLSX.read(data, {type: 'array'});
      const firstSheetName = workbook.SheetNames[0]; // 获取第一个工作表的名称
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});

      // jsonData 是一个二维数组，其中第一行是列名
      // 你可以在这里进一步处理jsonData，例如获取每列信息
      // 除去第一行
      var listData = jsonData.slice(1)
      listData.forEach((items, index) => {
        var CompetitionInformation = [];
        CompetitionInformation['Venue'] = items[0];
        CompetitionInformation['RedUsername'] = items[2];
        CompetitionInformation['RedUnit'] = items[3];
        CompetitionInformation['BlueUsername'] = items[4];
        CompetitionInformation['BlueUnit'] = items[5];
        CompetitionInformation['EventGroupName'] = items[6];
        var flag = 1
        while (flag < jsonData.length) {
          flag = flag * 2
        }
        CompetitionInformation['CompetitionSessions'] = '1' + '/' + flag;
        this.tableDataCompetitionInformation[index] = CompetitionInformation
      })
    },
    // 倒计时计算
    startCountDown() {
      if(this.CountdownState){
        return ; 
      }
      this.CountdownState = true;
      this.timerId = setInterval(() => {
        if (this.seconds == 0) {
          if (this.minutes == 0) {
            this.pauseCountDown();
            alert('比赛结束');
            return 0;
          } else {
            this.minutes--;
            this.seconds = 59;
          }
        } else {
          this.seconds--;
        }
        if (this.seconds < 10) {
          this.viewSeconds = '0' + this.seconds
        }
        else {
          this.viewSeconds = this.seconds
        }
        this.ChangeCPtimeState = !this.ChangeCPtimeState
      }, 1000);
    },
    toggleCountdown() {//切换倒计时状态
      if (this.CountdownState) {
        this.pauseCountDown();
      } else {
        this.startCountDown();
      }
    },
    pauseCountDown() {
      if(!this.CountdownState){
        return ; 
      }
      this.CountdownState = false;
      if (this.timerId) {
        clearInterval(this.timerId);
        this.timerId = null;
      }
    },
    resetCountdown() {
      this.pauseCountDown();
      this.minutes = this.SettingMinutes;
      this.seconds = this.SettingSeconds;
      if (this.SettingSeconds < 10) {
        this.viewSeconds = '0' + this.SettingSeconds;
      }
      else {
        this.viewSeconds = this.SettingSeconds;
      }
      this.ChangeCPtimeState = !this.ChangeCPtimeState
    },
    ConfirmSetting() {
      this.WarningCount = this.$refs.WarningCount.value;
      if(this.WarningCount < 0){
        this.$message({
          type: 'error',
          message: '警告次数设置失败！不能设置为负数!'
        });
      return ;  
      }
      this.CompetitionTime = this.$refs.CompetitionTime.value;
      if(this.CompetitionTime < 0){
        this.$message({
          type: 'error',
          message: '比赛时间设置失败！不能设置为负数!'
        });
      return ;  
      }
      this.SettingMinutes = Math.floor(this.CompetitionTime / 60)
      this.minutes = Math.floor(this.CompetitionTime / 60)
      if (this.CompetitionTime % 60 < 10) {
        this.viewSeconds = '0' + this.CompetitionTime % 60
      }
      else {
        this.viewSeconds = this.CompetitionTime % 60
      }
      this.SettingSeconds = this.CompetitionTime % 60
      this.seconds = this.CompetitionTime % 60
      localStorage.setItem('WarningCount', this.WarningCount)
      this.ChangeCPtimeState = !this.ChangeCPtimeState
      this.CompetitionSetting = false
      this.$message({
          type: 'success',
          message: '设置成功!'
        });
    },
    fontSizeInitialize () {
      const textEls = document.querySelectorAll('.selfAdaptionFontStyle');
      textEls.forEach((el) => {
          el.style.fontSize = `80px` // 应用新的字体大小
      });
    },
    setFontSize () {
      const textEls = document.querySelectorAll('.selfAdaptionFontStyle');
      textEls.forEach((el) => {
        let fontSize = 80 // 以一个相对较大的字体大小开始
        while (el.scrollWidth > el.clientWidth && fontSize > 1) {
          fontSize-- // 尝试减小字体大小
          el.style.fontSize = `${fontSize}px` // 应用新的字体大小
        }
        console.log(fontSize)
      });
    },
    massageSetFontSize () {
      const textEls = document.querySelectorAll('.MassageSelfAdaptionFontStyle');
      textEls.forEach((el) => {
        let fontSize = 50 // 以一个相对较大的字体大小开始
        while (el.scrollWidth > el.clientWidth && fontSize > 1) {
          fontSize-- // 尝试减小字体大小
          el.style.fontSize = `${fontSize}px` // 应用新的字体大小
        }
      });
    }, 
    handleButtonClick() {
      // 按钮点击事件的处理函数
      console.log('按钮被点击了')
      // 可以在这里添加更多的逻辑
    },
    //红方加一次警告
    RedAddWarning() {
      if(this.RedWarningNum + 1 > this.WarningCount){
        this.RedWarningNum = this.WarningCount
      } else {
        this.RedWarningNum +=1
      }
      localStorage.setItem('RedWarningNum', this.RedWarningNum)
    },
    //蓝方加一次警告
    BlueAddWarning() {
      if(this.BlueWarningNum + 1 > this.WarningCount){
        this.BlueWarningNum = this.WarningCount
      } else {
        this.BlueWarningNum +=1
      }
      localStorage.setItem('BlueWarningNum', this.BlueWarningNum)
    },
    //红方减一次警告
    RedSubtractWarning() {
      if(this.RedWarningNum -1 < 0) {
        this.RedWarningNum =0
      } else {
        this.RedWarningNum -=1
      }
      localStorage.setItem('RedWarningNum', this.RedWarningNum)
    },
    //红方减一次警告
    BlueSubtractWarning() {
      if(this.BlueWarningNum -1 < 0) {
        this.BlueWarningNum =0
      } else {
        this.BlueWarningNum -=1
      }
      localStorage.setItem('BlueWarningNum', this.BlueWarningNum)
    },
    // 红加一分
    RedAddOnePoint() {
      // if (this.RedTotalScore + 1 > 9) {
      //   this.RedTotalScore = 9
      // } else {
        this.RedTotalScore += 1
      // }
      localStorage.setItem('RedTotalScore', this.RedTotalScore)
    },
    BlueAddOnePoint() {
      this.BlueTotalScore += 1
      localStorage.setItem('BlueTotalScore', this.BlueTotalScore)
    },
    RedSubtractOnePoint() {
      if (this.RedTotalScore - 1 < 0) {
        this.RedTotalScore = 0
      } else {
        this.RedTotalScore -= 1
      }
      localStorage.setItem('RedTotalScore', this.RedTotalScore)
    },
    BlueSubtractOnePoint() {
      if (this.BlueTotalScore - 1 < 0) {
        this.BlueTotalScore = 0
      } else {
        this.BlueTotalScore -= 1
      }
      localStorage.setItem('BlueTotalScore', this.BlueTotalScore)
    },
    RedAddTwoPoint() {
      // if (this.RedTotalScore + 2 > 9) {
      //   this.RedTotalScore = 9
      // } else {
        this.RedTotalScore += 2
      // }
      localStorage.setItem('RedTotalScore', this.RedTotalScore)
    },
    BlueAddTwoPoint() {
      // if (this.BlueTotalScore + 2 > 9) {
      //   this.BlueTotalScore = 9
      // } else {
        this.BlueTotalScore += 2
      // }
      localStorage.setItem('BlueTotalScore', this.BlueTotalScore)
    },
    RedSubtractTwoPoint() {
      if (this.RedTotalScore - 2 < 0) {
        this.RedTotalScore = 0
      } else {
        this.RedTotalScore -= 2
      }
      localStorage.setItem('RedTotalScore', this.RedTotalScore)
    },
    BlueSubtractTwoPoint() {
      if (this.BlueTotalScore - 2 < 0) {
        this.BlueTotalScore = 0
      } else {
        this.BlueTotalScore -= 2
      }
      localStorage.setItem('BlueTotalScore', this.BlueTotalScore)
    },
    RedAddTherePoint() {
      // if (this.RedTotalScore + 3 > 9) {
      //   this.RedTotalScore = 9
      // } else {
        this.RedTotalScore += 3
      // }
      localStorage.setItem('RedTotalScore', this.RedTotalScore)
    },
    BlueAddTherePoint() {
      // if (this.BlueTotalScore + 3 > 9) {
      //   this.BlueTotalScore = 9
      // } else {
        this.BlueTotalScore += 3
      // }
      localStorage.setItem('BlueTotalScore', this.BlueTotalScore)
    },
    RedSubtractTherePoint() {
      if (this.RedTotalScore - 3 < 0) {
        this.RedTotalScore = 0
      } else {
        this.RedTotalScore -= 3
      }
      localStorage.setItem('RedTotalScore', this.RedTotalScore)
    },
    BlueSubtractTherePoint() {
      if (this.BlueTotalScore - 3 < 0) {
        this.BlueTotalScore = 0
      } else {
        this.BlueTotalScore -= 3
      }
      localStorage.setItem('BlueTotalScore', this.BlueTotalScore)
    },
    UpdateTheRedScoreImagePath() {
      return require('../assets/img/300ppi/' + this.RedTotalScore + '.png')
    },
    UpdateTheBlueScoreImagePath() {
      return require('../assets/img/300ppi/' + this.BlueTotalScore + '.png')
    },
    StartCompetition() {
      // 初始化数据
      this.RedTotalScore = 0
      this.BlueTotalScore = 0
      window.open('/ToCompetitionPage')
      localStorage.setItem('RedTotalScore', 0)
      localStorage.setItem('BlueTotalScore', 0)
      localStorage.setItem('minutes', 0)
      localStorage.setItem('seconds', 0)
    }
  }
}
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
}

.awarding-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  /* 防止文本换行 */
  /* 如果需要，添加其他样式，如字体大小、颜色等 */
}

.background-container {
  background-image: url('../assets/img/打分背景图.png');
  /* Vue CLI 的 @ 符号是 src 的别名 */
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%
}

.fontStyle {
  color: white;
  font-size: calc(100vw * 80 / 1920);
  font-family: 'ysbth';
}

.twoFontStyle {
  color: white;
  font-size: calc(100vw * 50 / 1920);
  font-family: 'ysbth';
}

.threeFontStyle {
  color: white;
  font-size: calc(100vw * 40 / 1920);
  font-family: 'ysbth';
}

.fourFontStyle {
  color: white;
  font-size: calc(100vw * 30 / 1920);
  font-family: 'ysbth';
}

.bottomFontStyle {
  color: black;
  font-size: calc(100vw * 50 / 1920);
  font-family: 'ysbth';
}

.CSFontStyle {
  color: black;
  font-size: calc(100vw * 40 / 1920);
  font-family: 'ysbth';
}

.ccxytFontStyle {
  color: white;
  margin-bottom: 15%;
  font-size: calc(100vw * 80 / 1920);
  font-family: 'ccxyt';
}

.fontStyleCompetitionTime {
  color: rgb(255, 255, 11);
  font-size: calc(100vw * 80 / 1920);
  font-family: 'ysbth';
}
.fontStyleviewTotalScore{
  color: white;
  font-weight :800;
  font-size: calc(100vw * 80 / 1920 * 3);
  font-family: 'ccxyt';
}
.selfAdaptionFontStyle {
  color: white;
  font-family: 'ysbth';
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  /* 设置一个合理的字体大小 */
  font-size: calc(100vw * 80 / 1920);
  /* 设置最大宽度，根据实际情况调整 */
  max-width: 80%;
}

.MassageSelfAdaptionFontStyle {
  color: white;
  font-family: 'ysbth';
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* 设置一个合理的字体大小 */
  font-size: calc(100vw * 50 / 1920);
  /* 设置最大宽度，根据实际情况调整 */
  max-width: 80%;
}

.relative-container {
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  /* 确保父容器有高度 */
}

.unit-absolute-container {
  /* 单位div显示位置 */
  position: absolute;
  top: 10%;
  /* 定位到容器顶部 */
  display: flex;
  /* 使用 Flexbox 布局 */
  justify-content: center;
  /* 子元素水平居中 */
  align-items: center;
  /* 子元素垂直居中 */
  left: 0;
  width: 100%;
  /* 宽度占满整个容器 */
  height: 20%;
  /*  高度占容器的20% */
}

.name-absolute-container {
  /* 名字div显示位置 */
  position: absolute;
  top: 30%;
  /* 定位到容器顶部 */
  display: flex;
  /* 使用 Flexbox 布局 */
  justify-content: center;
  /* 子元素水平居中 */
  align-items: center;
  /* 子元素垂直居中 */
  left: 0;
  width: 100%;
  /* 宽度占满整个容器 */
  height: 20%;
  /* 高度占容器的20% */
}

.massage-absolute-container {
  /* 名字div显示位置 */
  position: absolute;
  display: flex;
  /* 使用 Flexbox 布局 */
  justify-content: center;
  /* 子元素水平居中 */
  align-items: center;
  /* 子元素垂直居中 */
  left: 0;
  width: 100%;
  /* 宽度占满整个容器 */
  height: 100%;
  /* 高度占容器的20% */
}
.viewTotalScore {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.el-row {
    margin-bottom: 0px;
}

.el-col {
    margin-bottom: 0px;
}
</style>
