<template>
    <!-- 显示比赛 -->
    <el-card>
        <el-row :gutter="20">
            <el-col :span="6" v-for="(match, index) in matches" :key="index">
                <el-card class="match-card">
                    <div class="match-info">
                        <h3>{{ match.name }}</h3>
                        <p>时间：{{ match.show_comStartDate }}</p>
                        <p>地点：{{ match.comAddress }}</p>
                        <p>状态：{{ match.status }}</p>
                        <el-button link type="primary" @click="selectMatch(match)">配置比赛</el-button>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </el-card>

    <el-dialog title="配置比赛" v-model="dialogVisible" width="30%" >
        <div class="flexgap-2" >
            <el-row>
                比赛项目<el-tag class="button-new-tag" v-for="tag in gameList" :key="tag" closable :disable-transitions="false"
                @close="handleClose(tag)">{{ tag }}</el-tag>
            <el-input class="input-new-tag" v-if="gameInputVisible" ref="InputRef" v-model="inputValue"  size="small"
                @keyup.enter="handleInputConfirm" @blur="handleInputConfirm" />
            <el-button v-else class="button-new-tag" size="small" @click="showInput">
                + 添加比赛
            </el-button>
            </el-row>
            <!-- <el-row>
                配置场地<el-tag class="button-new-tag" v-for="tag in sideList" :key="tag" closable :disable-transitions="false"
                @close="SiteHandleClose(tag)">{{ tag }}</el-tag>
            <el-input class="input-new-tag" v-if="sideInputVisible" ref="InputRef" v-model="SiteInputValue"  size="small"
                @keyup.enter="SiteHandleInputConfirm" @blur="SiteHandleInputConfirm" />
            <el-button v-else class="button-new-tag" size="small" @click="SiteShowInput">
                + 添加比赛
            </el-button>
            </el-row> -->
        </div>
        <template v-slot:footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm()">确 定</el-button>
            </span>
        </template>
    </el-dialog>


</template>

<script>
import axios from 'axios';

  export default {
    data() {
      return {
        matches: [],
        selectedMatch: null,
        CompetitionAddress:'',
        addressShow:null,
        CompetitionStatus:'',
        selectedIndex:null,
        dialogVisible: false,
        gameList:[],
        inputVisible:false,
        inputValue:'',
        InputRef:'',
        gameInputVisible:'',
        sideInputVisible:'',
        SiteInputValue:'',
        sideList:[],
        mathId:''

      };
    },
    created(){
        this.getAllCompetition()
    },
    methods: {
    submitForm(){
        this.dialogVisible = false
        var that = this
        axios({
            url: this.$globalurl+'comItem/setLevel2Item',
            method:"post",
            data:{gameId:that.mathId,itemNameList:this.gameList},
            headers:{
                'content-type':'application/json'
            },
        }).then(function(response){
            console.log(response.data);
        })
    },
    showInput(){
        this.gameInputVisible = true
    }, 
    SiteShowInput(){
        this.sideInputVisible = true
    },
    SiteHandleInputConfirm(){
        if (this.SiteInputValue && !this.sideList.includes(this.SiteInputValue)) {
            this.sideList.push(this.SiteInputValue)
            this.sideInputVisible = false
            this.SiteInputValue = ''
        }else if(this.SiteInputValue){
            this.$message({
            message: '该场地已添加，不可重复添加',
            type: 'warning'
      });
        }
       
    },
    handleInputConfirm (){
        if (this.inputValue && !this.gameList.includes(this.inputValue)) {
            this.gameList.push(this.inputValue)
            this.gameInputVisible = false
            this.inputValue = ''
        }else if(this.inputValue){
            this.$message({
            message: '该项目已添加，不可重复添加',
            type: 'warning'
      });
        }
      
    },
    SiteHandleClose(tag){
        this.sideList.splice(this.sideList.indexOf(tag), 1)
    },
    handleClose(tag){
        this.gameList.splice(this.gameList.indexOf(tag), 1)
    },
    getAllCompetition(){
        var that = this
        axios({
            url: this.$globalurl+'game/getAll',
            method:"post",
            headers:{
                'content-type':'application/json'
            },
            }).then(function(response){
                that.matches = response.data
            })
    },
    selectMatch(match) {
        var that =this
        console.log(match);
        this.mathId = match.id
        axios({
        url: this.$globalurl+'comItem/getItemNameByGameId',
        method:'get',
        params:{"gameId":match.id},
        headers:{
          'content-type':'application/json'
        }
      }).then(function (response){
        that.gameList = response.data
      })

        this.dialogVisible = true
        console.log(match);
    },

    }
  };
  </script>
<style scoped>
.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.match-card {
    cursor: pointer;
}

.match-info h3 {
    margin: 0;
}

.dialog-footer {
    text-align: right;
}

.el-row {
    margin-bottom: 20px;
}

.el-col {
    margin-bottom: 20px;
}

.match-card {
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* 平滑过渡效果 */
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    /* 初始阴影 */
}

.match-card:hover,
.match-card:focus,
.match-card.active {
    /* 悬浮、聚焦或激活状态 */
    transform: scale(1.05);
    /* 放大到105% */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    /* 更明显的阴影 */
    z-index: 10;
    /* 确保放大的卡片在其他元素上方 */
}

.match-info h3 {
    margin: 0;
}

.dialog-footer {
    text-align: right;
}
.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
.flexgap-2{
  /* height: 200px;
  justify-content: center;
  align-items: center; */
}
</style>