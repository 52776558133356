<template>
    <div class="home-page">
      <div class="header">
        <h1>欢迎来到首页</h1>
        <p>这是一个简约风格的首页组件示例。</p>
      </div>
      <div class="content">
        <section class="introduction">
          <h2>关于我们</h2>
          <p>简短介绍你的公司或团队，以及你们提供的服务或产品。</p>
        </section>
        <section class="features">
          <h2>我们的特点</h2>
          <ul>
            <li>
              <el-icon><icon-name /></el-icon>
              <span>特点一：简洁直观的用户界面</span>
            </li>
            <li>
              <el-icon><icon-name /></el-icon>
              <span>特点二：易于使用的导航</span>
            </li>
            <li>
              <el-icon><icon-name /></el-icon>
              <span>特点三：高效的工作流程</span>
            </li>
          </ul>
        </section>
        <section class="actions">
          <el-button type="primary">了解更多</el-button>
          <el-button>联系我们</el-button>
        </section>
      </div>
      <footer class="footer">
        <p>&copy; 2023 你的公司名称. 版权所有</p>
      </footer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomePage',
    components: {
      // 注册你的图标组件
    }
  };
  </script>
  
  <style scoped>
  .home-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #fff;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .header {
    padding: 2rem;
    text-align: center;
  }
  
  .header h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }
  
  .header p {
    font-size: 1rem;
    color: #666;
  }
  
  .content {
    padding: 2rem;
  }
  
  .introduction, .features, .actions {
    margin-bottom: 2rem;
  }
  
  .features ul {
    list-style-type: none;
    padding: 0;
  }
  
  .features li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .features li span {
    margin-left: 1rem;
  }
  
  .footer {
    padding: 1rem;
    text-align: center;
    background-color: #f8f9fa;
    border-top: 1px solid #eaeaea;
  }
  
  /* 按钮样式可以根据需要添加 */
  .el-button {
    margin-right: 1rem;
  }
  </style>