<template>
    <el-card class="box-card">
        <el-button link type="primary" @click="diaAddDelegation = true"><el-icon style="height: 30px; width:25px;">
                <Plus />
            </el-icon>添加代表队</el-button>
        <!-- 显示比赛 -->
        <el-row :gutter="20">
            <el-col :span="6" v-for="(delegation, index) in delegationList" :key="index">
                <el-card class="match-card">
                    <div class="match-info">
                        <h3>{{ delegation.name }}</h3>
                        <h3 style="margin-top: 10px;">
                            <el-button link type="success" >详情</el-button>
                            <el-button link type="primary" @click="addLeader(delegation.id)">添加领队</el-button>
                            <el-button link type="danger" @click="deleteDelegation(delegation.id)">删除</el-button>
                        </h3>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </el-card>
    <el-dialog v-model="diaAddDelegation" title="添加代表队" width="700" :before-close="handleClose">
        <el-scrollbar>
            <el-form label-width="100px">
                <el-form-item label="代表队名称">
                    <el-input style="width: 200px;" v-model="delegationName" clearable />
                </el-form-item>
            </el-form>
        </el-scrollbar>

        <template #footer>
            <div class="dialog-footer">
                <el-button @click="diaAddDelegation = false">取消</el-button>
                <el-button type="primary" @click="handleAdd()">
                    确定添加
                </el-button>
            </div>
        </template>

    </el-dialog>
    <!-- 添加领队 -->
    <el-dialog v-model="diaAddLeader" title="添加代表队" width="700" :before-close="handleClose">
        <el-scrollbar>
            <el-form label-width="100px">
                <el-form-item label="领队名称">
                    <el-input style="width: 200px;" v-model="leaderName" clearable />
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input style="width: 200px;" v-model="leaderPhone" clearable />
                </el-form-item>
            </el-form>
        </el-scrollbar>

        <template #footer>
            <div class="dialog-footer">
                <el-button @click="diaAddLeader = false">取消</el-button>
                <el-button type="primary" @click="submitAddLeader()">
                    确定添加
                </el-button>
            </div>
        </template>

    </el-dialog>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return{
            diaAddDelegation:false,
            delegationList:[],
            delegationName:'',
            diaAddLeader:false,
            selectDelegationId:'',
            leaderName:'',
            leaderPhone:''
        }
    },
    created(){
        this.getAllDelegation()
    },
    methods:{
        handleAdd(){
            this.diaAddDelegation= false
            var that = this
            axios({
            url: this.$globalurl+'team/add',
            method:'post',
            data:{name:this.delegationName,gameId:localStorage.getItem("comId")},
            headers:{
            'content-type':'application/json'
            }
        }).then(function(){
            that.getAllDelegation()
            that.$message({
                    type:'success',
                    message:'添加成功'
                })
        })
        },
        getAllDelegation(){
            var that =this
            axios({
            url: this.$globalurl+'team/getByGameId',
            method:'get',
            params:{gameId:localStorage.getItem("comId")},
            headers:{
            'content-type':'application/json'
            }
        }).then(function (response){
            console.log(response.data);
            that.delegationList = response.data
        })
        },
        deleteDelegation(id){
            var that =this
            axios({
            url: this.$globalurl+'team/deleteById',
            method:'get',
            params:{id:id},
            headers:{
            'content-type':'application/json'
            }
        }).then(function(){
            that.getAllDelegation()
            that.$message({
                    type:'success',
                    message:'删除成功'
                })
        })
        },
        addLeader(id){
            this.diaAddLeader = true
            this.selectDelegationId= id

        },
        submitAddLeader(){
                
            var that =this
            console.log(this.leaderPhone);
            axios({
            url: this.$globalurl+'leader/getByPhoneOrName',
            method:'get',
            params:{phoneOrName:this.leaderPhone},
        }).then(function (response){
            if (response.data.length ==0) {
                that.$message({
                    type:'error',
                    message:'电话号码错误'
                })
            }else{
                
                axios({
                    url: that.$globalurl+'team/addLeaderToTeam',
                    method:'get',
                    params:{leaderId:response.data[0].id,teamId:that.selectDelegationId},
                    headers:{
                    'content-type':'application/json'
                    }
                }).then(function(response){
                    if (response.data) {
                        that.$message({
                        type:'success',
                        message:'添加成功'
                        
                })
                
                    that.diaAddLeader = false
                    }else{
                        that.$message({
                        type:'error',
                        message:'添加失败'
                    })}
                })
                
            }
        })
        }
    }
}
</script>

<style></style>