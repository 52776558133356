<template>
    <el-row>
      <el-upload
        class="upload-excel"
        action="#"
        :http-request="uploadExcel"
        :on-change="handleChange"
        :show-file-list="false"
      >
        <el-button type="primary">点击上传 Excel 文件</el-button>
      </el-upload>
      <el-button type="" style="margin-left: 20px" @click="downloadExcel">
        下载模版
      </el-button>
    </el-row>
    <el-row>
      <el-table :data="tableData" style="width: 100%" v-if="tableData.length > 0">
        <el-table-column
          v-for="key in tableKeys"
          :key="key"
          :prop="key"
          :label="key"
        ></el-table-column>
      </el-table>
    </el-row>
  </template>
  
  <script>
  import * as XLSX from "xlsx"; // 确保这个库被使用
  
  export default {
    data() {
      return {
        tableData: [], // 存储每一行数据的对象列表
        tableKeys: [], // 存储对象键的数组
      };
    },
    methods: {
      async downloadExcel() {
        try {
          const response = await fetch("/template.xlsx");
          if (!response.ok) {
            throw new Error("Failed to fetch template");
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "template.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error downloading the template:", error);
        }
      },
      uploadExcel(options) {
        const file = options.file;
        if (file instanceof Blob) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: true });
            this.tableData = json.slice(1); // 跳过标题行，只保留数据行
            this.tableKeys = Object.keys(json[0]); // 获取第一行作为键
          };
          reader.readAsArrayBuffer(file);
          console.log(this.tableData);
        } else {
          this.$message.error('上传的文件不是一个有效的 Blob 或 File 对象');
        }
      },
      handleChange(file) {
        if (file.raw) {
          this.uploadExcel({ file: file.raw });
        }
      },
    },
  };
  </script>
  
  <style>
  .upload-excel .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-left: 20px;
  }
  .upload-excel .el-upload:hover {
    border-color: #409eff;
  }
  </style>