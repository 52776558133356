<template>
  <div class="bg">
    <el-row :gutter="24">
      <el-col :span="4"></el-col>
      <el-col :span="16">
        <el-card style="margin-top: 20px">
          <div class="containerCompetitionInformation">
            <div style="height: 300px; width: 300px">
              <el-image
                :src="require('@/assets/img/2024-logo.png')"
                style="width: 100%; height: 100%; object-fit: contain"
              ></el-image>
            </div>

            <div class="descriptions-container">
              <el-descriptions style="display: flex; margin-left: 20px">
                <el-descriptions-item label=""
                  ><span style="font-size: 30px; font-weight: bold">{{
                    competitionInformation.name
                  }}</span></el-descriptions-item
                >
              </el-descriptions>
              <el-descriptions
                style="display: flex; margin-left: 20px; margin-top: 40px"
              >
                <el-descriptions-item label=""
                  ><span style="font-size: 15px">
                    <el-icon :style="iconStyle"> <location /> </el-icon
                    >&nbsp;地点:&nbsp;&nbsp;&nbsp;&nbsp;{{
                      competitionInformation.comAddress
                    }}</span
                  ></el-descriptions-item
                >
              </el-descriptions>
              <el-descriptions style="display: flex; margin-left: 20px">
                <el-descriptions-item label=""
                  ><span style="font-size: 15px">
                    <el-icon :style="iconStyle"> <Timer /> </el-icon
                    >&nbsp;比赛:&nbsp;&nbsp;&nbsp;&nbsp;{{
                      competitionInformation.show_comStartDate
                    }}</span
                  ></el-descriptions-item
                >
              </el-descriptions>
              <el-descriptions style="display: flex; margin-left: 20px">
                <el-descriptions-item label=""
                  ><span style="font-size: 15px">
                    <el-icon :style="iconStyle"> <AlarmClock /> </el-icon
                    >&nbsp;报名:&nbsp;&nbsp;&nbsp;&nbsp;{{
                      competitionInformation.show_regStartDate
                    }}</span
                  ></el-descriptions-item
                >
              </el-descriptions>
            </div>
            <div
              style="
                width: 500px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              "
            >
              <span
                v-if="seleceDeleagationName == ''"
                style="
                  font-size: 15px;
                  font-weight: bold;
                  opacity: 0.7;
                  margin-bottom: 20px;
                "
                >你还未加入任何代表队，点击加入</span
              >
              <span
                v-if="seleceDeleagationName != ''"
                style="
                  font-size: 30px;
                  font-weight: bold;
                  opacity: 0.7;
                  margin-bottom: 20px;
                "
                >{{ this.seleceDeleagationName }}</span
              >
              <el-button type="primary" @click="diaAddDelegation = true"
                >选择代表队</el-button
              >
            </div>
          </div>
        </el-card>
        <el-card style="margin-top: 20px; padding: 0">
          <div
            class="custom-style"
            style="width: 400px; margin-bottom: 20px; margin-left: 20px"
          >
            <el-segmented
              style="height: 20px; background-color: rgb(255, 255, 255)"
              v-model="seleceValue"
              :options="options"
              block
            ></el-segmented>
          </div>
          <transition>
            <div v-if="seleceValue === '详情'">
              <el-row
                ><span style="margin-left: 20px"
                  >项&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;目</span
                ></el-row
              ><el-row style="cursor: pointer">
                <el-collapse
                  v-model="activeNames"
                  accordion="true"
                  style="width: 100%; height: 100%"
                >
                  <el-collapse-item
                    :name="itemIndex"
                    v-for="(itemName, itemIndex) in items"
                    :key="itemIndex"
                    @click="SelectItemPage(item)"
                  >
                       
                  <template #title>
                      <h2>{{ itemName }}</h2>
                      &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      <span class="red-border"
                        >&nbsp;&nbsp;限时15天&nbsp;03:56::56&nbsp;&nbsp;&nbsp;&nbsp;</span
                      >
                    </template>



                    <el-collapse>
                      <el-collapse-item
                        v-for="(group, index) in groups"
                        :key="index"
                      >
                        <h2></h2>
                      
                    <template #title>
                      <h2>{{ index }}</h2>
                      &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      <span class="red-border"
                        >&nbsp;&nbsp;限时15天&nbsp;03:56::56&nbsp;&nbsp;&nbsp;&nbsp;</span
                      >
                    </template>
                    <div style="width: 100%">
                      <el-table :data="group" style="width: 100%">
                        <el-table-column width="1000">
                          <template v-slot="scope">
                            <h4>
                              {{ scope.row.constituencyName }}-{{
                                scope.row.name
                              }}-{{ scope.row.gender }}-{{ scope.row.type
                              }}{{ scope.row.level }}
                            </h4>
                          </template>
                        </el-table-column>
                        <el-table-column>
                          <template v-slot="scope">
                            <el-button @click="SignUpPage(scope.row)"
                              >立刻报名</el-button
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                        
                        
                        
                        </el-collapse-item
                      >
                    </el-collapse>

                  </el-collapse-item></el-collapse
                >
              </el-row>
            </div>
          </transition>
          <transition>
            <div v-if="seleceValue === '成绩'">2222</div>
          </transition>

          <pdf-viewer src="scr/assets/pdf/tqd.pdf"></pdf-viewer>
        </el-card>
      </el-col>
      <el-col :span="4"></el-col>
    </el-row>
  </div>
  <el-dialog
    v-model="diaAddDelegation"
    title="申请代表队"
    width="700"
    :before-close="handleClose"
  >
    <el-row>
      <el-button
        style="margin-top: 10px; width: 150px; margin-left: 5px"
        v-for="item in delegationList"
        :key="item.id"
        @click="applyDelegation(item)"
        >{{ item.name }}</el-button
      >
    </el-row>
    <el-row>
      <p>我的代表队</p>
      <el-button
        @click="seleceDelegation(item)"
        style="margin-top: 10px; margin-left: 10px"
        v-for="item in userDelegationList"
        :key="item.id"
        >{{ item.name }}</el-button
      >
    </el-row>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="diaAddDelegation = false">取消</el-button>
        <el-button type="primary" @click="handleAdd()"> 确定添加 </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      competitionInformation: {},
      seleceValue: "详情",
      options: ["详情", "成绩", "证书"],
      id: "",
      activeNames: -1,
      items: "",
      levels: "",
      diaAddDelegation: false,
      delegationList: [],
      userDelegationList: [],
      seleceDeleagationName: "",
      seleceDeleagationId: "",
      groups: {},
    };
  },
  created() {
    this.getCompetitonInformation();
    this.getItmes();
    this.getAllDelegation();
    this.getAllUserDelegation();
  },
  methods: {
    SignUpPage(row) {
      console.log(row);
      if (this.seleceDeleagationName == "") {
        this.$message({
          type: "error",
          message: "请选择代表队",
        });
      } else {
        localStorage.setItem("comId", row.gameId);
        this.$router.push({
          path: "/ToRegistrationInformationPage",
          query: {
            itemId: row.comItemId,
            seleceDeleagationName: this.seleceDeleagationName,
            seleceDeleagationId: this.seleceDeleagationId,
            competitionInformationId:
              this.$route.query.competitionInformationId,
          },
        });
      }
    },
    SelectItemPage(item) {
      var that = this;
      axios({
        url: that.$globalurl + "/comItem/getByCondition",
        method: "post",
        data: {
          gameId: that.$route.query.competitionInformationId,
          name: item,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.levels = response.data;
        console.log(that.levels);
        if (Object.keys(that.groups).length == 0) {
          that.levels.forEach((item) => {
            if (!that.groups[item.constituencyName]) {
              that.groups[item.constituencyName] = [];
            }
            that.groups[item.constituencyName].push(item);
          });
        }

        console.log(that.groups);
      });
    },
    getCompetitonInformation() {
      var that = this;
      axios({
        url: that.$globalurl + "game/getById",
        method: "post",
        data: this.$route.query.competitionInformationId,
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.competitionInformation = response.data;
      });
    },
    getItmes() {
      console.log("dasd");
      var that = this;
      axios({
        url: that.$globalurl + "comItem/getItemNameByGameId",
        method: "get",
        params: { gameId: this.$route.query.competitionInformationId },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.items = response.data;
        console.log(that.items);
      });
    },
    selecePage(value) {
      console.log(value);
    },

    getAllDelegation() {
      console.log("测试");
      console.log(localStorage.getItem("comId"));
      console.log(this.$route.query.competitionInformationId);
      var that = this;
      axios({
        url: this.$globalurl + "team/getByGameId",
        method: "get",
        params: { gameId: this.$route.query.competitionInformationId },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        console.log(response.data);
        that.delegationList = response.data;
      });
    },
    //
    getAllUserDelegation() {
      var that = this;
      console.log({
        athleteId: localStorage.getItem("userId"),
        gameId: localStorage.getItem("comId"),
      });
      axios({
        url: that.$globalurl + "team/getByAthleteIdAndGameId",
        method: "get",
        params: {
          athleteId: localStorage.getItem("userId"),
          gameId: localStorage.getItem("comId"),
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        console.log(response.data);
        that.userDelegationList = response.data;
      });
    },
    applyDelegation(item) {
      var that = this;
      axios({
        url: this.$globalurl + "teamApply/add",
        method: "get",
        params: { athleteId: localStorage.getItem("userId"), teamId: item.id },
      }).then(function (response) {
        console.log(response.data);
        that.getAllUserDelegation();
        that.$message({
          type: "success",
          message: "申请成功",
        });
        that.diaAddDelegation = false;
      });
    },
    seleceDelegation(item) {
      this.seleceDeleagationName = item.name;
      this.seleceDeleagationId = item.id;
      this.diaAddDelegation = false;
      this.$message({
        type: "success",
        message: "选择成功",
      });
    },
  },
};
</script>

<style>
.bg {
  background-color: #f0f0f0;
  /* 直接设置背景颜色 */
  margin: 0;
  min-height: 100vh;
  /* 使背景覆盖整个视口高度 */
}

.containerCompetitionInformation {
  display: flex;
  align-items: center;
  /* 垂直居中 */
  justify-content: space-between;
  /* 两端对齐 */
}

.descriptions-container {
  flex-grow: 1;
  /* 让描述部分占据剩余空间 */
}

.custom-style .el-segmented {
  --el-segmented-item-selected-color: var(--el-text-color-primary);
  --el-segmented-item-selected-bg-color: #999999;
  --el-border-radius-base: 16px;
}

.red-border {
  border: 1px solid rgb(206, 57, 57);
  /* 红色边框 */
  /* 可选，为边框内的内容添加一些内边距 */
  /* 使边框包裹住内容 */
  border-radius: 16px;
  color: rgb(206, 57, 57);
  height: 20px;
  font-size: 5;
  display: flex;
  align-items: center;
  /* 垂直居中 */
  justify-content: space-between;
}
.descriptions-container {
  width: 400px;
}
</style>