<template>
    <div class="registration-page">
        <el-card class="box-card">
            <!-- 显示比赛 -->
            <el-row :gutter="20">
                <el-col :span="6" v-for="(match, index) in matches" :key="index">
                    <el-card class="match-card" @click="selectMatch(match)">
                        <div class="match-info">
                            <h3>{{ match }}</h3>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-card>
        <el-dialog title="选择比赛时间" :visible="dialogVisible" width="30%">
            <el-date-picker v-model="selectedDate" type="datetime" placeholder="选择日期时间"></el-date-picker>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <el-button type="primary" @click="confirmSelection">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
  export default {
    data() {
      return {
        matches: [],
        selectedMatch: null,
        dialogVisible: false,
        selectedDate: null,
        startData:'' , // 查询的初始日期,
        endData:'',
        CompetitionAddress:'',
        addressList:['上海','北京','江西','广东','深圳','浙江','河南','湖南','新疆','西藏','云南','四川'],
        addressListHost:['上海','江西','北京','四川'],
        addressShow:null,
        CompetitionStatus:'',
        selectedIndex:null,
      };
    },
    created(){
       this.getItemNames()

    },
    methods: {
        // 获取所有的比赛项目名称
    getItemNames(){
        var that =this
        axios({
        url: this.$globalurl+'comItem/getItemNameByGameId',
        method:'get',
        params:{"gameId":this.$route.query.comId},
        headers:{
          'content-type':'application/json'
        }
      }).then(function (response){
        that.matches = response.data
      })
    },
    selectCity(index){
        this.selectedIndex = index
    },
    selectMatch(match) {
      this.selectedMatch = match;
      console.log(match);
      this.dialogVisible = true;
      this.$router.push({
        path:'/ToComMainPage',
        query:{itemName:match,comId:this.$route.query.comId,comName:this.$route.query.comName}
      })
      localStorage.setItem("itemName",match)
      localStorage.setItem("comId",this.$route.query.comId)
      localStorage.setItem("comName",this.$route.query.comName)
    },
      confirmSelection() {
        if (this.selectedDate) {
          this.selectedMatch.selectedTime = this.selectedDate;
          this.dialogVisible = false;
          alert(`已选择比赛：${this.selectedMatch.name}，时间：${this.selectedDate}`);
        }
      },
      selectAddress(address){
            this.addressShow =address
      },
      selectStatus(status){
            console.log(status);
      }
    }
  };
  </script>

<style scoped>
.el-row {
    margin-bottom: 20px;
}

.el-col {
    margin-bottom: 20px;
}

.match-card {
    cursor: pointer;
}

.match-info h3 {
    margin: 0;
}

.dialog-footer {
    text-align: right;
}

.el-row {
    margin-bottom: 20px;
}

.el-col {
    margin-bottom: 20px;
}

.match-card {
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* 平滑过渡效果 */
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    /* 初始阴影 */
}

.match-card:hover,
.match-card:focus,
.match-card.active {
    /* 悬浮、聚焦或激活状态 */
    transform: scale(1.05);
    /* 放大到105% */
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    /* 更明显的阴影 */
    z-index: 10;
    /* 确保放大的卡片在其他元素上方 */
}

.match-info h3 {
    margin: 0;
}

.dialog-footer {
    text-align: right;
}
</style>