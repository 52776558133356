<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="队伍名" style="margin-left: 10px">
        <el-input placeholder="队伍名" clearable />
      </el-form-item>
      <el-form-item label="领队名">
        <el-input placeholder="领队名" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
  <el-divider />
  <div>
    <div>
      <el-text style="margin-left: 10px">批量处理：</el-text>
      <el-button type="primary" size="default">通过</el-button>
      <el-button type="danger" size="default">拒绝</el-button>
    </div>
    <el-table
      :data="applyList"
      border
      style="width: 100%; margin-top: 10px"
      highlight-current-row="true"
    >
      <el-table-column type="selection" header-align="center" align="center" />
      <el-table-column
        prop="groupName"
        label="队伍名"
        header-align="center"
        align="center"
      />
      <el-table-column label="比赛项目" header-align="center" align="center">
        <template #default="scope">
          <h4>
            {{ scope.row.comItem.name }}{{ scope.row.comItem.gender
            }}{{ scope.row.comItem.type }}{{ scope.row.comItem.level }}-{{
              scope.row.comItem.weight
            }}KG
          </h4>
        </template>
      </el-table-column>
      <el-table-column label="队伍成员" header-align="center" align="center">
        <template #default="scope">
          <el-tag
            style="margin-left: 20px"
            type="primary"
            v-for="athleteName in applyList[scope.$index].athleteNameList"
            :key="athleteName"
          >
            {{ athleteName }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="审核状态"
        header-align="center"
        align="center"
        :filters="[
          { text: '未审核', value: '未审核' },
          { text: '拒绝', value: '拒绝' },
          { text: '审核通过', value: '审核通过' },
        ]"
        :filter-method="filterTag"
        filter-placement="bottom-end"
      >
        <template #default="scope">
          <el-tag :type="selectType(scope.row.status)" disable-transitions>{{
            scope.row.status
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="360"
        header-align="center"
        align="center"
      >
        <template #default="scope">
          <el-button type="primary" size="small">查看详情</el-button>
          <el-button
            type="success"
            size="small"
            @click="passCheck(scope.row.id)"
            :disabled="scope.row.status === '待领队审核'"
            >通过</el-button
          >
          <el-button
            type="danger"
            size="small"
            @click="noPassCheck(scope.row.id)"
            :disabled="scope.row.status === '待领队审核'"
            >拒绝</el-button
          >
          <el-button type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      applyList: [],
    };
  },
  created() {
    console.log("create");
    this.getAllCheck();
    console.log(localStorage.getItem("itemName"));
    console.log(localStorage.getItem("comId"));
  },
  methods: {
    selectType(type) {
      if (type == "待组委会审核") {
        return "primary";
      } else if (type == "组委会审核不通过") {
        return "danger";
      } else if (type == "领队审核不通过") {
        return "danger";
      } else if (type == "待领队审核") {
        return "info";
      } else {
        return "success";
      }
    },
    filterTag(value, row) {
      return row.status === value;
    },

    getAllCheck() {
      var that = this;
      console.log(localStorage.getItem("userId"));
      axios({
        url: this.$globalurl + "applicationApply/getByGameIdAndComItemName",
        method: "get",
        params: {
          comItemName: localStorage.getItem("itemName"),
          gameId: localStorage.getItem("comId"),
          status:'0'
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        that.applyList = response.data;
        console.log(response.data);
      });
    },
    passCheck(id) {
      var that = this;
      axios({
        url: this.$globalurl + "applicationApply/auditByCommittee",
        method: "get",
        params: { applicationApplyId: id, status: true },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (response.data) {
          that.getAllCheck();
          that.$message({
            type: "success",
            message: "操作成功",
          });
        } else {
          that.$message({
            type: "error",
            message: "操作失败",
          });
        }
      });
    },
    noPassCheck(id) {
      var that = this;
      axios({
        url: this.$globalurl + "applicationApply/auditByCommittee",
        method: "get",
        params: { applicationApplyId: id, status: false },
        headers: {
          "content-type": "application/json",
        },
      }).then(function (response) {
        if (response.data) {
          that.getAllCheck();
          that.$message({
            type: "success",
            message: "操作成功",
          });
        } else {
          that.$message({
            type: "error",
            message: "操作失败",
          });
        }
      });
    },
  },
};
</script>

<style>
</style>